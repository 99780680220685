import { Form, Spin, Typography } from "antd/es";
import moment from "moment";
import { connect } from "react-redux";
import {
  Button,
  Checkbox,
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KDatePicker,
  KPanel,
  KRow,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  MultiSelect,
  NumberInput,
  TextArea,
  TextInput
} from "../../components/web-components";
import { KSelectBox, Spacer } from "../../kuika";
import {
  hideImportMappedExcelModal,
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showImportMappedExcelModal,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/modals/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { BFormuTicari_ScreenBase } from "./bformuticari-base";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class BFormuTicari_Screen extends BFormuTicari_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              bformuticari_836609_value: "283ac3c9-74b0-40c1-7273-08dbdfa0a8af",
              bformuticari_750474_value: undefined,
              bformuticari_192848_value: undefined,
              bformuticari_390390_value: undefined,
              bformuticari_389012_value: this.state.getBasvuruFormuById?.at?.(0)?.basvuranSirketAd ?? undefined,
              bformuticari_907796_value: this.state.getBasvuruFormuById?.at?.(0)?.firmaTuruID ?? undefined,
              bformuticari_654168_value: this.state.getBasvuruFormuById?.at?.(0)?.sirketAd ?? undefined,
              bformuticari_405451_value: this.state.getBasvuruFormuById?.at?.(0)?.eserAd ?? undefined,
              bformuticari_788421_value: this.state.getBasvuruFormuById?.at?.(0)?.eserSahibi ?? undefined,
              bformuticari_757838_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
              bformuticari_613968_value: this.state.getBasvuruFormuById?.at?.(0)?.toplamButce ?? undefined,
              bformuticari_819000_value: this.state.getBasvuruFormuById?.at?.(0)?.produktor ?? undefined,
              bformuticari_441327_value: this.state.getBasvuruFormuById?.at?.(0)?.yonetmen ?? undefined,
              bformuticari_164797_value: this.state.getBasvuruFormuById?.at?.(0)?.belgeselinKonusu ?? undefined,
              bformuticari_709057_value: this.state.getBasvuruFormuById?.at?.(0)?.sahneTarifi ?? undefined,
              bformuticari_96573_value: this.state.getBasvuruFormuById?.at?.(0)?.turkiyeHakki ?? undefined,
              bformuticari_904040_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiUlke ?? undefined,
              bformuticari_655326_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiFestivalmi ?? undefined,
              bformuticari_363998_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiFestivalAciklama ?? undefined,
              bformuticari_59667_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresi ?? undefined,
              bformuticari_846743_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresiDiger ?? undefined,
              bformuticari_301253_value: this.state.getBasvuruFormuById?.at?.(0)?.telifBedeli ?? undefined,
              bformuticari_499516_value: this.state.getBasvuruFormuById?.at?.(0)?.eserKullanimSekli ?? undefined,
              bformuticari_806100_value: this.state.getBasvuruFormuById?.at?.(0)?.linkInfo ?? undefined,
              bformuticari_727135_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
              bformuticari_419406_value: this.state.getBasvuruFormuById?.at?.(0)?.yonetmen ?? undefined,
              bformuticari_464905_value: this.state.getBasvuruFormuById?.at?.(0)?.yapimci ?? undefined,
              bformuticari_919241_value: this.state.getBasvuruFormuById?.at?.(0)?.sanatcilar ?? undefined,
              bformuticari_74883_value: this.state.getBasvuruFormuById?.at?.(0)?.facebook ?? undefined,
              bformuticari_136717_value: this.state.getBasvuruFormuById?.at?.(0)?.instagram ?? undefined,
              bformuticari_212231_value: this.state.getBasvuruFormuById?.at?.(0)?.tikTok ?? undefined,
              bformuticari_628115_value: this.state.getBasvuruFormuById?.at?.(0)?.twitter ?? undefined,
              bformuticari_774689_value: this.state.getBasvuruFormuById?.at?.(0)?.youTube ?? undefined,
              bformuticari_176725_value: this.state.getBasvuruFormuById?.at?.(0)?.digerSosyalMedya ?? undefined,
              bformuticari_42241_value: this.state.getBasvuruFormuById?.at?.(0)?.kullanimSekliCD ?? undefined,
              bformuticari_151733_value: this.state.getBasvuruFormuById?.at?.(0)?.formats ?? undefined,
              bformuticari_643220_value: this.state.getBasvuruFormuById?.at?.(0)?.formatAciklama ?? undefined,
              bformuticari_174173_value: this.state.getBasvuruFormuById?.at?.(0)?.yayinTarihi ?? undefined,
              bformuticari_314315_value: this.state.getBasvuruFormuById?.at?.(0)?.yayimBolgesi ?? undefined,
              bformuticari_363699_value: this.state.getBasvuruFormuById?.at?.(0)?.markaUrunVarsa ?? undefined,
              bformuticari_631318_value: this.state.getBasvuruFormuById?.at?.(0)?.sozDegisimi ?? undefined,
              bformuticari_731780_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
              bformuticari_530996_value: this.state.getBasvuruFormuById?.at?.(0)?.tvKanal ?? undefined,
              bformuticari_35217_value: this.state.getBasvuruFormuById?.at?.(0)?.produktor ?? undefined,
              bformuticari_215099_value: this.state.getBasvuruFormuById?.at?.(0)?.yonetmen ?? undefined,
              bformuticari_503111_value: this.state.getBasvuruFormuById?.at?.(0)?.basrol ?? undefined,
              bformuticari_800510_value: this.state.getBasvuruFormuById?.at?.(0)?.diziKonu ?? undefined,
              bformuticari_125636_value: this.state.getBasvuruFormuById?.at?.(0)?.sezon ?? undefined,
              bformuticari_949599_value: this.state.getBasvuruFormuById?.at?.(0)?.diziBolumNo ?? undefined,
              bformuticari_180071_value: this.state.getBasvuruFormuById?.at?.(0)?.yayinTarihi ?? undefined,
              bformuticari_496974_value: this.state.getBasvuruFormuById?.at?.(0)?.sahneTarifi ?? undefined,
              bformuticari_408183_value: this.state.getBasvuruFormuById?.at?.(0)?.eserSure ?? undefined,
              bformuticari_554864_value: this.state.getBasvuruFormuById?.at?.(0)?.turkiyeHakki ?? undefined,
              bformuticari_711138_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiUlke ?? undefined,
              bformuticari_193622_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresi ?? undefined,
              bformuticari_296139_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresiDiger ?? undefined,
              bformuticari_54329_value: this.state.getBasvuruFormuById?.at?.(0)?.telifBedeli ?? undefined,
              bformuticari_139423_value: this.state.getBasvuruFormuById?.at?.(0)?.eserKullanimSekli ?? undefined,
              bformuticari_315845_value: this.state.getBasvuruFormuById?.at?.(0)?.linkInfo ?? undefined,
              bformuticari_953799_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
              bformuticari_795343_value: this.state.getBasvuruFormuById?.at?.(0)?.tvKanal ?? undefined,
              bformuticari_894235_value: this.state.getBasvuruFormuById?.at?.(0)?.produktor ?? undefined,
              bformuticari_752355_value: this.state.getBasvuruFormuById?.at?.(0)?.yonetmen ?? undefined,
              bformuticari_37671_value: this.state.getBasvuruFormuById?.at?.(0)?.basrol ?? undefined,
              bformuticari_183550_value: this.state.getBasvuruFormuById?.at?.(0)?.kacBolum ?? undefined,
              bformuticari_780979_value: this.state.getBasvuruFormuById?.at?.(0)?.sinopsis ?? undefined,
              bformuticari_175576_value: this.state.getBasvuruFormuById?.at?.(0)?.eserKullanimYeri ?? undefined,
              bformuticari_563208_value: this.state.getBasvuruFormuById?.at?.(0)?.turkiyeHakki ?? undefined,
              bformuticari_861385_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiUlke ?? undefined,
              bformuticari_482186_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresi ?? undefined,
              bformuticari_398647_value: this.state.getBasvuruFormuById?.at?.(0)?.eserKullanimSekli ?? undefined,
              bformuticari_433250_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
              bformuticari_401545_value: this.state.getBasvuruFormuById?.at?.(0)?.toplamButce ?? undefined,
              bformuticari_548831_value: this.state.getBasvuruFormuById?.at?.(0)?.produktor ?? undefined,
              bformuticari_241884_value: this.state.getBasvuruFormuById?.at?.(0)?.yonetmen ?? undefined,
              bformuticari_154279_value: this.state.getBasvuruFormuById?.at?.(0)?.basrol ?? undefined,
              bformuticari_306823_value: this.state.getBasvuruFormuById?.at?.(0)?.senaryoOzeti ?? undefined,
              bformuticari_821567_value: this.state.getBasvuruFormuById?.at?.(0)?.sahneTarifi ?? undefined,
              bformuticari_135744_value: this.state.getBasvuruFormuById?.at?.(0)?.eserSure ?? undefined,
              bformuticari_109874_value: this.state.getBasvuruFormuById?.at?.(0)?.fragmandaVarmi ?? undefined,
              bformuticari_43394_value: this.state.getBasvuruFormuById?.at?.(0)?.filmMecra ?? undefined,
              bformuticari_49563_value: this.state.getBasvuruFormuById?.at?.(0)?.turkiyeHakki ?? undefined,
              bformuticari_437312_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiUlke ?? undefined,
              bformuticari_763581_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiFestivalmi ?? undefined,
              bformuticari_478463_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiFestivalAciklama ?? undefined,
              bformuticari_161165_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresi ?? undefined,
              bformuticari_597208_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresiDiger ?? undefined,
              bformuticari_108219_value: this.state.getBasvuruFormuById?.at?.(0)?.telifBedeli ?? undefined,
              bformuticari_599586_value: this.state.getBasvuruFormuById?.at?.(0)?.eserKullanimSekli ?? undefined,
              bformuticari_179006_value: this.state.getBasvuruFormuById?.at?.(0)?.linkInfo ?? undefined,
              bformuticari_779822_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
              bformuticari_139034_value: this.state.getBasvuruFormuById?.at?.(0)?.sanatcilar ?? undefined,
              bformuticari_213030_value: this.state.getBasvuruFormuById?.at?.(0)?.yapimci ?? undefined,
              bformuticari_895754_value: this.state.getBasvuruFormuById?.at?.(0)?.sponsor ?? undefined,
              bformuticari_569899_value: this.state.getBasvuruFormuById?.at?.(0)?.konserYerTarih ?? undefined,
              bformuticari_179729_value: this.state.getBasvuruFormuById?.at?.(0)?.konserKapasitesi ?? undefined,
              bformuticari_936236_value: this.state.getBasvuruFormuById?.at?.(0)?.biletFiyatlari ?? undefined,
              bformuticari_46982_value: this.state.getBasvuruFormuById?.at?.(0)?.konserAfis ?? undefined,
              bformuticari_925632_value: this.state.getBasvuruFormuById?.at?.(0)?.konserKayit ?? undefined,
              bformuticari_753776_value: this.state.getBasvuruFormuById?.at?.(0)?.konserGoruntu ?? undefined,
              bformuticari_118061_value: this.state.getBasvuruFormuById?.at?.(0)?.konserCDvd ?? undefined,
              bformuticari_447965_value: this.state.getBasvuruFormuById?.at?.(0)?.telifBedeli ?? undefined,
              bformuticari_636418_value: this.state.getBasvuruFormuById?.at?.(0)?.eserKullanimSekli ?? undefined,
              bformuticari_606315_value: this.state.getBasvuruFormuById?.at?.(0)?.linkInfo ?? undefined,
              bformuticari_243523_value: this.state.getBasvuruFormuById?.at?.(0)?.konserHologram ?? undefined,
              bformuticari_252338_value: this.state.getBasvuruFormuById?.at?.(0)?.konserGelir ?? undefined,
              bformuticari_63361_value: this.state.getBasvuruFormuById?.at?.(0)?.konserGelirPay ?? undefined,
              bformuticari_160425_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
              bformuticari_634083_value: this.state.getBasvuruFormuById?.at?.(0)?.urun ?? undefined,
              bformuticari_375623_value: this.state.getBasvuruFormuById?.at?.(0)?.parca ?? undefined,
              bformuticari_84279_value: this.state.getBasvuruFormuById?.at?.(0)?.besteciSozYazari ?? undefined,
              bformuticari_952938_value: this.state.getBasvuruFormuById?.at?.(0)?.izinTuruID ?? undefined,
              bformuticari_330499_value: this.state.getBasvuruFormuById?.at?.(0)?.mecras ?? undefined,
              bformuticari_575302_value: this.state.getBasvuruFormuById?.at?.(0)?.spot ?? undefined,
              bformuticari_275256_value: this.state.getBasvuruFormuById?.at?.(0)?.filmSayisi ?? undefined,
              bformuticari_33436_value: this.state.getBasvuruFormuById?.at?.(0)?.versiyonSayisi ?? undefined,
              bformuticari_601579_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiUlke ?? undefined,
              bformuticari_925097_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresi ?? undefined,
              bformuticari_713576_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresiDiger ?? undefined,
              bformuticari_776748_value: this.state.getBasvuruFormuById?.at?.(0)?.arsiv ?? undefined,
              bformuticari_883613_value: this.state.getBasvuruFormuById?.at?.(0)?.arsivSure ?? undefined,
              bformuticari_69287_value: this.state.getBasvuruFormuById?.at?.(0)?.script ?? undefined,
              bformuticari_423768_value: this.state.getBasvuruFormuById?.at?.(0)?.eserKullanimSekli ?? undefined,
              bformuticari_680656_value: this.state.getBasvuruFormuById?.at?.(0)?.linkInfo ?? undefined,
              bformuticari_826306_value: this.state.getBasvuruFormuById?.at?.(0)?.sozDegisimi ?? undefined,
              bformuticari_508876_value: this.state.getBasvuruFormuById?.at?.(0)?.yayinTarihi ?? undefined,
              bformuticari_958009_value: this.state.getBasvuruFormuById?.at?.(0)?.munhasirlikID ?? undefined,
              bformuticari_41842_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
              bformuticari_769963_value: this.state.getBasvuruFormuById?.at?.(0)?.toplamButce ?? undefined,
              bformuticari_745722_value: this.state.getBasvuruFormuById?.at?.(0)?.produktor ?? undefined,
              bformuticari_678596_value: this.state.getBasvuruFormuById?.at?.(0)?.yonetmen ?? undefined,
              bformuticari_13402_value: this.state.getBasvuruFormuById?.at?.(0)?.basrol ?? undefined,
              bformuticari_98625_value: this.state.getBasvuruFormuById?.at?.(0)?.senaryoOzeti ?? undefined,
              bformuticari_977010_value: this.state.getBasvuruFormuById?.at?.(0)?.sahneTarifi ?? undefined,
              bformuticari_939868_value: this.state.getBasvuruFormuById?.at?.(0)?.sahneVeKoltuk ?? undefined,
              bformuticari_605461_value: this.state.getBasvuruFormuById?.at?.(0)?.koltuk ?? undefined,
              bformuticari_348650_value: this.state.getBasvuruFormuById?.at?.(0)?.yayinlanmaFrekans ?? undefined,
              bformuticari_785985_value: this.state.getBasvuruFormuById?.at?.(0)?.biletFiyatlari ?? undefined,
              bformuticari_114157_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresi ?? undefined,
              bformuticari_961538_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresiDiger ?? undefined,
              bformuticari_531317_value: this.state.getBasvuruFormuById?.at?.(0)?.sponsor ?? undefined,
              bformuticari_595383_value: this.state.getBasvuruFormuById?.at?.(0)?.telifBedeli ?? undefined,
              bformuticari_945615_value: this.state.getBasvuruFormuById?.at?.(0)?.eserKullanimSekli ?? undefined,
              bformuticari_997705_value: this.state.getBasvuruFormuById?.at?.(0)?.linkInfo ?? undefined,
              bformuticari_834746_value: this.state.getBasvuruFormuById?.at?.(0)?.fragmandaVarmi ?? undefined,
              bformuticari_970226_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
              bformuticari_284840_value: this.state.getBasvuruFormuById?.at?.(0)?.tvKanal ?? undefined,
              bformuticari_584964_value: this.state.getBasvuruFormuById?.at?.(0)?.produktor ?? undefined,
              bformuticari_151522_value: this.state.getBasvuruFormuById?.at?.(0)?.yonetmen ?? undefined,
              bformuticari_818678_value: this.state.getBasvuruFormuById?.at?.(0)?.programIcerik ?? undefined,
              bformuticari_300311_value: this.state.getBasvuruFormuById?.at?.(0)?.sezon ?? undefined,
              bformuticari_596953_value: this.state.getBasvuruFormuById?.at?.(0)?.tvBolumNo ?? undefined,
              bformuticari_38491_value: this.state.getBasvuruFormuById?.at?.(0)?.tvYayimTarihi ?? undefined,
              bformuticari_701444_value: this.state.getBasvuruFormuById?.at?.(0)?.yayinlanmaFrekans ?? undefined,
              bformuticari_475588_value: this.state.getBasvuruFormuById?.at?.(0)?.turkiyeHakki ?? undefined,
              bformuticari_382382_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiUlke ?? undefined,
              bformuticari_841151_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresi ?? undefined,
              bformuticari_691237_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresiDiger ?? undefined,
              bformuticari_232600_value: this.state.getBasvuruFormuById?.at?.(0)?.telifBedeli ?? undefined,
              bformuticari_653975_value: this.state.getBasvuruFormuById?.at?.(0)?.eserKullanimSekli ?? undefined,
              bformuticari_607210_value: this.state.getBasvuruFormuById?.at?.(0)?.linkInfo ?? undefined,
              bformuticari_709919_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
              bformuticari_830230_value: this.state.getBasvuruFormuById?.at?.(0)?.projeTuruID ?? undefined,
              bformuticari_169770_value: this.state.getBasvuruFormuById?.at?.(0)?.programIcerik ?? undefined,
              bformuticari_211926_value: this.state.getBasvuruFormuById?.at?.(0)?.programIcerik ?? undefined,
              bformuticari_926329_value: this.state.getBasvuruFormuById?.at?.(0)?.terminTarihi ?? undefined,
              bformuticari_950974_value: this.state.getBasvuruFormuById?.at?.(0)?.acil ?? undefined,
              bformuticari_417431_value: false,
              bformuticari_141657_value: undefined,
              bformuticari_570080_value: undefined,
              bformuticari_770932_value: NaN,
              bformuticari_707397_value: NaN,
              bformuticari_968501_value: NaN,
              bformuticari_409878_value: this.state.getBasvuruFormuById?.at?.(0)?.aciklama ?? undefined,
              bformuticari_241255_value: undefined,
              bformuticari_772477_value: undefined,
              bformuticari_56794_value: undefined
            }}
            onValuesChange={(changedValues, allValues) => {
              this.clearFieldValidation(changedValues);
            }}
          >
            <div
              id="bformuticari_body"
              style={
                {
                  borderBottomWidth: 1,
                  borderColor: "rgba(155, 155, 155, 1)",
                  borderStyle: "solid",
                  borderTopWidth: 0,
                  borderRightWidth: 0,
                  borderLeftWidth: 0,
                  backgroundColor: "rgba(245, 245, 245, 1)",
                  backgroundImage:
                    "url(" +
                    KuikaAppManager.GetBackendUrl() +
                    "/resource/runtime/byid?resourceId=31886a70-856c-4afc-8e82-08da211d182d)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  display: "block"
                } as any
              }
            >
              <KRow
                id="859788"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    paddingTop: 24,
                    paddingBottom: 24
                  } as any
                }
              >
                <KCol
                  id="455461"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "left",
                      alignItems: "center",
                      textAlign: "-webkit-center"
                    } as any
                  }
                >
                  <KRow
                    id="619866"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 16,
                        paddingBottom: 16,
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <KCol
                      id="656411"
                      xs={8}
                      sm={8}
                      md={8}
                      lg={8}
                      xl={8}
                      xxl={8}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingRight: 16,
                          paddingLeft: 16,
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    ></KCol>

                    <KCol
                      id="632055"
                      xs={8}
                      sm={8}
                      md={8}
                      lg={8}
                      xl={8}
                      xxl={8}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingRight: 16,
                          paddingLeft: 16,
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    ></KCol>

                    <KCol
                      id="586"
                      xs={8}
                      sm={8}
                      md={8}
                      lg={8}
                      xl={8}
                      xxl={8}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingRight: 16,
                          paddingLeft: 16,
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    ></KCol>
                  </KRow>

                  <KPanel
                    id="510076"
                    scrollRadius={false}
                    style={
                      {
                        borderTopLeftRadius: 12,
                        borderTopRightRadius: 12,
                        borderBottomRightRadius: 12,
                        borderBottomLeftRadius: 12,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 16,
                        paddingLeft: 16,
                        maxWidth: "980px",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <KRow
                      id="210174"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 48,
                          paddingBottom: 24,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="474782"
                        xs={23}
                        sm={23}
                        md={23}
                        lg={23}
                        xl={23}
                        xxl={23}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingRight: 16,
                            paddingLeft: 16,
                            alignItems: "center",
                            textAlign: "-webkit-center",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Form.Item className="kFormItem" name="bformuticari_836609_value">
                          <Image
                            id="836609"
                            placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                            zoomOnClick={false}
                            imageFit="fit"
                            style={
                              {
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingLeft: 16,
                                height: "24px"
                              } as any
                            }
                          ></Image>
                        </Form.Item>
                      </KCol>

                      <KCol
                        id="810710"
                        xs={1}
                        sm={1}
                        md={1}
                        lg={1}
                        xl={1}
                        xxl={1}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Icon
                          id="236080"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.BFormuTicariComponent_236080_onClick();
                          }}
                          showCursorPointer
                          visibility={this.state.isComp236080Visible}
                          iconName="close"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              fontSize: "24px",
                              color: "rgba(172, 38, 42, 1)"
                            } as any
                          }
                        ></Icon>
                      </KCol>
                    </KRow>

                    <KRow
                      id="637417"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 16,
                          paddingBottom: 36,
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="716723"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingRight: 16,
                            paddingLeft: 16,
                            alignItems: "center",
                            textAlign: "-webkit-center",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="861186"
                          visibility={this.state.isComp861186Visible}
                          value={ReactSystemFunctions.translate(this.ml, 861186, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="468627"
                          visibility={this.state.isComp468627Visible}
                          value={ReactSystemFunctions.translate(this.ml, 468627, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(208, 2, 27, 1)",
                              letterSpacing: "0.5px",
                              fontStyle: "italic"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="10355"
                          visibility={this.state.isComp10355Visible}
                          value={ReactSystemFunctions.translate(this.ml, 10355, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="139406"
                          visibility={this.state.isComp139406Visible}
                          value={ReactSystemFunctions.translate(this.ml, 139406, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(208, 2, 27, 1)",
                              letterSpacing: "0.5px",
                              fontStyle: "italic"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="967257"
                          visibility={this.state.isComp967257Visible}
                          value={ReactSystemFunctions.translate(this.ml, 967257, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="938143"
                          visibility={this.state.isComp938143Visible}
                          value={ReactSystemFunctions.translate(this.ml, 938143, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(208, 2, 27, 1)",
                              letterSpacing: "0.5px",
                              fontStyle: "italic"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="274058"
                          visibility={this.state.isComp274058Visible}
                          value={ReactSystemFunctions.translate(this.ml, 274058, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="256251"
                          visibility={this.state.isComp256251Visible}
                          value={ReactSystemFunctions.translate(this.ml, 256251, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(208, 2, 27, 1)",
                              letterSpacing: "0.5px",
                              fontStyle: "italic"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="28903"
                          visibility={this.state.isComp28903Visible}
                          value={ReactSystemFunctions.translate(this.ml, 28903, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="918779"
                          visibility={this.state.isComp918779Visible}
                          value={ReactSystemFunctions.translate(this.ml, 918779, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(208, 2, 27, 1)",
                              letterSpacing: "0.5px",
                              fontStyle: "italic"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="115665"
                          visibility={this.state.isComp115665Visible}
                          value={ReactSystemFunctions.translate(this.ml, 115665, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="3330"
                          visibility={this.state.isComp3330Visible}
                          value={ReactSystemFunctions.translate(this.ml, 3330, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(208, 2, 27, 1)",
                              letterSpacing: "0.5px",
                              fontStyle: "italic"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="695955"
                          visibility={this.state.isComp695955Visible}
                          value={ReactSystemFunctions.translate(this.ml, 695955, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="25348"
                          visibility={this.state.isComp25348Visible}
                          value={ReactSystemFunctions.translate(this.ml, 25348, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(208, 2, 27, 1)",
                              letterSpacing: "0.5px",
                              fontStyle: "italic"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="487276"
                          visibility={this.state.isComp487276Visible}
                          value={ReactSystemFunctions.translate(this.ml, 487276, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="981156"
                          visibility={this.state.isComp981156Visible}
                          value={ReactSystemFunctions.translate(this.ml, 981156, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(208, 2, 27, 1)",
                              letterSpacing: "0.5px",
                              fontStyle: "italic"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="169331"
                          visibility={this.state.isComp169331Visible}
                          value={ReactSystemFunctions.translate(this.ml, 169331, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="998670"
                          visibility={this.state.isComp998670Visible}
                          value={ReactSystemFunctions.translate(this.ml, 998670, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(208, 2, 27, 1)",
                              letterSpacing: "0.5px",
                              fontStyle: "italic"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="406152"
                          visibility={this.state.isComp406152Visible}
                          value={ReactSystemFunctions.translate(this.ml, 406152, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingLeft: 6,
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="712408"
                          visibility={this.state.isComp712408Visible}
                          value={ReactSystemFunctions.translate(this.ml, 712408, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingLeft: 6,
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(208, 2, 27, 1)",
                              letterSpacing: "0.5px",
                              fontStyle: "italic"
                            } as any
                          }
                        ></Label>
                      </KCol>
                    </KRow>

                    <KRow
                      id="27582"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 8,
                          paddingBottom: 8,
                          alignItems: "center",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="515331"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="346979"
                          value={ReactSystemFunctions.translate(this.ml, 346979, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="802042"
                          value={ReactSystemFunctions.translate(this.ml, 802042, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(208, 2, 27, 1)",
                              letterSpacing: "0.5px",
                              fontStyle: "italic"
                            } as any
                          }
                        ></Label>
                      </KCol>

                      <KCol
                        id="432505"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Form.Item className="kFormItem" name="bformuticari_750474_value">
                          <TextInput
                            id="750474"
                            kuikaRef={this.bformuticari_750474_value_kuikaTextInputRef}
                            placeholder={ReactSystemFunctions.translate(this.ml, 750474, "placeholder", this.defaultML)}
                            allowClear={false}
                            bordered={true}
                            disabled={false}
                            type="text"
                            iconColor="rgba(0, 0, 0, 1)"
                            formatter="None"
                            isCharOnly={false}
                            autoComplete={true}
                            style={
                              {
                                borderBottomWidth: 1,
                                borderColor: "rgba(155, 155, 155, 1)",
                                borderStyle: "dotted",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                height: "36px",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(47, 47, 47, 1)"
                              } as any
                            }
                          ></TextInput>
                        </Form.Item>
                      </KCol>
                    </KRow>

                    <KRow
                      id="441370"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 8,
                          paddingBottom: 8,
                          alignItems: "center",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="896786"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="213845"
                          value={ReactSystemFunctions.translate(this.ml, 213845, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="401310"
                          value={ReactSystemFunctions.translate(this.ml, 401310, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(208, 2, 27, 1)",
                              letterSpacing: "0.5px",
                              fontStyle: "italic"
                            } as any
                          }
                        ></Label>
                      </KCol>

                      <KCol
                        id="387874"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Form.Item className="kFormItem" name="bformuticari_192848_value">
                          <TextInput
                            id="192848"
                            kuikaRef={this.bformuticari_192848_value_kuikaTextInputRef}
                            placeholder={ReactSystemFunctions.translate(this.ml, 192848, "placeholder", this.defaultML)}
                            allowClear={false}
                            bordered={true}
                            disabled={false}
                            type="text"
                            iconColor="rgba(0, 0, 0, 1)"
                            formatter="None"
                            isCharOnly={false}
                            autoComplete={true}
                            style={
                              {
                                borderBottomWidth: 1,
                                borderColor: "rgba(155, 155, 155, 1)",
                                borderStyle: "dotted",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                height: "36px",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(47, 47, 47, 1)"
                              } as any
                            }
                          ></TextInput>
                        </Form.Item>
                      </KCol>
                    </KRow>

                    <KRow
                      id="439592"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 8,
                          paddingBottom: 8,
                          alignItems: "center",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="901578"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="186660"
                          value={ReactSystemFunctions.translate(this.ml, 186660, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="45251"
                          value={ReactSystemFunctions.translate(this.ml, 45251, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(208, 2, 27, 1)",
                              letterSpacing: "0.5px",
                              fontStyle: "italic"
                            } as any
                          }
                        ></Label>
                      </KCol>

                      <KCol
                        id="592670"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Form.Item className="kFormItem" name="bformuticari_390390_value">
                          <TextInput
                            id="390390"
                            kuikaRef={this.bformuticari_390390_value_kuikaTextInputRef}
                            placeholder={ReactSystemFunctions.translate(this.ml, 390390, "placeholder", this.defaultML)}
                            allowClear={false}
                            bordered={true}
                            disabled={false}
                            type="text"
                            iconColor="rgba(0, 0, 0, 1)"
                            formatter="None"
                            isCharOnly={false}
                            autoComplete={true}
                            style={
                              {
                                borderBottomWidth: 1,
                                borderColor: "rgba(155, 155, 155, 1)",
                                borderStyle: "dotted",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                height: "36px",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(47, 47, 47, 1)"
                              } as any
                            }
                          ></TextInput>
                        </Form.Item>
                      </KCol>
                    </KRow>

                    <KRow
                      id="595160"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 8,
                          paddingBottom: 8,
                          alignItems: "center",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="143362"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="543730"
                          value={ReactSystemFunctions.translate(this.ml, 543730, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="643048"
                          value={ReactSystemFunctions.translate(this.ml, 643048, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(208, 2, 27, 1)",
                              letterSpacing: "0.5px",
                              fontStyle: "italic"
                            } as any
                          }
                        ></Label>
                      </KCol>

                      <KCol
                        id="763434"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Form.Item className="kFormItem" name="bformuticari_389012_value">
                          <TextInput
                            id="389012"
                            kuikaRef={this.bformuticari_389012_value_kuikaTextInputRef}
                            placeholder={ReactSystemFunctions.translate(this.ml, 389012, "placeholder", this.defaultML)}
                            allowClear={false}
                            bordered={true}
                            disabled={false}
                            type="text"
                            iconColor="rgba(0, 0, 0, 1)"
                            formatter="None"
                            isCharOnly={false}
                            autoComplete={true}
                            style={
                              {
                                borderBottomWidth: 1,
                                borderColor: "rgba(155, 155, 155, 1)",
                                borderStyle: "dotted",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                height: "36px",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(47, 47, 47, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></TextInput>
                        </Form.Item>
                      </KCol>
                    </KRow>

                    <KRow
                      id="743653"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 8,
                          paddingBottom: 8,
                          alignItems: "center",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="604181"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="556749"
                          value={ReactSystemFunctions.translate(this.ml, 556749, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="376687"
                          value={ReactSystemFunctions.translate(this.ml, 376687, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(208, 2, 27, 1)",
                              letterSpacing: "0.5px",
                              fontStyle: "italic"
                            } as any
                          }
                        ></Label>
                      </KCol>

                      <KCol
                        id="528769"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Form.Item className="kFormItem" name="bformuticari_907796_value">
                          <KSelectBox
                            id="907796"
                            kuikaRef={this.bformuticari_907796_value_kuikaSelectBoxRef}
                            options={this.state.sbFirmaTuruList}
                            placeholder={ReactSystemFunctions.translate(this.ml, 907796, "placeholder", this.defaultML)}
                            allowClear={true}
                            autoClearSearchValue={true}
                            showSearch={true}
                            widthType="fill"
                            containsNullItem={false}
                            sortBy="none"
                            datavaluefield="id"
                            datatextfield="tanimTREN"
                            style={
                              {
                                borderBottomWidth: 1,
                                borderColor: "rgba(155, 155, 155, 1)",
                                borderStyle: "dotted",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                height: "36px",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(47, 47, 47, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KSelectBox>
                        </Form.Item>
                      </KCol>
                    </KRow>

                    <KRow
                      id="57055"
                      visibility={this.state.isComp57055Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 8,
                          paddingBottom: 8,
                          alignItems: "center",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="976264"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="176074"
                          value={ReactSystemFunctions.translate(this.ml, 176074, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>
                      </KCol>

                      <KCol
                        id="512148"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Form.Item className="kFormItem" name="bformuticari_654168_value">
                          <TextInput
                            id="654168"
                            kuikaRef={this.bformuticari_654168_value_kuikaTextInputRef}
                            placeholder={ReactSystemFunctions.translate(this.ml, 654168, "placeholder", this.defaultML)}
                            allowClear={false}
                            bordered={true}
                            disabled={false}
                            type="text"
                            iconColor="rgba(0, 0, 0, 1)"
                            formatter="None"
                            isCharOnly={false}
                            autoComplete={true}
                            style={
                              {
                                borderBottomWidth: 1,
                                borderColor: "rgba(155, 155, 155, 1)",
                                borderStyle: "dotted",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                height: "36px",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(47, 47, 47, 1)"
                              } as any
                            }
                          ></TextInput>
                        </Form.Item>
                      </KCol>
                    </KRow>

                    <KRow
                      id="951915"
                      visibility={this.state.isComp951915Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 8,
                          paddingBottom: 8,
                          alignItems: "center",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="117832"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="669324"
                          value={ReactSystemFunctions.translate(this.ml, 669324, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="424359"
                          value={ReactSystemFunctions.translate(this.ml, 424359, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(208, 2, 27, 1)",
                              letterSpacing: "0.5px",
                              fontStyle: "italic"
                            } as any
                          }
                        ></Label>
                      </KCol>

                      <KCol
                        id="103747"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Form.Item className="kFormItem" name="bformuticari_405451_value">
                          <TextInput
                            id="405451"
                            kuikaRef={this.bformuticari_405451_value_kuikaTextInputRef}
                            placeholder={ReactSystemFunctions.translate(this.ml, 405451, "placeholder", this.defaultML)}
                            allowClear={false}
                            bordered={true}
                            disabled={false}
                            type="text"
                            iconColor="rgba(0, 0, 0, 1)"
                            formatter="None"
                            isCharOnly={false}
                            autoComplete={true}
                            style={
                              {
                                borderBottomWidth: 1,
                                borderColor: "rgba(155, 155, 155, 1)",
                                borderStyle: "dotted",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                height: "36px",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(47, 47, 47, 1)"
                              } as any
                            }
                          ></TextInput>
                        </Form.Item>
                      </KCol>
                    </KRow>

                    <KRow
                      id="220908"
                      visibility={this.state.isComp220908Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 8,
                          paddingBottom: 8,
                          alignItems: "center",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="47729"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="295487"
                          value={ReactSystemFunctions.translate(this.ml, 295487, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="166941"
                          value={ReactSystemFunctions.translate(this.ml, 166941, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(208, 2, 27, 1)",
                              letterSpacing: "0.5px",
                              fontStyle: "italic"
                            } as any
                          }
                        ></Label>
                      </KCol>

                      <KCol
                        id="339165"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Form.Item className="kFormItem" name="bformuticari_788421_value">
                          <TextInput
                            id="788421"
                            kuikaRef={this.bformuticari_788421_value_kuikaTextInputRef}
                            placeholder={ReactSystemFunctions.translate(this.ml, 788421, "placeholder", this.defaultML)}
                            allowClear={false}
                            bordered={true}
                            disabled={false}
                            type="text"
                            iconColor="rgba(0, 0, 0, 1)"
                            formatter="None"
                            isCharOnly={false}
                            autoComplete={true}
                            style={
                              {
                                borderBottomWidth: 1,
                                borderColor: "rgba(155, 155, 155, 1)",
                                borderStyle: "dotted",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                height: "36px",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(47, 47, 47, 1)"
                              } as any
                            }
                          ></TextInput>
                        </Form.Item>
                      </KCol>
                    </KRow>

                    <KRow
                      id="846395"
                      visibility={this.state.isComp846395Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="748583"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KRow
                          id="723855"
                          visibility={this.state.isComp723855Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(155, 155, 155, 0.21)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="417302"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingRight: 16,
                                paddingLeft: 16,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="89366"
                              value={ReactSystemFunctions.translate(this.ml, 89366, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="786623"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>

                          <KCol
                            id="56512"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>
                        </KRow>

                        <KRow
                          id="283925"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="382715"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="739201"
                              value={ReactSystemFunctions.translate(this.ml, 739201, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="181465"
                              value={ReactSystemFunctions.translate(this.ml, 181465, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="746389"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_757838_value">
                              <TextInput
                                id="757838"
                                kuikaRef={this.bformuticari_757838_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  757838,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="121447"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="390907"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="143432"
                              value={ReactSystemFunctions.translate(this.ml, 143432, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="464344"
                              value={ReactSystemFunctions.translate(this.ml, 464344, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="445040"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_613968_value">
                              <TextInput
                                id="613968"
                                kuikaRef={this.bformuticari_613968_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  613968,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="185221"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="120378"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="933944"
                              value={ReactSystemFunctions.translate(this.ml, 933944, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="135971"
                              value={ReactSystemFunctions.translate(this.ml, 135971, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="459214"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_819000_value">
                              <TextInput
                                id="819000"
                                kuikaRef={this.bformuticari_819000_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  819000,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="919829"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="580826"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="387556"
                              value={ReactSystemFunctions.translate(this.ml, 387556, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="179965"
                              value={ReactSystemFunctions.translate(this.ml, 179965, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="561854"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_441327_value">
                              <TextInput
                                id="441327"
                                kuikaRef={this.bformuticari_441327_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  441327,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="871852"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="646958"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="528904"
                              value={ReactSystemFunctions.translate(this.ml, 528904, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="617592"
                              value={ReactSystemFunctions.translate(this.ml, 617592, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="739417"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_164797_value">
                              <TextInput
                                id="164797"
                                kuikaRef={this.bformuticari_164797_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  164797,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="825771"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="696060"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="986753"
                              value={ReactSystemFunctions.translate(this.ml, 986753, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="713473"
                              value={ReactSystemFunctions.translate(this.ml, 713473, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="131002"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_709057_value">
                              <TextArea
                                id="709057"
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  709057,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                autoSize={false}
                                showCount={false}
                                formatter="None"
                                readOnly={false}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></TextArea>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="502005"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="656730"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="126444"
                              value={ReactSystemFunctions.translate(this.ml, 126444, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="322148"
                              value={ReactSystemFunctions.translate(this.ml, 322148, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="500504"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_96573_value">
                              <KSelectBox
                                id="96573"
                                kuikaRef={this.bformuticari_96573_value_kuikaSelectBoxRef}
                                options={this.state.dataSource_96573}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  96573,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={true}
                                autoClearSearchValue={true}
                                showSearch={false}
                                widthType="fill"
                                containsNullItem={false}
                                sortBy="none"
                                datavaluefield="key"
                                datatextfield="text"
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></KSelectBox>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="271499"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="537531"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="56894"
                              value={ReactSystemFunctions.translate(this.ml, 56894, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="259234"
                              value={ReactSystemFunctions.translate(this.ml, 259234, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="420777"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_904040_value">
                              <TextInput
                                id="904040"
                                kuikaRef={this.bformuticari_904040_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  904040,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="813762"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="937065"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="675625"
                              value={ReactSystemFunctions.translate(this.ml, 675625, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="720911"
                              value={ReactSystemFunctions.translate(this.ml, 720911, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="188862"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="209794"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="405108"
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                xxl={6}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="bformuticari_655326_value">
                                  <KSelectBox
                                    id="655326"
                                    kuikaRef={this.bformuticari_655326_value_kuikaSelectBoxRef}
                                    options={this.state.dataSource_655326}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      655326,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={true}
                                    autoClearSearchValue={true}
                                    showSearch={false}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="key"
                                    datatextfield="text"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="994843"
                                xs={18}
                                sm={18}
                                md={18}
                                lg={18}
                                xl={18}
                                xxl={18}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    alignItems: "center",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="bformuticari_363998_value">
                                  <TextInput
                                    id="363998"
                                    kuikaRef={this.bformuticari_363998_value_kuikaTextInputRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      363998,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="692394"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="712194"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="958777"
                              value={ReactSystemFunctions.translate(this.ml, 958777, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="810513"
                              value={ReactSystemFunctions.translate(this.ml, 810513, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="327801"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="537611"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="970675"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="bformuticari_59667_value">
                                  <KSelectBox
                                    id="59667"
                                    kuikaRef={this.bformuticari_59667_value_kuikaSelectBoxRef}
                                    options={this.state.dataSource_59667}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      59667,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    autoClearSearchValue={true}
                                    showSearch={false}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="key"
                                    datatextfield="text"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="601804"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    alignItems: "center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="bformuticari_846743_value">
                                  <TextInput
                                    id="846743"
                                    kuikaRef={this.bformuticari_846743_value_kuikaTextInputRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      846743,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="306128"
                          visibility={this.state.isComp306128Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="919512"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="728545"
                              value={ReactSystemFunctions.translate(this.ml, 728545, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="159456"
                              value={ReactSystemFunctions.translate(this.ml, 159456, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="407287"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_301253_value">
                              <TextInput
                                id="301253"
                                kuikaRef={this.bformuticari_301253_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  301253,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="837933"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="986248"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="760794"
                              value={ReactSystemFunctions.translate(this.ml, 760794, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="947230"
                              value={ReactSystemFunctions.translate(this.ml, 947230, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="797299"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_499516_value">
                              <KSelectBox
                                id="499516"
                                onChange={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.BFormuTicariComponent_499516_onChange();
                                }}
                                kuikaRef={this.bformuticari_499516_value_kuikaSelectBoxRef}
                                options={this.state.dataSource_499516}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  499516,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={true}
                                autoClearSearchValue={true}
                                showSearch={false}
                                widthType="fill"
                                containsNullItem={false}
                                sortBy="none"
                                datavaluefield="key"
                                datatextfield="text"
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></KSelectBox>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="173740"
                          visibility={this.state.isComp173740Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="556322"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="200574"
                              value={ReactSystemFunctions.translate(this.ml, 200574, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="385899"
                              value={ReactSystemFunctions.translate(this.ml, 385899, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="383583"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_806100_value">
                              <TextInput
                                id="806100"
                                kuikaRef={this.bformuticari_806100_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  806100,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>

                    <KRow
                      id="755011"
                      visibility={this.state.isComp755011Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="204898"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KRow
                          id="543508"
                          visibility={this.state.isComp543508Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(155, 155, 155, 0.21)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="550180"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingRight: 16,
                                paddingLeft: 16,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="334745"
                              value={ReactSystemFunctions.translate(this.ml, 334745, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="50113"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>

                          <KCol
                            id="180198"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>
                        </KRow>

                        <KRow
                          id="375665"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="526960"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="457053"
                              value={ReactSystemFunctions.translate(this.ml, 457053, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="56240"
                              value={ReactSystemFunctions.translate(this.ml, 56240, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="224853"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_727135_value">
                              <TextInput
                                id="727135"
                                kuikaRef={this.bformuticari_727135_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  727135,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="852087"
                          visibility={this.state.isComp852087Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="900550"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="953111"
                              value={ReactSystemFunctions.translate(this.ml, 953111, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="646489"
                              value={ReactSystemFunctions.translate(this.ml, 646489, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="427021"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_419406_value">
                              <TextInput
                                id="419406"
                                kuikaRef={this.bformuticari_419406_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  419406,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="899289"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="133716"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="742073"
                              value={ReactSystemFunctions.translate(this.ml, 742073, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="625218"
                              value={ReactSystemFunctions.translate(this.ml, 625218, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="708147"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_464905_value">
                              <TextInput
                                id="464905"
                                kuikaRef={this.bformuticari_464905_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  464905,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="365914"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="716801"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="33877"
                              value={ReactSystemFunctions.translate(this.ml, 33877, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="647385"
                              value={ReactSystemFunctions.translate(this.ml, 647385, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="96926"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_919241_value">
                              <TextInput
                                id="919241"
                                kuikaRef={this.bformuticari_919241_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  919241,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="749583"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="746032"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="802533"
                              value={ReactSystemFunctions.translate(this.ml, 802533, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="297204"
                              value={ReactSystemFunctions.translate(this.ml, 297204, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="640131"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>
                        </KRow>

                        <KRow
                          id="475762"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="343908"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="927003"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="532607"
                                xs={1}
                                sm={1}
                                md={1}
                                lg={1}
                                xl={1}
                                xxl={1}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></KCol>

                              <KCol
                                id="948434"
                                xs={23}
                                sm={23}
                                md={23}
                                lg={23}
                                xl={23}
                                xxl={23}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="16118"
                                  value={ReactSystemFunctions.translate(this.ml, 16118, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Label>
                              </KCol>
                            </KRow>
                          </KCol>

                          <KCol
                            id="638596"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_74883_value">
                              <TextInput
                                id="74883"
                                kuikaRef={this.bformuticari_74883_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  74883,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="314344"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="319803"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="41257"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="102524"
                                xs={1}
                                sm={1}
                                md={1}
                                lg={1}
                                xl={1}
                                xxl={1}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></KCol>

                              <KCol
                                id="615366"
                                xs={23}
                                sm={23}
                                md={23}
                                lg={23}
                                xl={23}
                                xxl={23}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="627888"
                                  value={ReactSystemFunctions.translate(this.ml, 627888, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Label>
                              </KCol>
                            </KRow>
                          </KCol>

                          <KCol
                            id="883639"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_136717_value">
                              <TextInput
                                id="136717"
                                kuikaRef={this.bformuticari_136717_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  136717,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="426030"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="952126"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="963633"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="531081"
                                xs={1}
                                sm={1}
                                md={1}
                                lg={1}
                                xl={1}
                                xxl={1}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></KCol>

                              <KCol
                                id="35342"
                                xs={23}
                                sm={23}
                                md={23}
                                lg={23}
                                xl={23}
                                xxl={23}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="158771"
                                  value={ReactSystemFunctions.translate(this.ml, 158771, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Label>
                              </KCol>
                            </KRow>
                          </KCol>

                          <KCol
                            id="644119"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_212231_value">
                              <TextInput
                                id="212231"
                                kuikaRef={this.bformuticari_212231_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  212231,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="771732"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="358585"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="669570"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="892011"
                                xs={1}
                                sm={1}
                                md={1}
                                lg={1}
                                xl={1}
                                xxl={1}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></KCol>

                              <KCol
                                id="277877"
                                xs={23}
                                sm={23}
                                md={23}
                                lg={23}
                                xl={23}
                                xxl={23}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="187627"
                                  value={ReactSystemFunctions.translate(this.ml, 187627, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Label>
                              </KCol>
                            </KRow>
                          </KCol>

                          <KCol
                            id="515269"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_628115_value">
                              <TextInput
                                id="628115"
                                kuikaRef={this.bformuticari_628115_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  628115,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="46984"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="434723"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="39289"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="349434"
                                xs={1}
                                sm={1}
                                md={1}
                                lg={1}
                                xl={1}
                                xxl={1}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></KCol>

                              <KCol
                                id="125522"
                                xs={23}
                                sm={23}
                                md={23}
                                lg={23}
                                xl={23}
                                xxl={23}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="151977"
                                  value={ReactSystemFunctions.translate(this.ml, 151977, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Label>
                              </KCol>
                            </KRow>
                          </KCol>

                          <KCol
                            id="961211"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_774689_value">
                              <TextInput
                                id="774689"
                                kuikaRef={this.bformuticari_774689_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  774689,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="52351"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="870662"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="88655"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="687844"
                                xs={1}
                                sm={1}
                                md={1}
                                lg={1}
                                xl={1}
                                xxl={1}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></KCol>

                              <KCol
                                id="115037"
                                xs={23}
                                sm={23}
                                md={23}
                                lg={23}
                                xl={23}
                                xxl={23}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="378733"
                                  value={ReactSystemFunctions.translate(this.ml, 378733, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Label>

                                <Label
                                  id="960125"
                                  value={ReactSystemFunctions.translate(this.ml, 960125, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(208, 2, 27, 1)",
                                      letterSpacing: "0.5px",
                                      fontStyle: "italic"
                                    } as any
                                  }
                                ></Label>
                              </KCol>
                            </KRow>
                          </KCol>

                          <KCol
                            id="618403"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_176725_value">
                              <TextInput
                                id="176725"
                                kuikaRef={this.bformuticari_176725_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  176725,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="125986"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="45357"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="554583"
                              value={ReactSystemFunctions.translate(this.ml, 554583, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="93222"
                              value={ReactSystemFunctions.translate(this.ml, 93222, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="637719"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_42241_value">
                              <KSelectBox
                                id="42241"
                                kuikaRef={this.bformuticari_42241_value_kuikaSelectBoxRef}
                                options={this.state.dataSource_42241}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  42241,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={true}
                                autoClearSearchValue={true}
                                showSearch={true}
                                widthType="fill"
                                containsNullItem={false}
                                sortBy="none"
                                datavaluefield="key"
                                datatextfield="text"
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    textAlign: "-webkit-left",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></KSelectBox>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="250844"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="37960"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="921473"
                              value={ReactSystemFunctions.translate(this.ml, 921473, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="440226"
                              value={ReactSystemFunctions.translate(this.ml, 440226, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="890015"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="762819"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="177373"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="391566"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="11377"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="bformuticari_151733_value">
                                      <MultiSelect
                                        id="151733"
                                        kuikaRef={this.bformuticari_151733_value_kuikaMultiSelectRef}
                                        options={this.state.dataSource_151733}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          151733,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={true}
                                        autoClearSearchValue={true}
                                        mode="tags"
                                        showSearch={true}
                                        autoRefresh={true}
                                        datavaluefield="key"
                                        datatextfield="text"
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></MultiSelect>
                                    </Form.Item>
                                  </KCol>
                                </KRow>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="786938"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="407080"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="74421"
                              value={ReactSystemFunctions.translate(this.ml, 74421, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="860210"
                              value={ReactSystemFunctions.translate(this.ml, 860210, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="143955"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="285072"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="234715"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="bformuticari_643220_value">
                                  <TextInput
                                    id="643220"
                                    kuikaRef={this.bformuticari_643220_value_kuikaTextInputRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      643220,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="12227"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="635887"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="993383"
                              value={ReactSystemFunctions.translate(this.ml, 993383, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="15418"
                              value={ReactSystemFunctions.translate(this.ml, 15418, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="193236"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_174173_value">
                              <TextInput
                                id="174173"
                                kuikaRef={this.bformuticari_174173_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  174173,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="412605"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="474338"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="399941"
                              value={ReactSystemFunctions.translate(this.ml, 399941, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="62671"
                              value={ReactSystemFunctions.translate(this.ml, 62671, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="95647"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="687992"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="941311"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="bformuticari_314315_value">
                                  <KSelectBox
                                    id="314315"
                                    kuikaRef={this.bformuticari_314315_value_kuikaSelectBoxRef}
                                    options={this.state.dataSource_314315}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      314315,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={true}
                                    autoClearSearchValue={true}
                                    showSearch={false}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="key"
                                    datatextfield="text"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="971933"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="684390"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="317331"
                              value={ReactSystemFunctions.translate(this.ml, 317331, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="378854"
                              value={ReactSystemFunctions.translate(this.ml, 378854, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="928682"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_363699_value">
                              <TextInput
                                id="363699"
                                kuikaRef={this.bformuticari_363699_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  363699,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="235109"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="300801"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="53752"
                              value={ReactSystemFunctions.translate(this.ml, 53752, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="227995"
                              value={ReactSystemFunctions.translate(this.ml, 227995, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="863940"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_631318_value">
                              <TextInput
                                id="631318"
                                kuikaRef={this.bformuticari_631318_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  631318,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>

                    <KRow
                      id="466555"
                      visibility={this.state.isComp466555Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="872274"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KRow
                          id="947730"
                          visibility={this.state.isComp947730Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(155, 155, 155, 0.21)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="30606"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingRight: 16,
                                paddingLeft: 16,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="227848"
                              value={ReactSystemFunctions.translate(this.ml, 227848, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="30709"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>

                          <KCol
                            id="376433"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>
                        </KRow>

                        <KRow
                          id="275065"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="302937"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "dotted",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="386576"
                              value={ReactSystemFunctions.translate(this.ml, 386576, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="425832"
                              value={ReactSystemFunctions.translate(this.ml, 425832, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="258367"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundColor: "rgba(155, 155, 155, 0.21)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_731780_value">
                              <TextInput
                                id="731780"
                                kuikaRef={this.bformuticari_731780_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  731780,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="149956"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="69481"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "dotted",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="417927"
                              value={ReactSystemFunctions.translate(this.ml, 417927, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="418119"
                              value={ReactSystemFunctions.translate(this.ml, 418119, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="859502"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_530996_value">
                              <TextInput
                                id="530996"
                                kuikaRef={this.bformuticari_530996_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  530996,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="240642"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="253963"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "dotted",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="148877"
                              value={ReactSystemFunctions.translate(this.ml, 148877, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="554040"
                              value={ReactSystemFunctions.translate(this.ml, 554040, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="287439"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_35217_value">
                              <TextInput
                                id="35217"
                                kuikaRef={this.bformuticari_35217_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  35217,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="849862"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="319252"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="725698"
                              value={ReactSystemFunctions.translate(this.ml, 725698, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="445016"
                              value={ReactSystemFunctions.translate(this.ml, 445016, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="203830"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_215099_value">
                              <TextInput
                                id="215099"
                                kuikaRef={this.bformuticari_215099_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  215099,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="805488"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="781378"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="203778"
                              value={ReactSystemFunctions.translate(this.ml, 203778, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="722907"
                              value={ReactSystemFunctions.translate(this.ml, 722907, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="345214"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_503111_value">
                              <TextInput
                                id="503111"
                                kuikaRef={this.bformuticari_503111_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  503111,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="424576"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="331461"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="319972"
                              value={ReactSystemFunctions.translate(this.ml, 319972, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="797730"
                              value={ReactSystemFunctions.translate(this.ml, 797730, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="143000"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_800510_value">
                              <TextArea
                                id="800510"
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  800510,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                autoSize={false}
                                showCount={false}
                                formatter="None"
                                readOnly={false}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></TextArea>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="938045"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="592759"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="151120"
                              value={ReactSystemFunctions.translate(this.ml, 151120, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="15206"
                              value={ReactSystemFunctions.translate(this.ml, 15206, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="313097"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_125636_value">
                              <TextInput
                                id="125636"
                                kuikaRef={this.bformuticari_125636_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  125636,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="299525"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="822778"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="880384"
                              value={ReactSystemFunctions.translate(this.ml, 880384, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="48258"
                              value={ReactSystemFunctions.translate(this.ml, 48258, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="544863"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_949599_value">
                              <TextInput
                                id="949599"
                                kuikaRef={this.bformuticari_949599_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  949599,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="262798"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="82820"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="64119"
                              value={ReactSystemFunctions.translate(this.ml, 64119, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="877174"
                              value={ReactSystemFunctions.translate(this.ml, 877174, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="100510"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_180071_value">
                              <KDatePicker
                                id="180071"
                                kuikaRef={this.bformuticari_180071_value_kuikaDateRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  180071,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={true}
                                format="DD/MM/YYYY"
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    height: "36px",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></KDatePicker>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="808560"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="860564"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="123612"
                              value={ReactSystemFunctions.translate(this.ml, 123612, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="783474"
                              value={ReactSystemFunctions.translate(this.ml, 783474, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="71666"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_496974_value">
                              <TextArea
                                id="496974"
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  496974,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                autoSize={false}
                                showCount={false}
                                formatter="None"
                                readOnly={false}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></TextArea>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="257312"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="102544"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="435457"
                              value={ReactSystemFunctions.translate(this.ml, 435457, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="734824"
                              value={ReactSystemFunctions.translate(this.ml, 734824, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="885976"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_408183_value">
                              <TextInput
                                id="408183"
                                kuikaRef={this.bformuticari_408183_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  408183,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="226422"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="275442"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="962391"
                              value={ReactSystemFunctions.translate(this.ml, 962391, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="506579"
                              value={ReactSystemFunctions.translate(this.ml, 506579, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="319251"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_554864_value">
                              <KSelectBox
                                id="554864"
                                kuikaRef={this.bformuticari_554864_value_kuikaSelectBoxRef}
                                options={this.state.dataSource_554864}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  554864,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={true}
                                autoClearSearchValue={true}
                                showSearch={false}
                                widthType="fill"
                                containsNullItem={false}
                                sortBy="none"
                                datavaluefield="key"
                                datatextfield="text"
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    textAlign: "-webkit-left",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></KSelectBox>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="583776"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="303497"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="761157"
                              value={ReactSystemFunctions.translate(this.ml, 761157, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="8328"
                              value={ReactSystemFunctions.translate(this.ml, 8328, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="310496"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_711138_value">
                              <TextInput
                                id="711138"
                                kuikaRef={this.bformuticari_711138_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  711138,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="690692"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="325761"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="357522"
                              value={ReactSystemFunctions.translate(this.ml, 357522, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="869275"
                              value={ReactSystemFunctions.translate(this.ml, 869275, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="237579"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="36930"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="882818"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="bformuticari_193622_value">
                                  <KSelectBox
                                    id="193622"
                                    kuikaRef={this.bformuticari_193622_value_kuikaSelectBoxRef}
                                    options={this.state.dataSource_193622}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      193622,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    autoClearSearchValue={true}
                                    showSearch={false}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="key"
                                    datatextfield="text"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        height: "36px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="295759"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    alignItems: "center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="bformuticari_296139_value">
                                  <TextInput
                                    id="296139"
                                    kuikaRef={this.bformuticari_296139_value_kuikaTextInputRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      296139,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        alignItems: "center",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="744907"
                          visibility={this.state.isComp744907Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="867940"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="988356"
                              value={ReactSystemFunctions.translate(this.ml, 988356, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="918859"
                              value={ReactSystemFunctions.translate(this.ml, 918859, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="394371"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_54329_value">
                              <TextInput
                                id="54329"
                                kuikaRef={this.bformuticari_54329_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  54329,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="494894"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="619277"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="845989"
                              value={ReactSystemFunctions.translate(this.ml, 845989, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="192527"
                              value={ReactSystemFunctions.translate(this.ml, 192527, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="234471"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_139423_value">
                              <KSelectBox
                                id="139423"
                                onChange={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.BFormuTicariComponent_139423_onChange();
                                }}
                                kuikaRef={this.bformuticari_139423_value_kuikaSelectBoxRef}
                                options={this.state.dataSource_139423}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  139423,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={true}
                                autoClearSearchValue={true}
                                showSearch={false}
                                widthType="fill"
                                containsNullItem={false}
                                sortBy="none"
                                datavaluefield="key"
                                datatextfield="text"
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    textAlign: "-webkit-left",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></KSelectBox>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="280419"
                          visibility={this.state.isComp280419Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="856532"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="782166"
                              value={ReactSystemFunctions.translate(this.ml, 782166, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="128287"
                              value={ReactSystemFunctions.translate(this.ml, 128287, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="876805"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_315845_value">
                              <TextInput
                                id="315845"
                                kuikaRef={this.bformuticari_315845_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  315845,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>

                    <KRow
                      id="434573"
                      visibility={this.state.isComp434573Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundColor: "rgba(194, 245, 35, 0.06)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="635235"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KRow
                          id="845643"
                          visibility={this.state.isComp845643Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(155, 155, 155, 0.21)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="640453"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingRight: 16,
                                paddingLeft: 16,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="345052"
                              value={ReactSystemFunctions.translate(this.ml, 345052, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="606607"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>

                          <KCol
                            id="832012"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>
                        </KRow>

                        <KRow
                          id="567461"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="636493"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="476457"
                              value={ReactSystemFunctions.translate(this.ml, 476457, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="291649"
                              value={ReactSystemFunctions.translate(this.ml, 291649, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="1902"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_953799_value">
                              <TextInput
                                id="953799"
                                kuikaRef={this.bformuticari_953799_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  953799,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(221, 219, 219, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="770920"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="314889"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="324637"
                              value={ReactSystemFunctions.translate(this.ml, 324637, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="298608"
                              value={ReactSystemFunctions.translate(this.ml, 298608, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="141127"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_795343_value">
                              <TextInput
                                id="795343"
                                kuikaRef={this.bformuticari_795343_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  795343,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(221, 219, 219, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="872725"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="261043"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="327229"
                              value={ReactSystemFunctions.translate(this.ml, 327229, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="757771"
                              value={ReactSystemFunctions.translate(this.ml, 757771, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="44051"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_894235_value">
                              <TextInput
                                id="894235"
                                kuikaRef={this.bformuticari_894235_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  894235,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(221, 219, 219, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="144222"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="127429"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="973067"
                              value={ReactSystemFunctions.translate(this.ml, 973067, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="546087"
                              value={ReactSystemFunctions.translate(this.ml, 546087, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="466593"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_752355_value">
                              <TextInput
                                id="752355"
                                kuikaRef={this.bformuticari_752355_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  752355,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(221, 219, 219, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="330136"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="619584"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="223091"
                              value={ReactSystemFunctions.translate(this.ml, 223091, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="392461"
                              value={ReactSystemFunctions.translate(this.ml, 392461, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="351123"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_37671_value">
                              <TextInput
                                id="37671"
                                kuikaRef={this.bformuticari_37671_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  37671,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(221, 219, 219, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="583466"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="856635"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="341326"
                              value={ReactSystemFunctions.translate(this.ml, 341326, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="887514"
                              value={ReactSystemFunctions.translate(this.ml, 887514, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="162861"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_183550_value">
                              <TextInput
                                id="183550"
                                kuikaRef={this.bformuticari_183550_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  183550,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(221, 219, 219, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="689597"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="690867"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="284585"
                              value={ReactSystemFunctions.translate(this.ml, 284585, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="520098"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_780979_value">
                              <TextInput
                                id="780979"
                                kuikaRef={this.bformuticari_780979_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  780979,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(221, 219, 219, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="611085"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="210830"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="500664"
                              value={ReactSystemFunctions.translate(this.ml, 500664, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="729249"
                              value={ReactSystemFunctions.translate(this.ml, 729249, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="80289"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_175576_value">
                              <KSelectBox
                                id="175576"
                                kuikaRef={this.bformuticari_175576_value_kuikaSelectBoxRef}
                                options={this.state.dataSource_175576}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  175576,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={true}
                                autoClearSearchValue={true}
                                showSearch={false}
                                widthType="fill"
                                containsNullItem={false}
                                sortBy="none"
                                datavaluefield="key"
                                datatextfield="text"
                                style={
                                  {
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    borderTopWidth: 1,
                                    borderColor: "rgba(204, 204, 204, 1)",
                                    borderStyle: "solid",
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    textAlign: "-webkit-left",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></KSelectBox>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="240767"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="109981"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="81696"
                              value={ReactSystemFunctions.translate(this.ml, 81696, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="932763"
                              value={ReactSystemFunctions.translate(this.ml, 932763, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="276462"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_563208_value">
                              <KSelectBox
                                id="563208"
                                kuikaRef={this.bformuticari_563208_value_kuikaSelectBoxRef}
                                options={this.state.dataSource_563208}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  563208,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={true}
                                autoClearSearchValue={true}
                                showSearch={false}
                                widthType="fill"
                                containsNullItem={false}
                                sortBy="none"
                                datavaluefield="key"
                                datatextfield="text"
                                style={
                                  {
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    borderTopWidth: 1,
                                    borderColor: "rgba(204, 204, 204, 1)",
                                    borderStyle: "solid",
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    textAlign: "-webkit-left",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></KSelectBox>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="484467"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="227618"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="512575"
                              value={ReactSystemFunctions.translate(this.ml, 512575, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="325672"
                              value={ReactSystemFunctions.translate(this.ml, 325672, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="880091"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_861385_value">
                              <TextInput
                                id="861385"
                                kuikaRef={this.bformuticari_861385_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  861385,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(221, 219, 219, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="332410"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="657080"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="883904"
                              value={ReactSystemFunctions.translate(this.ml, 883904, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="942289"
                              value={ReactSystemFunctions.translate(this.ml, 942289, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="341051"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_482186_value">
                              <TextInput
                                id="482186"
                                kuikaRef={this.bformuticari_482186_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  482186,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(221, 219, 219, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="23582"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="444113"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="556956"
                              value={ReactSystemFunctions.translate(this.ml, 556956, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="311922"
                              value={ReactSystemFunctions.translate(this.ml, 311922, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="62679"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_398647_value">
                              <KSelectBox
                                id="398647"
                                kuikaRef={this.bformuticari_398647_value_kuikaSelectBoxRef}
                                options={this.state.dataSource_398647}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  398647,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={true}
                                autoClearSearchValue={true}
                                showSearch={false}
                                widthType="fill"
                                containsNullItem={false}
                                sortBy="none"
                                datavaluefield="key"
                                datatextfield="text"
                                style={
                                  {
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    borderTopWidth: 1,
                                    borderColor: "rgba(204, 204, 204, 1)",
                                    borderStyle: "solid",
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    textAlign: "-webkit-left",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></KSelectBox>
                            </Form.Item>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>

                    <KRow
                      id="535471"
                      visibility={this.state.isComp535471Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="45583"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KRow
                          id="160306"
                          visibility={this.state.isComp160306Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(155, 155, 155, 0.21)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="528239"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingRight: 16,
                                paddingLeft: 16,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="912114"
                              value={ReactSystemFunctions.translate(this.ml, 912114, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="146272"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>

                          <KCol
                            id="538946"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>
                        </KRow>

                        <KRow
                          id="927892"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="409014"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="896912"
                              value={ReactSystemFunctions.translate(this.ml, 896912, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="334888"
                              value={ReactSystemFunctions.translate(this.ml, 334888, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="315601"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_433250_value">
                              <TextInput
                                id="433250"
                                kuikaRef={this.bformuticari_433250_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  433250,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="732044"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="308097"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="841530"
                              value={ReactSystemFunctions.translate(this.ml, 841530, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="5627"
                              value={ReactSystemFunctions.translate(this.ml, 5627, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="468754"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_401545_value">
                              <TextInput
                                id="401545"
                                kuikaRef={this.bformuticari_401545_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  401545,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="282818"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="208147"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="348454"
                              value={ReactSystemFunctions.translate(this.ml, 348454, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="415291"
                              value={ReactSystemFunctions.translate(this.ml, 415291, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="755231"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_548831_value">
                              <TextInput
                                id="548831"
                                kuikaRef={this.bformuticari_548831_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  548831,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="451816"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="82032"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="487282"
                              value={ReactSystemFunctions.translate(this.ml, 487282, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="514296"
                              value={ReactSystemFunctions.translate(this.ml, 514296, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="463778"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_241884_value">
                              <TextInput
                                id="241884"
                                kuikaRef={this.bformuticari_241884_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  241884,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="452862"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="79876"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="503556"
                              value={ReactSystemFunctions.translate(this.ml, 503556, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="168384"
                              value={ReactSystemFunctions.translate(this.ml, 168384, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="129522"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_154279_value">
                              <TextInput
                                id="154279"
                                kuikaRef={this.bformuticari_154279_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  154279,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="108277"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="945541"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="904626"
                              value={ReactSystemFunctions.translate(this.ml, 904626, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="217696"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_306823_value">
                              <TextInput
                                id="306823"
                                kuikaRef={this.bformuticari_306823_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  306823,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="321169"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="633469"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="504443"
                              value={ReactSystemFunctions.translate(this.ml, 504443, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="616327"
                              value={ReactSystemFunctions.translate(this.ml, 616327, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="712080"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_821567_value">
                              <TextArea
                                id="821567"
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  821567,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                autoSize={false}
                                showCount={false}
                                formatter="None"
                                readOnly={false}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></TextArea>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="751351"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="491933"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="199034"
                              value={ReactSystemFunctions.translate(this.ml, 199034, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="56838"
                              value={ReactSystemFunctions.translate(this.ml, 56838, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="888937"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_135744_value">
                              <TextInput
                                id="135744"
                                kuikaRef={this.bformuticari_135744_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  135744,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="889731"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="751873"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="585806"
                              value={ReactSystemFunctions.translate(this.ml, 585806, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="284844"
                              value={ReactSystemFunctions.translate(this.ml, 284844, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="164499"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_109874_value">
                              <TextInput
                                id="109874"
                                kuikaRef={this.bformuticari_109874_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  109874,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="956412"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="562891"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="896508"
                              value={ReactSystemFunctions.translate(this.ml, 896508, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="525934"
                              value={ReactSystemFunctions.translate(this.ml, 525934, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="776475"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_43394_value">
                              <TextInput
                                id="43394"
                                kuikaRef={this.bformuticari_43394_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  43394,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="146848"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="673093"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="533678"
                              value={ReactSystemFunctions.translate(this.ml, 533678, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="960863"
                              value={ReactSystemFunctions.translate(this.ml, 960863, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="862854"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_49563_value">
                              <KSelectBox
                                id="49563"
                                kuikaRef={this.bformuticari_49563_value_kuikaSelectBoxRef}
                                options={this.state.dataSource_49563}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  49563,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={true}
                                autoClearSearchValue={true}
                                showSearch={false}
                                widthType="fill"
                                containsNullItem={false}
                                sortBy="none"
                                datavaluefield="key"
                                datatextfield="text"
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    textAlign: "-webkit-left",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></KSelectBox>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="866703"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="328854"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="366291"
                              value={ReactSystemFunctions.translate(this.ml, 366291, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="746191"
                              value={ReactSystemFunctions.translate(this.ml, 746191, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="914033"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_437312_value">
                              <TextInput
                                id="437312"
                                kuikaRef={this.bformuticari_437312_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  437312,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="35341"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="650993"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="465402"
                              value={ReactSystemFunctions.translate(this.ml, 465402, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="303894"
                              value={ReactSystemFunctions.translate(this.ml, 303894, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="251523"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="289754"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="748869"
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                xxl={6}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="bformuticari_763581_value">
                                  <KSelectBox
                                    id="763581"
                                    kuikaRef={this.bformuticari_763581_value_kuikaSelectBoxRef}
                                    options={this.state.dataSource_763581}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      763581,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={true}
                                    autoClearSearchValue={true}
                                    showSearch={false}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="key"
                                    datatextfield="text"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="865209"
                                xs={18}
                                sm={18}
                                md={18}
                                lg={18}
                                xl={18}
                                xxl={18}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    alignItems: "center",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="bformuticari_478463_value">
                                  <TextInput
                                    id="478463"
                                    kuikaRef={this.bformuticari_478463_value_kuikaTextInputRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      478463,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingBottom: 8,
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="189927"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="434340"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="618413"
                              value={ReactSystemFunctions.translate(this.ml, 618413, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="660459"
                              value={ReactSystemFunctions.translate(this.ml, 660459, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="520783"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="628749"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="278793"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="bformuticari_161165_value">
                                  <KSelectBox
                                    id="161165"
                                    kuikaRef={this.bformuticari_161165_value_kuikaSelectBoxRef}
                                    options={this.state.dataSource_161165}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      161165,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    autoClearSearchValue={true}
                                    showSearch={false}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="key"
                                    datatextfield="text"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        height: "36px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="106015"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="bformuticari_597208_value">
                                  <TextInput
                                    id="597208"
                                    kuikaRef={this.bformuticari_597208_value_kuikaTextInputRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      597208,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="332643"
                          visibility={this.state.isComp332643Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="350353"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="717525"
                              value={ReactSystemFunctions.translate(this.ml, 717525, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="981943"
                              value={ReactSystemFunctions.translate(this.ml, 981943, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="336666"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_108219_value">
                              <TextInput
                                id="108219"
                                kuikaRef={this.bformuticari_108219_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  108219,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="25750"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="969982"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="533641"
                              value={ReactSystemFunctions.translate(this.ml, 533641, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="598093"
                              value={ReactSystemFunctions.translate(this.ml, 598093, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="277553"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_599586_value">
                              <KSelectBox
                                id="599586"
                                onChange={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.BFormuTicariComponent_599586_onChange();
                                }}
                                kuikaRef={this.bformuticari_599586_value_kuikaSelectBoxRef}
                                options={this.state.dataSource_599586}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  599586,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={true}
                                autoClearSearchValue={true}
                                showSearch={false}
                                widthType="fill"
                                containsNullItem={false}
                                sortBy="none"
                                datavaluefield="key"
                                datatextfield="text"
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    textAlign: "-webkit-left",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></KSelectBox>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="561077"
                          visibility={this.state.isComp561077Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="544902"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="61789"
                              value={ReactSystemFunctions.translate(this.ml, 61789, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="129258"
                              value={ReactSystemFunctions.translate(this.ml, 129258, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="157112"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_179006_value">
                              <TextInput
                                id="179006"
                                kuikaRef={this.bformuticari_179006_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  179006,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>

                    <KRow
                      id="564896"
                      visibility={this.state.isComp564896Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="617249"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KRow
                          id="383924"
                          visibility={this.state.isComp383924Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(155, 155, 155, 0.21)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="538083"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingRight: 16,
                                paddingLeft: 16,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="516000"
                              value={ReactSystemFunctions.translate(this.ml, 516000, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="539694"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>

                          <KCol
                            id="126135"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>
                        </KRow>

                        <KRow
                          id="423812"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="517631"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="571477"
                              value={ReactSystemFunctions.translate(this.ml, 571477, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="346100"
                              value={ReactSystemFunctions.translate(this.ml, 346100, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="938993"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_779822_value">
                              <TextInput
                                id="779822"
                                kuikaRef={this.bformuticari_779822_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  779822,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="520875"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="256760"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="504417"
                              value={ReactSystemFunctions.translate(this.ml, 504417, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="39030"
                              value={ReactSystemFunctions.translate(this.ml, 39030, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="157340"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_139034_value">
                              <TextInput
                                id="139034"
                                kuikaRef={this.bformuticari_139034_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  139034,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="182459"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="331072"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="103880"
                              value={ReactSystemFunctions.translate(this.ml, 103880, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="91568"
                              value={ReactSystemFunctions.translate(this.ml, 91568, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="777936"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_213030_value">
                              <TextInput
                                id="213030"
                                kuikaRef={this.bformuticari_213030_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  213030,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="423498"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="399799"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="852214"
                              value={ReactSystemFunctions.translate(this.ml, 852214, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="910891"
                              value={ReactSystemFunctions.translate(this.ml, 910891, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="655001"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_895754_value">
                              <TextInput
                                id="895754"
                                kuikaRef={this.bformuticari_895754_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  895754,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="929526"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="220738"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="817656"
                              value={ReactSystemFunctions.translate(this.ml, 817656, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="907140"
                              value={ReactSystemFunctions.translate(this.ml, 907140, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="333387"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_569899_value">
                              <TextInput
                                id="569899"
                                kuikaRef={this.bformuticari_569899_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  569899,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="137677"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="284405"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="72135"
                              value={ReactSystemFunctions.translate(this.ml, 72135, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="221165"
                              value={ReactSystemFunctions.translate(this.ml, 221165, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="509472"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_179729_value">
                              <TextInput
                                id="179729"
                                kuikaRef={this.bformuticari_179729_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  179729,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="919271"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="603392"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="497710"
                              value={ReactSystemFunctions.translate(this.ml, 497710, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="306774"
                              value={ReactSystemFunctions.translate(this.ml, 306774, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="228136"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_936236_value">
                              <TextInput
                                id="936236"
                                kuikaRef={this.bformuticari_936236_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  936236,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="235675"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="952951"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="882188"
                              value={ReactSystemFunctions.translate(this.ml, 882188, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="988857"
                              value={ReactSystemFunctions.translate(this.ml, 988857, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="607097"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_46982_value">
                              <TextInput
                                id="46982"
                                kuikaRef={this.bformuticari_46982_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  46982,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="870695"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="559115"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="37454"
                              value={ReactSystemFunctions.translate(this.ml, 37454, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="684377"
                              value={ReactSystemFunctions.translate(this.ml, 684377, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="554194"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_925632_value">
                              <TextInput
                                id="925632"
                                kuikaRef={this.bformuticari_925632_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  925632,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="667368"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="158863"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="725703"
                              value={ReactSystemFunctions.translate(this.ml, 725703, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="679747"
                              value={ReactSystemFunctions.translate(this.ml, 679747, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="342711"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_753776_value">
                              <TextInput
                                id="753776"
                                kuikaRef={this.bformuticari_753776_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  753776,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="133472"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="339574"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="917119"
                              value={ReactSystemFunctions.translate(this.ml, 917119, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="175915"
                              value={ReactSystemFunctions.translate(this.ml, 175915, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="291081"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_118061_value">
                              <TextInput
                                id="118061"
                                kuikaRef={this.bformuticari_118061_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  118061,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="720067"
                          visibility={this.state.isComp720067Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="998039"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="576150"
                              value={ReactSystemFunctions.translate(this.ml, 576150, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="861564"
                              value={ReactSystemFunctions.translate(this.ml, 861564, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="486079"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_447965_value">
                              <TextInput
                                id="447965"
                                kuikaRef={this.bformuticari_447965_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  447965,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="838187"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="464341"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="445544"
                              value={ReactSystemFunctions.translate(this.ml, 445544, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="473701"
                              value={ReactSystemFunctions.translate(this.ml, 473701, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="106584"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_636418_value">
                              <KSelectBox
                                id="636418"
                                onChange={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.BFormuTicariComponent_636418_onChange();
                                }}
                                kuikaRef={this.bformuticari_636418_value_kuikaSelectBoxRef}
                                options={this.state.dataSource_636418}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  636418,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={true}
                                autoClearSearchValue={true}
                                showSearch={false}
                                widthType="fill"
                                containsNullItem={false}
                                sortBy="none"
                                datavaluefield="key"
                                datatextfield="text"
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    textAlign: "-webkit-left",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></KSelectBox>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="556886"
                          visibility={this.state.isComp556886Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="480296"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="136186"
                              value={ReactSystemFunctions.translate(this.ml, 136186, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="403672"
                              value={ReactSystemFunctions.translate(this.ml, 403672, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="13955"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_606315_value">
                              <TextInput
                                id="606315"
                                kuikaRef={this.bformuticari_606315_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  606315,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="277416"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="45167"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="347217"
                              value={ReactSystemFunctions.translate(this.ml, 347217, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="600466"
                              value={ReactSystemFunctions.translate(this.ml, 600466, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="5242"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_243523_value">
                              <TextInput
                                id="243523"
                                kuikaRef={this.bformuticari_243523_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  243523,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="790497"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="58195"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="193478"
                              value={ReactSystemFunctions.translate(this.ml, 193478, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="76537"
                              value={ReactSystemFunctions.translate(this.ml, 76537, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="615054"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_252338_value">
                              <TextInput
                                id="252338"
                                kuikaRef={this.bformuticari_252338_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  252338,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="389345"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              textAlign: "-webkit-center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="404214"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="107141"
                              value={ReactSystemFunctions.translate(this.ml, 107141, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="732757"
                              value={ReactSystemFunctions.translate(this.ml, 732757, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="987675"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_63361_value">
                              <TextInput
                                id="63361"
                                kuikaRef={this.bformuticari_63361_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  63361,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>

                    <KRow
                      id="315445"
                      visibility={this.state.isComp315445Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="106408"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KRow
                          id="393979"
                          visibility={this.state.isComp393979Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(155, 155, 155, 0.21)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="829650"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingRight: 16,
                                paddingLeft: 16,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="594473"
                              value={ReactSystemFunctions.translate(this.ml, 594473, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="985711"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>

                          <KCol
                            id="683756"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>
                        </KRow>

                        <KRow
                          id="877622"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="610398"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="922693"
                              value={ReactSystemFunctions.translate(this.ml, 922693, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="361357"
                              value={ReactSystemFunctions.translate(this.ml, 361357, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="359565"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_160425_value">
                              <TextInput
                                id="160425"
                                kuikaRef={this.bformuticari_160425_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  160425,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="914635"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="562468"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="625517"
                              value={ReactSystemFunctions.translate(this.ml, 625517, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="910071"
                              value={ReactSystemFunctions.translate(this.ml, 910071, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="637422"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_634083_value">
                              <TextInput
                                id="634083"
                                kuikaRef={this.bformuticari_634083_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  634083,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="998433"
                          visibility={this.state.isComp998433Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="538470"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="231733"
                              value={ReactSystemFunctions.translate(this.ml, 231733, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="579871"
                              value={ReactSystemFunctions.translate(this.ml, 579871, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="710525"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_375623_value">
                              <TextInput
                                id="375623"
                                kuikaRef={this.bformuticari_375623_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  375623,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="783152"
                          visibility={this.state.isComp783152Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="171262"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="93632"
                              value={ReactSystemFunctions.translate(this.ml, 93632, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="972922"
                              value={ReactSystemFunctions.translate(this.ml, 972922, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="86313"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_84279_value">
                              <TextInput
                                id="84279"
                                kuikaRef={this.bformuticari_84279_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  84279,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="481428"
                          visibility={this.state.isComp481428Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="301015"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="331858"
                              value={ReactSystemFunctions.translate(this.ml, 331858, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="711631"
                              value={ReactSystemFunctions.translate(this.ml, 711631, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="90487"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_952938_value">
                              <KSelectBox
                                id="952938"
                                kuikaRef={this.bformuticari_952938_value_kuikaSelectBoxRef}
                                options={this.state.sbIzinTuruList}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  952938,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={true}
                                autoClearSearchValue={true}
                                showSearch={false}
                                widthType="fill"
                                containsNullItem={false}
                                sortBy="none"
                                datavaluefield="id"
                                datatextfield="izinTuruTanim"
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    textAlign: "-webkit-left",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></KSelectBox>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="284214"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="413737"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="663220"
                              value={ReactSystemFunctions.translate(this.ml, 663220, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="524095"
                              value={ReactSystemFunctions.translate(this.ml, 524095, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="170750"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_330499_value">
                              <TextArea
                                id="330499"
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  330499,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                autoSize={false}
                                showCount={false}
                                formatter="None"
                                readOnly={false}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></TextArea>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="165470"
                          visibility={this.state.isComp165470Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="858478"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="890990"
                              value={ReactSystemFunctions.translate(this.ml, 890990, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="828213"
                              value={ReactSystemFunctions.translate(this.ml, 828213, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="639638"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_575302_value">
                              <TextInput
                                id="575302"
                                kuikaRef={this.bformuticari_575302_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  575302,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="295029"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="139632"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="314967"
                              value={ReactSystemFunctions.translate(this.ml, 314967, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="943966"
                              value={ReactSystemFunctions.translate(this.ml, 943966, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="56352"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_275256_value">
                              <TextInput
                                id="275256"
                                kuikaRef={this.bformuticari_275256_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  275256,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="616391"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="364709"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="378150"
                              value={ReactSystemFunctions.translate(this.ml, 378150, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="659334"
                              value={ReactSystemFunctions.translate(this.ml, 659334, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="604800"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_33436_value">
                              <TextInput
                                id="33436"
                                kuikaRef={this.bformuticari_33436_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  33436,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="911998"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="945635"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="615338"
                              value={ReactSystemFunctions.translate(this.ml, 615338, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="587303"
                              value={ReactSystemFunctions.translate(this.ml, 587303, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="981295"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_601579_value">
                              <TextInput
                                id="601579"
                                kuikaRef={this.bformuticari_601579_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  601579,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="83066"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="340770"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="186097"
                              value={ReactSystemFunctions.translate(this.ml, 186097, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="496812"
                              value={ReactSystemFunctions.translate(this.ml, 496812, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(145, 145, 145, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="415839"
                              value={ReactSystemFunctions.translate(this.ml, 415839, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="115178"
                              value={ReactSystemFunctions.translate(this.ml, 115178, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="532395"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="737176"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="490139"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="bformuticari_925097_value">
                                  <KSelectBox
                                    id="925097"
                                    kuikaRef={this.bformuticari_925097_value_kuikaSelectBoxRef}
                                    options={this.state.dataSource_925097}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      925097,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    autoClearSearchValue={true}
                                    showSearch={false}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="key"
                                    datatextfield="text"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        height: "36px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="700330"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="bformuticari_713576_value">
                                  <TextInput
                                    id="713576"
                                    kuikaRef={this.bformuticari_713576_value_kuikaTextInputRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      713576,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="796381"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="162290"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="938367"
                              value={ReactSystemFunctions.translate(this.ml, 938367, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="703006"
                              value={ReactSystemFunctions.translate(this.ml, 703006, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(145, 145, 145, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="304379"
                              value={ReactSystemFunctions.translate(this.ml, 304379, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="236248"
                              value={ReactSystemFunctions.translate(this.ml, 236248, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="98212"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_776748_value">
                              <KSelectBox
                                id="776748"
                                onChange={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.BFormuTicariComponent_776748_onChange();
                                }}
                                kuikaRef={this.bformuticari_776748_value_kuikaSelectBoxRef}
                                options={this.state.dataSource_776748}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  776748,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                autoClearSearchValue={true}
                                showSearch={false}
                                widthType="fill"
                                containsNullItem={false}
                                sortBy="none"
                                datavaluefield="key"
                                datatextfield="text"
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    height: "36px",
                                    textAlign: "-webkit-left",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></KSelectBox>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="119700"
                          visibility={this.state.isComp119700Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="216302"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="687776"
                              value={ReactSystemFunctions.translate(this.ml, 687776, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="17666"
                              value={ReactSystemFunctions.translate(this.ml, 17666, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="685471"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_883613_value">
                              <TextInput
                                id="883613"
                                kuikaRef={this.bformuticari_883613_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  883613,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="389696"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="555537"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="488501"
                              value={ReactSystemFunctions.translate(this.ml, 488501, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="723115"
                              value={ReactSystemFunctions.translate(this.ml, 723115, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="541259"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_69287_value">
                              <TextInput
                                id="69287"
                                kuikaRef={this.bformuticari_69287_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  69287,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="770538"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="227482"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="572426"
                              value={ReactSystemFunctions.translate(this.ml, 572426, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="662780"
                              value={ReactSystemFunctions.translate(this.ml, 662780, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="959887"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_423768_value">
                              <KSelectBox
                                id="423768"
                                onChange={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.BFormuTicariComponent_423768_onChange();
                                }}
                                kuikaRef={this.bformuticari_423768_value_kuikaSelectBoxRef}
                                options={this.state.dataSource_423768}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  423768,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={true}
                                autoClearSearchValue={true}
                                showSearch={false}
                                widthType="fill"
                                containsNullItem={false}
                                sortBy="none"
                                datavaluefield="key"
                                datatextfield="text"
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    textAlign: "-webkit-left",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></KSelectBox>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="128084"
                          visibility={this.state.isComp128084Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="164104"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="473920"
                              value={ReactSystemFunctions.translate(this.ml, 473920, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="773399"
                              value={ReactSystemFunctions.translate(this.ml, 773399, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="812870"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_680656_value">
                              <TextInput
                                id="680656"
                                kuikaRef={this.bformuticari_680656_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  680656,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="214648"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="390016"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="298287"
                              value={ReactSystemFunctions.translate(this.ml, 298287, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="490903"
                              value={ReactSystemFunctions.translate(this.ml, 490903, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="239279"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_826306_value">
                              <TextArea
                                id="826306"
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  826306,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                autoSize={false}
                                showCount={false}
                                formatter="None"
                                readOnly={false}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></TextArea>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="876566"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="256374"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="264901"
                              value={ReactSystemFunctions.translate(this.ml, 264901, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="645397"
                              value={ReactSystemFunctions.translate(this.ml, 645397, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="416512"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_508876_value">
                              <KDatePicker
                                id="508876"
                                kuikaRef={this.bformuticari_508876_value_kuikaDateRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  508876,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={true}
                                format="DD/MM/YYYY"
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    height: "36px",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></KDatePicker>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="266343"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="808087"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="524042"
                              value={ReactSystemFunctions.translate(this.ml, 524042, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="70753"
                              value={ReactSystemFunctions.translate(this.ml, 70753, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="739682"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_958009_value">
                              <KSelectBox
                                id="958009"
                                kuikaRef={this.bformuticari_958009_value_kuikaSelectBoxRef}
                                options={this.state.sbMunhasirlikList}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  958009,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={true}
                                autoClearSearchValue={true}
                                showSearch={false}
                                widthType="fill"
                                containsNullItem={false}
                                sortBy="none"
                                datavaluefield="id"
                                datatextfield="tanimTREN"
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    textAlign: "-webkit-left",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></KSelectBox>
                            </Form.Item>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>

                    <KRow
                      id="572237"
                      visibility={this.state.isComp572237Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="319360"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KRow
                          id="149325"
                          visibility={this.state.isComp149325Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(155, 155, 155, 0.21)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="749026"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingRight: 16,
                                paddingLeft: 16,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="429605"
                              value={ReactSystemFunctions.translate(this.ml, 429605, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="753868"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>

                          <KCol
                            id="674788"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>
                        </KRow>

                        <KRow
                          id="324554"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="406150"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="156908"
                              value={ReactSystemFunctions.translate(this.ml, 156908, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="653901"
                              value={ReactSystemFunctions.translate(this.ml, 653901, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="140682"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_41842_value">
                              <TextInput
                                id="41842"
                                kuikaRef={this.bformuticari_41842_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  41842,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="733556"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="74692"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="550195"
                              value={ReactSystemFunctions.translate(this.ml, 550195, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="646392"
                              value={ReactSystemFunctions.translate(this.ml, 646392, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="71421"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_769963_value">
                              <TextInput
                                id="769963"
                                kuikaRef={this.bformuticari_769963_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  769963,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="167657"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="417174"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="828086"
                              value={ReactSystemFunctions.translate(this.ml, 828086, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="147974"
                              value={ReactSystemFunctions.translate(this.ml, 147974, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="528281"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_745722_value">
                              <TextInput
                                id="745722"
                                kuikaRef={this.bformuticari_745722_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  745722,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="364537"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="417181"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="228415"
                              value={ReactSystemFunctions.translate(this.ml, 228415, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="180982"
                              value={ReactSystemFunctions.translate(this.ml, 180982, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="327124"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_678596_value">
                              <TextInput
                                id="678596"
                                kuikaRef={this.bformuticari_678596_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  678596,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="680242"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="436693"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="528445"
                              value={ReactSystemFunctions.translate(this.ml, 528445, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="592537"
                              value={ReactSystemFunctions.translate(this.ml, 592537, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="44180"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_13402_value">
                              <TextInput
                                id="13402"
                                kuikaRef={this.bformuticari_13402_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  13402,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="254728"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="156151"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="743001"
                              value={ReactSystemFunctions.translate(this.ml, 743001, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="298537"
                              value={ReactSystemFunctions.translate(this.ml, 298537, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="176400"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_98625_value">
                              <TextInput
                                id="98625"
                                kuikaRef={this.bformuticari_98625_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  98625,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="186621"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="119926"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="294851"
                              value={ReactSystemFunctions.translate(this.ml, 294851, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="171599"
                              value={ReactSystemFunctions.translate(this.ml, 171599, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="809506"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_977010_value">
                              <TextArea
                                id="977010"
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  977010,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                autoSize={false}
                                showCount={false}
                                formatter="None"
                                readOnly={false}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextArea>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="869663"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="158118"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="961458"
                              value={ReactSystemFunctions.translate(this.ml, 961458, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="91412"
                              value={ReactSystemFunctions.translate(this.ml, 91412, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="824070"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_939868_value">
                              <TextInput
                                id="939868"
                                kuikaRef={this.bformuticari_939868_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  939868,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="862678"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="331738"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingBottom: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="446724"
                              value={ReactSystemFunctions.translate(this.ml, 446724, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="23593"
                              value={ReactSystemFunctions.translate(this.ml, 23593, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="441415"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_605461_value">
                              <TextInput
                                id="605461"
                                kuikaRef={this.bformuticari_605461_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  605461,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="48366"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="783220"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="212652"
                              value={ReactSystemFunctions.translate(this.ml, 212652, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="94689"
                              value={ReactSystemFunctions.translate(this.ml, 94689, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="380692"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_348650_value">
                              <TextInput
                                id="348650"
                                kuikaRef={this.bformuticari_348650_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  348650,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="885907"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="613643"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="306213"
                              value={ReactSystemFunctions.translate(this.ml, 306213, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="52685"
                              value={ReactSystemFunctions.translate(this.ml, 52685, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="788181"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_785985_value">
                              <TextInput
                                id="785985"
                                kuikaRef={this.bformuticari_785985_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  785985,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="691050"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="479065"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="865241"
                              value={ReactSystemFunctions.translate(this.ml, 865241, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="691289"
                              value={ReactSystemFunctions.translate(this.ml, 691289, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="107566"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="164268"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="731850"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="bformuticari_114157_value">
                                  <KSelectBox
                                    id="114157"
                                    kuikaRef={this.bformuticari_114157_value_kuikaSelectBoxRef}
                                    options={this.state.dataSource_114157}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      114157,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    autoClearSearchValue={true}
                                    showSearch={false}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="key"
                                    datatextfield="text"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        height: "36px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="710348"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="bformuticari_961538_value">
                                  <TextInput
                                    id="961538"
                                    kuikaRef={this.bformuticari_961538_value_kuikaTextInputRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      961538,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="85527"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="121106"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="127567"
                              value={ReactSystemFunctions.translate(this.ml, 127567, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="938530"
                              value={ReactSystemFunctions.translate(this.ml, 938530, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="723850"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_531317_value">
                              <TextInput
                                id="531317"
                                kuikaRef={this.bformuticari_531317_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  531317,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="202654"
                          visibility={this.state.isComp202654Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="931778"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="335364"
                              value={ReactSystemFunctions.translate(this.ml, 335364, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="126531"
                              value={ReactSystemFunctions.translate(this.ml, 126531, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="620495"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_595383_value">
                              <TextInput
                                id="595383"
                                kuikaRef={this.bformuticari_595383_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  595383,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="837826"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="815874"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="249799"
                              value={ReactSystemFunctions.translate(this.ml, 249799, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="505005"
                              value={ReactSystemFunctions.translate(this.ml, 505005, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="402548"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_945615_value">
                              <KSelectBox
                                id="945615"
                                onChange={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.BFormuTicariComponent_945615_onChange();
                                }}
                                kuikaRef={this.bformuticari_945615_value_kuikaSelectBoxRef}
                                options={this.state.dataSource_945615}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  945615,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={true}
                                autoClearSearchValue={true}
                                showSearch={false}
                                widthType="fill"
                                containsNullItem={false}
                                sortBy="none"
                                datavaluefield="key"
                                datatextfield="text"
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    textAlign: "-webkit-left",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></KSelectBox>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="312802"
                          visibility={this.state.isComp312802Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="951048"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="822386"
                              value={ReactSystemFunctions.translate(this.ml, 822386, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="668749"
                              value={ReactSystemFunctions.translate(this.ml, 668749, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="972486"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_997705_value">
                              <TextInput
                                id="997705"
                                kuikaRef={this.bformuticari_997705_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  997705,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="894244"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="784960"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="485494"
                              value={ReactSystemFunctions.translate(this.ml, 485494, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="7316"
                              value={ReactSystemFunctions.translate(this.ml, 7316, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="89830"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_834746_value">
                              <TextInput
                                id="834746"
                                kuikaRef={this.bformuticari_834746_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  834746,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>

                    <KRow
                      id="307737"
                      visibility={this.state.isComp307737Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="969645"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KRow
                          id="810747"
                          visibility={this.state.isComp810747Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(155, 155, 155, 0.21)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="571659"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingRight: 16,
                                paddingLeft: 16,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="307262"
                              value={ReactSystemFunctions.translate(this.ml, 307262, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="539777"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>

                          <KCol
                            id="232615"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>
                        </KRow>

                        <KRow
                          id="880532"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="542319"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "dotted",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="888270"
                              value={ReactSystemFunctions.translate(this.ml, 888270, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="671637"
                              value={ReactSystemFunctions.translate(this.ml, 671637, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="997513"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_970226_value">
                              <TextInput
                                id="970226"
                                kuikaRef={this.bformuticari_970226_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  970226,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="726633"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="883910"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="12307"
                              value={ReactSystemFunctions.translate(this.ml, 12307, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="392486"
                              value={ReactSystemFunctions.translate(this.ml, 392486, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="69861"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_284840_value">
                              <TextInput
                                id="284840"
                                kuikaRef={this.bformuticari_284840_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  284840,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="477021"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="307315"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="679905"
                              value={ReactSystemFunctions.translate(this.ml, 679905, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="109213"
                              value={ReactSystemFunctions.translate(this.ml, 109213, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="940938"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_584964_value">
                              <TextInput
                                id="584964"
                                kuikaRef={this.bformuticari_584964_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  584964,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="699304"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="210128"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="359392"
                              value={ReactSystemFunctions.translate(this.ml, 359392, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="611955"
                              value={ReactSystemFunctions.translate(this.ml, 611955, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="47250"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_151522_value">
                              <TextInput
                                id="151522"
                                kuikaRef={this.bformuticari_151522_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  151522,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="81707"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="650929"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="782842"
                              value={ReactSystemFunctions.translate(this.ml, 782842, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="928045"
                              value={ReactSystemFunctions.translate(this.ml, 928045, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="233623"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_818678_value">
                              <TextInput
                                id="818678"
                                kuikaRef={this.bformuticari_818678_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  818678,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="88506"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="591563"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="390909"
                              value={ReactSystemFunctions.translate(this.ml, 390909, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="209584"
                              value={ReactSystemFunctions.translate(this.ml, 209584, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="5990"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_300311_value">
                              <TextInput
                                id="300311"
                                kuikaRef={this.bformuticari_300311_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  300311,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="544316"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="27091"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="439235"
                              value={ReactSystemFunctions.translate(this.ml, 439235, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="40130"
                              value={ReactSystemFunctions.translate(this.ml, 40130, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="360805"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_596953_value">
                              <TextInput
                                id="596953"
                                kuikaRef={this.bformuticari_596953_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  596953,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="941153"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="201713"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="435909"
                              value={ReactSystemFunctions.translate(this.ml, 435909, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="517660"
                              value={ReactSystemFunctions.translate(this.ml, 517660, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="637963"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_38491_value">
                              <KDatePicker
                                id="38491"
                                kuikaRef={this.bformuticari_38491_value_kuikaDateRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  38491,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={true}
                                format="DD/MM/YYYY"
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    height: "36px",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></KDatePicker>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="10975"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="677468"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="346715"
                              value={ReactSystemFunctions.translate(this.ml, 346715, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="206320"
                              value={ReactSystemFunctions.translate(this.ml, 206320, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="908624"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_701444_value">
                              <TextInput
                                id="701444"
                                kuikaRef={this.bformuticari_701444_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  701444,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="947805"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="463492"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="975995"
                              value={ReactSystemFunctions.translate(this.ml, 975995, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="403387"
                              value={ReactSystemFunctions.translate(this.ml, 403387, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="418548"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_475588_value">
                              <KSelectBox
                                id="475588"
                                kuikaRef={this.bformuticari_475588_value_kuikaSelectBoxRef}
                                options={this.state.dataSource_475588}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  475588,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={true}
                                autoClearSearchValue={true}
                                showSearch={false}
                                widthType="fill"
                                containsNullItem={false}
                                sortBy="none"
                                datavaluefield="key"
                                datatextfield="text"
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    textAlign: "-webkit-left",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></KSelectBox>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="189842"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="797504"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="725834"
                              value={ReactSystemFunctions.translate(this.ml, 725834, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="146790"
                              value={ReactSystemFunctions.translate(this.ml, 146790, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="529976"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_382382_value">
                              <TextInput
                                id="382382"
                                kuikaRef={this.bformuticari_382382_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  382382,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="864572"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="478789"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="921985"
                              value={ReactSystemFunctions.translate(this.ml, 921985, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="897299"
                              value={ReactSystemFunctions.translate(this.ml, 897299, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="626294"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="732711"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="382355"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="bformuticari_841151_value">
                                  <KSelectBox
                                    id="841151"
                                    kuikaRef={this.bformuticari_841151_value_kuikaSelectBoxRef}
                                    options={this.state.dataSource_841151}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      841151,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    autoClearSearchValue={true}
                                    showSearch={false}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="key"
                                    datatextfield="text"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        height: "36px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="106579"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="bformuticari_691237_value">
                                  <TextInput
                                    id="691237"
                                    kuikaRef={this.bformuticari_691237_value_kuikaTextInputRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      691237,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="201385"
                          visibility={this.state.isComp201385Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="736382"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="735192"
                              value={ReactSystemFunctions.translate(this.ml, 735192, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="137547"
                              value={ReactSystemFunctions.translate(this.ml, 137547, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="677828"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_232600_value">
                              <TextInput
                                id="232600"
                                kuikaRef={this.bformuticari_232600_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  232600,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="530398"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="524683"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="206723"
                              value={ReactSystemFunctions.translate(this.ml, 206723, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="595972"
                              value={ReactSystemFunctions.translate(this.ml, 595972, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="385492"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_653975_value">
                              <KSelectBox
                                id="653975"
                                onChange={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.BFormuTicariComponent_653975_onChange();
                                }}
                                kuikaRef={this.bformuticari_653975_value_kuikaSelectBoxRef}
                                options={this.state.dataSource_653975}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  653975,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={true}
                                autoClearSearchValue={true}
                                showSearch={false}
                                widthType="fill"
                                containsNullItem={false}
                                sortBy="none"
                                datavaluefield="key"
                                datatextfield="text"
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    textAlign: "-webkit-left",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></KSelectBox>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="437424"
                          visibility={this.state.isComp437424Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="204"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="614126"
                              value={ReactSystemFunctions.translate(this.ml, 614126, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="40989"
                              value={ReactSystemFunctions.translate(this.ml, 40989, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="953069"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_607210_value">
                              <TextInput
                                id="607210"
                                kuikaRef={this.bformuticari_607210_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  607210,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>

                    <KRow
                      id="269167"
                      visibility={this.state.isComp269167Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="876437"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KRow
                          id="977493"
                          visibility={this.state.isComp977493Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(155, 155, 155, 0.21)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="802361"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingRight: 16,
                                paddingLeft: 16,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="281356"
                              value={ReactSystemFunctions.translate(this.ml, 281356, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="741960"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>

                          <KCol
                            id="140439"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>
                        </KRow>

                        <KRow
                          id="903184"
                          visibility={this.state.isComp903184Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="123747"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="562480"
                              value={ReactSystemFunctions.translate(this.ml, 562480, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="61977"
                              value={ReactSystemFunctions.translate(this.ml, 61977, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="194524"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_709919_value">
                              <TextInput
                                id="709919"
                                kuikaRef={this.bformuticari_709919_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  709919,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(221, 219, 219, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="67119"
                          visibility={this.state.isComp67119Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="560945"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="242048"
                              value={ReactSystemFunctions.translate(this.ml, 242048, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="638593"
                              value={ReactSystemFunctions.translate(this.ml, 638593, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="305357"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderBottomWidth: 1,
                                borderColor: "rgba(238, 237, 237, 1)",
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_830230_value">
                              <KSelectBox
                                id="830230"
                                kuikaRef={this.bformuticari_830230_value_kuikaSelectBoxRef}
                                options={this.state.dataSource_830230}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  830230,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                autoClearSearchValue={true}
                                showSearch={false}
                                widthType="fill"
                                containsNullItem={false}
                                sortBy="none"
                                options={this.state.dataSource_830230}
                                datavaluefield=""
                                datatextfield=""
                                style={
                                  {
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(255, 255, 255, 1)",
                                    borderStyle: "dashed",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    textAlign: "-webkit-left",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></KSelectBox>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="615583"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="395952"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="490197"
                              value={ReactSystemFunctions.translate(this.ml, 490197, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="763387"
                              value={ReactSystemFunctions.translate(this.ml, 763387, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="958534"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_169770_value">
                              <TextInput
                                id="169770"
                                visibility={this.state.isComp169770Visible}
                                kuikaRef={this.bformuticari_169770_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  169770,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(221, 219, 219, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>

                            <Form.Item className="kFormItem" name="bformuticari_211926_value">
                              <TextArea
                                id="211926"
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  211926,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                autoSize={false}
                                showCount={false}
                                formatter="None"
                                readOnly={false}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></TextArea>
                            </Form.Item>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>

                    <KRow
                      id="138635"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 16,
                          paddingBottom: 8,
                          alignItems: "center",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="335535"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <HorizontalStack
                          id="815498"
                          direction="horizontal"
                          size={12}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <Label
                            id="185089"
                            value={ReactSystemFunctions.translate(this.ml, 185089, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></Label>
                        </HorizontalStack>

                        <Label
                          id="267584"
                          value={ReactSystemFunctions.translate(this.ml, 267584, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(208, 2, 27, 1)",
                              letterSpacing: "0.5px",
                              fontStyle: "italic"
                            } as any
                          }
                        ></Label>
                      </KCol>

                      <KCol
                        id="101010"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            textAlign: "-webkit-left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KRow
                          id="251924"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 16,
                              paddingBottom: 16,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="199748"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_926329_value" noStyle>
                              <KDatePicker
                                id="926329"
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  926329,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={true}
                                format="DD/MM/YYYY"
                                use12Hours={false}
                                disableBeforeDate={moment().format("YYYY-MM-DDTHH:mm:ss")}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "36px",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></KDatePicker>
                            </Form.Item>
                          </KCol>

                          <KCol
                            id="60331"
                            xs={16}
                            sm={16}
                            md={16}
                            lg={16}
                            xl={16}
                            xxl={16}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingLeft: 24,
                                alignItems: "center",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="836929"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="265813"
                                xs={16}
                                sm={16}
                                md={16}
                                lg={16}
                                xl={16}
                                xxl={16}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="966662"
                                  value={ReactSystemFunctions.translate(this.ml, 966662, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Label>

                                <Label
                                  id="20503"
                                  value={ReactSystemFunctions.translate(this.ml, 20503, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(208, 2, 27, 1)",
                                      letterSpacing: "0.5px",
                                      fontStyle: "italic"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="591288"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="bformuticari_950974_value">
                                  <Checkbox
                                    id="950974"
                                    children={ReactSystemFunctions.translate(
                                      this.ml,
                                      950974,
                                      "children",
                                      this.defaultML
                                    )}
                                    disabled={false}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingLeft: 12,
                                        display: "inline",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></Checkbox>
                                </Form.Item>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>

                    <KRow
                      id="810627"
                      visibility={this.state.isComp810627Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 16,
                          paddingBottom: 8,
                          alignItems: "center",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="81513"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="701822"
                          value={ReactSystemFunctions.translate(this.ml, 701822, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="312461"
                          value={ReactSystemFunctions.translate(this.ml, 312461, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(208, 2, 27, 1)",
                              letterSpacing: "0.5px",
                              fontStyle: "italic"
                            } as any
                          }
                        ></Label>
                      </KCol>

                      <KCol
                        id="457571"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            textAlign: "-webkit-left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Spacer id="618212" direction="horizontal" size={12} style={{} as any}>
                          <Form.Item className="kFormItem" name="bformuticari_417431_value">
                            <Checkbox
                              id="417431"
                              onChange={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.BFormuTicariComponent_417431_onChange();
                              }}
                              children={ReactSystemFunctions.translate(this.ml, 417431, "children", this.defaultML)}
                              disabled={false}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Checkbox>
                          </Form.Item>
                        </Spacer>

                        <Label
                          id="879397"
                          value={ReactSystemFunctions.translate(this.ml, 879397, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(208, 2, 27, 1)",
                              letterSpacing: "0.5px",
                              fontStyle: "italic"
                            } as any
                          }
                        ></Label>
                      </KCol>
                    </KRow>

                    <KRow
                      id="176741"
                      visibility={this.state.isComp176741Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 24,
                          paddingBottom: 0,
                          paddingLeft: 8,
                          alignItems: "center",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="496960"
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                        xxl={4}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="283848"
                          value={ReactSystemFunctions.translate(this.ml, 283848, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "10px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>
                      </KCol>

                      <KCol
                        id="311123"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="304820"
                          visibility={this.state.isComp304820Visible}
                          value={ReactSystemFunctions.translate(this.ml, 304820, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "10px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="541034"
                          visibility={this.state.isComp541034Visible}
                          value={ReactSystemFunctions.translate(this.ml, 541034, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "10px",
                              color: "rgba(172, 38, 42, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>
                      </KCol>

                      <KCol
                        id="649592"
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        xxl={2}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="122781"
                          value={ReactSystemFunctions.translate(this.ml, 122781, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "10px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>
                      </KCol>

                      <KCol
                        id="164812"
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        xxl={2}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="344261"
                          value={ReactSystemFunctions.translate(this.ml, 344261, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "10px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>
                      </KCol>

                      <KCol
                        id="389308"
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        xxl={2}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="444884"
                          value={ReactSystemFunctions.translate(this.ml, 444884, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "10px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>
                      </KCol>

                      <KCol
                        id="981969"
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        xxl={2}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></KCol>
                    </KRow>

                    <KRow
                      id="227437"
                      visibility={this.state.isComp227437Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 0,
                          paddingBottom: 24,
                          paddingLeft: 8,
                          alignItems: "center",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="800943"
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                        xxl={4}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Form.Item className="kFormItem" name="bformuticari_141657_value">
                          <TextInput
                            id="141657"
                            kuikaRef={this.bformuticari_141657_value_kuikaTextInputRef}
                            placeholder={ReactSystemFunctions.translate(this.ml, 141657, "placeholder", this.defaultML)}
                            allowClear={false}
                            bordered={true}
                            disabled={false}
                            type="text"
                            iconColor="rgba(0, 0, 0, 1)"
                            formatter="None"
                            isCharOnly={false}
                            autoComplete={true}
                            style={
                              {
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                height: "36px",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(47, 47, 47, 1)"
                              } as any
                            }
                          ></TextInput>
                        </Form.Item>
                      </KCol>

                      <KCol
                        id="672564"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Form.Item className="kFormItem" name="bformuticari_570080_value">
                          <TextInput
                            id="570080"
                            kuikaRef={this.bformuticari_570080_value_kuikaTextInputRef}
                            placeholder={ReactSystemFunctions.translate(this.ml, 570080, "placeholder", this.defaultML)}
                            allowClear={false}
                            bordered={true}
                            disabled={false}
                            type="text"
                            iconColor="rgba(0, 0, 0, 1)"
                            formatter="None"
                            isCharOnly={false}
                            autoComplete={true}
                            style={
                              {
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                height: "36px",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(47, 47, 47, 1)"
                              } as any
                            }
                          ></TextInput>
                        </Form.Item>
                      </KCol>

                      <KCol
                        id="986215"
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        xxl={2}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Form.Item className="kFormItem" name="bformuticari_770932_value">
                          <NumberInput
                            id="770932"
                            decimalSeparator="."
                            decimalScale={2}
                            disabled={false}
                            placeholder={ReactSystemFunctions.translate(this.ml, 770932, "placeholder", this.defaultML)}
                            fixedDecimalScale={false}
                            allowNegative={true}
                            allowEmptyFormatting={false}
                            isThousandSeperatorOn={true}
                            style={
                              {
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                height: "36px",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></NumberInput>
                        </Form.Item>
                      </KCol>

                      <KCol
                        id="515078"
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        xxl={2}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Form.Item className="kFormItem" name="bformuticari_707397_value">
                          <NumberInput
                            id="707397"
                            decimalSeparator="."
                            decimalScale={2}
                            disabled={false}
                            placeholder={ReactSystemFunctions.translate(this.ml, 707397, "placeholder", this.defaultML)}
                            fixedDecimalScale={false}
                            allowNegative={true}
                            allowEmptyFormatting={false}
                            isThousandSeperatorOn={true}
                            style={
                              {
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                height: "36px",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></NumberInput>
                        </Form.Item>
                      </KCol>

                      <KCol
                        id="222014"
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        xxl={2}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Form.Item className="kFormItem" name="bformuticari_968501_value">
                          <NumberInput
                            id="968501"
                            decimalSeparator="."
                            decimalScale={2}
                            disabled={false}
                            placeholder={ReactSystemFunctions.translate(this.ml, 968501, "placeholder", this.defaultML)}
                            fixedDecimalScale={false}
                            allowNegative={true}
                            allowEmptyFormatting={false}
                            isThousandSeperatorOn={true}
                            style={
                              {
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                height: "36px",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></NumberInput>
                        </Form.Item>
                      </KCol>

                      <KCol
                        id="145678"
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        xxl={2}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingRight: 16,
                            paddingLeft: 16,
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Button
                          id="123993"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.BFormuTicariComponent_123993_onClick();
                          }}
                          showCursorPointer
                          visibility={this.state.isComp123993Visible}
                          label={ReactSystemFunctions.translate(this.ml, 123993, "label", this.defaultML)}
                          size="middle"
                          loading={false}
                          ghost={false}
                          block={false}
                          htmlType="button"
                          icon="add"
                          iconPosition="left"
                          danger={false}
                          style={
                            {
                              borderTopLeftRadius: 32,
                              borderTopRightRadius: 32,
                              borderBottomRightRadius: 32,
                              borderBottomLeftRadius: 32,
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(172, 38, 42, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 1,
                              paddingRight: 6,
                              paddingBottom: 1,
                              paddingLeft: 6,
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "16px",
                              color: "rgba(255, 255, 255, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Button>
                      </KCol>
                    </KRow>

                    <KRow
                      id="307784"
                      visibility={this.state.isComp307784Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderTopLeftRadius: 12,
                          borderTopRightRadius: 12,
                          borderBottomRightRadius: 12,
                          borderBottomLeftRadius: 12,
                          borderTopWidth: 1,
                          borderRightWidth: 1,
                          borderBottomWidth: 1,
                          borderLeftWidth: 1,
                          borderColor: "rgba(155, 155, 155, 1)",
                          borderStyle: "dotted",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingBottom: 24,
                          alignItems: "center",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="905095"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KRow
                          id="709147"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderTopLeftRadius: 12,
                              borderTopRightRadius: 12,
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(155, 155, 155, 0.17)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 16,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="252913"
                            xs={4}
                            sm={4}
                            md={4}
                            lg={4}
                            xl={4}
                            xxl={4}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="166525"
                              value={ReactSystemFunctions.translate(this.ml, 166525, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "10px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="262739"
                            xs={13}
                            sm={13}
                            md={13}
                            lg={13}
                            xl={13}
                            xxl={13}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="415808"
                              value={ReactSystemFunctions.translate(this.ml, 415808, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "10px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="516345"
                            xs={2}
                            sm={2}
                            md={2}
                            lg={2}
                            xl={2}
                            xxl={2}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                textAlign: "-webkit-center",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="569809"
                              value={ReactSystemFunctions.translate(this.ml, 569809, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "10px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="170926"
                            xs={2}
                            sm={2}
                            md={2}
                            lg={2}
                            xl={2}
                            xxl={2}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                textAlign: "-webkit-center",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="237488"
                              value={ReactSystemFunctions.translate(this.ml, 237488, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "10px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="252942"
                            xs={2}
                            sm={2}
                            md={2}
                            lg={2}
                            xl={2}
                            xxl={2}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                textAlign: "-webkit-center",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="851322"
                              value={ReactSystemFunctions.translate(this.ml, 851322, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "10px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="626365"
                            xs={1}
                            sm={1}
                            md={1}
                            lg={1}
                            xl={1}
                            xxl={1}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>
                        </KRow>

                        <KTable
                          id="577170"
                          kuikaRef={this.bformuticari_577170_value_kuikaTableRef}
                          form={this.props.form}
                          dataSource={this.state.getListKullanimByBasvuruFId}
                          size="middle"
                          bordered={true}
                          showHeader={false}
                          loading={false}
                          nodatafoundmessage={ReactSystemFunctions.translate(
                            this.ml,
                            577170,
                            "nodatafoundmessage",
                            this.defaultML
                          )}
                          sorter={false}
                          pagination={false}
                          striped={false}
                          stripedColor="#F5F7FA"
                          insertRowActive={false}
                          searchable={false}
                          filtering={false}
                          transformedOnMobileResolution={false}
                          tableWidthMode="fit"
                          fixedHeader={false}
                          pageSize={10}
                          showSizeChanger={false}
                          columnChooser={false}
                          insertRowPosition="top"
                          multiSelect={false}
                          editable={false}
                          globalSearch={false}
                          resizableColumns={false}
                          showNoDataFound={true}
                          export={false}
                          hideHeaderOnMobileResolution={false}
                          editableAlwaysActive={false}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(155, 155, 155, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KTableHeader
                            id="60241"
                            hideOnMobileResolution={false}
                            textDirection="Default"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KTableHeader>

                          <KTableRow
                            id="691646"
                            hoverFontColor="rgba(255, 255, 255, 1)"
                            hoverBgColor="rgba(231, 231, 230, 1)"
                            style={
                              {
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                                borderBottomLeftRadius: 0,
                                borderTopWidth: 1,
                                borderColor: "rgba(204, 204, 204, 1)",
                                borderStyle: "solid",
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KTableColumn
                              id="211540"
                              title={ReactSystemFunctions.translate(this.ml, 211540, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              footerFontSize={14}
                              footerFontColor="rgb(68, 68, 68)"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KRow
                                id="591655"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 16,
                                    paddingBottom: 16,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    fontWeight: 500,
                                    fontSize: "24px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KCol
                                  id="60653"
                                  xs={4}
                                  sm={4}
                                  md={4}
                                  lg={4}
                                  xl={4}
                                  xxl={4}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="284897"
                                    value="[datafield:bilgi]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="981296"
                                  xs={13}
                                  sm={13}
                                  md={13}
                                  lg={13}
                                  xl={13}
                                  xxl={13}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="315736"
                                    value="[datafield:muzikKod]"
                                    maxLine={3}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="261721"
                                  xs={2}
                                  sm={2}
                                  md={2}
                                  lg={2}
                                  xl={2}
                                  xxl={2}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      textAlign: "-webkit-center",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="871564"
                                    value="[datafield:sureSaat]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingRight: 8,
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="306262"
                                  xs={2}
                                  sm={2}
                                  md={2}
                                  lg={2}
                                  xl={2}
                                  xxl={2}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      textAlign: "-webkit-center",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="922552"
                                    value="[datafield:sureDakika]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingRight: 8,
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="880956"
                                  xs={2}
                                  sm={2}
                                  md={2}
                                  lg={2}
                                  xl={2}
                                  xxl={2}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      textAlign: "-webkit-center",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="962051"
                                    value="[datafield:sureSaniye]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingRight: 8,
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="886621"
                                  xs={1}
                                  sm={1}
                                  md={1}
                                  lg={1}
                                  xl={1}
                                  xxl={1}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Icon
                                    id="439359"
                                    onClick={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.BFormuTicariComponent_439359_onClick();
                                    }}
                                    showCursorPointer
                                    visibility={this.state.isComp439359Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isEqualTo(
                                        this.state.getBasvuruFormuById[0]?.formReadOnly?.toString(),
                                        "0"
                                      )
                                    }
                                    iconName="remove_circle_outline"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "16px",
                                        color: "rgba(172, 38, 42, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></Icon>
                                </KCol>
                              </KRow>
                            </KTableColumn>
                          </KTableRow>
                        </KTable>
                      </KCol>
                    </KRow>

                    <KRow
                      id="421914"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 36,
                          alignItems: "center",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="243594"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingRight: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <HorizontalStack
                          id="589549"
                          direction="horizontal"
                          size={12}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <Label
                            id="489554"
                            value={ReactSystemFunctions.translate(this.ml, 489554, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></Label>
                        </HorizontalStack>

                        <Label
                          id="280014"
                          value={ReactSystemFunctions.translate(this.ml, 280014, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(208, 2, 27, 1)",
                              letterSpacing: "0.5px",
                              fontStyle: "italic"
                            } as any
                          }
                        ></Label>
                      </KCol>

                      <KCol
                        id="764918"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Form.Item className="kFormItem" name="bformuticari_409878_value">
                          <TextArea
                            id="409878"
                            placeholder={ReactSystemFunctions.translate(this.ml, 409878, "placeholder", this.defaultML)}
                            allowClear={false}
                            autoSize={false}
                            showCount={false}
                            formatter="None"
                            readOnly={false}
                            style={
                              {
                                borderBottomWidth: 1,
                                borderColor: "rgba(155, 155, 155, 1)",
                                borderStyle: "dotted",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(47, 47, 47, 1)"
                              } as any
                            }
                          ></TextArea>
                        </Form.Item>
                      </KCol>
                    </KRow>

                    <KRow
                      id="692704"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 16,
                          paddingBottom: 16,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="777577"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="338718"
                          value={ReactSystemFunctions.translate(this.ml, 338718, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="605839"
                          value={ReactSystemFunctions.translate(this.ml, 605839, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(208, 2, 27, 1)",
                              letterSpacing: "0.5px",
                              fontStyle: "italic"
                            } as any
                          }
                        ></Label>
                      </KCol>

                      <KCol
                        id="611071"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KRow
                          id="152566"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingBottom: 8,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="276971"
                            xs={19}
                            sm={19}
                            md={19}
                            lg={19}
                            xl={19}
                            xxl={19}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_241255_value">
                              <TextInput
                                id="241255"
                                visibility={this.state.isComp241255Visible}
                                kuikaRef={this.bformuticari_241255_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  241255,
                                  "placeholder",
                                  this.defaultML
                                )}
                                maxLength={48}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(208, 2, 27, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    height: "36px",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>

                          <KCol
                            id="567130"
                            xs={5}
                            sm={5}
                            md={5}
                            lg={5}
                            xl={5}
                            xxl={5}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Button
                              id="262195"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.BFormuTicariComponent_262195_onClick();
                              }}
                              showCursorPointer
                              visibility={this.state.isComp262195Visible}
                              label={ReactSystemFunctions.translate(this.ml, 262195, "label", this.defaultML)}
                              size="middle"
                              loading={false}
                              ghost={false}
                              block={false}
                              htmlType="button"
                              iconPosition="left"
                              danger={false}
                              style={
                                {
                                  backgroundColor: "rgba(172, 38, 42, 1)",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(255, 255, 255, 1)"
                                } as any
                              }
                            ></Button>
                          </KCol>
                        </KRow>

                        <KRow
                          id="118971"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="289484"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KTable
                              id="614193"
                              kuikaRef={this.bformuticari_614193_value_kuikaTableRef}
                              form={this.props.form}
                              dataSource={this.state.getDokumansListByBFId}
                              size="middle"
                              bordered={true}
                              showHeader={false}
                              loading={false}
                              nodatafoundmessage={ReactSystemFunctions.translate(
                                this.ml,
                                614193,
                                "nodatafoundmessage",
                                this.defaultML
                              )}
                              sorter={false}
                              pagination={false}
                              hideTableOnEmpty={true}
                              striped={false}
                              stripedColor="#F5F7FA"
                              insertRowActive={false}
                              searchable={false}
                              filtering={false}
                              transformedOnMobileResolution={false}
                              tableWidthMode="fit"
                              fixedHeader={false}
                              pageSize={10}
                              showSizeChanger={false}
                              columnChooser={false}
                              insertRowPosition="top"
                              multiSelect={false}
                              editable={false}
                              globalSearch={false}
                              resizableColumns={false}
                              showNoDataFound={true}
                              export={false}
                              hideHeaderOnMobileResolution={false}
                              editableAlwaysActive={false}
                              style={
                                {
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KTableHeader
                                id="333763"
                                hideOnMobileResolution={false}
                                textDirection="Default"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0
                                  } as any
                                }
                              ></KTableHeader>

                              <KTableRow
                                id="53566"
                                hoverFontColor="red"
                                hoverBgColor="#F5F5F5"
                                style={
                                  {
                                    paddingTop: 8,
                                    paddingBottom: 8,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KTableColumn
                                  id="837076"
                                  title={ReactSystemFunctions.translate(this.ml, 837076, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KRow
                                    id="283346"
                                    align="top"
                                    justify="start"
                                    horizontalGutter={0}
                                    verticalGutter={0}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KCol
                                      id="663401"
                                      xs={22}
                                      sm={22}
                                      md={22}
                                      lg={22}
                                      xl={22}
                                      xxl={22}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingBottom: 8,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="12374"
                                        onClick={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.BFormuTicariComponent_12374_onClick();
                                        }}
                                        showCursorPointer
                                        value="[datafield:dosyaTanim]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(74, 144, 226, 1)",
                                            letterSpacing: "0.5px",
                                            textDecoration: "underline"
                                          } as any
                                        }
                                      ></Label>
                                    </KCol>

                                    <KCol
                                      id="325569"
                                      xs={2}
                                      sm={2}
                                      md={2}
                                      lg={2}
                                      xl={2}
                                      xxl={2}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingBottom: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Icon
                                        id="550139"
                                        onClick={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.BFormuTicariComponent_550139_onClick();
                                        }}
                                        showCursorPointer
                                        visibility={this.state.isComp550139Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isEmpty(
                                            this.state.getBasvuruFormuById[0]?.id?.toString(),
                                            "true"
                                          ) ||
                                          ReactSystemFunctions.isEqualTo(
                                            this.state.getBasvuruFormuById[0]?.formReadOnly?.toString(),
                                            "0"
                                          )
                                        }
                                        iconName="delete"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontSize: "24px",
                                            color: "rgba(172, 38, 42, 1)"
                                          } as any
                                        }
                                      ></Icon>
                                    </KCol>
                                  </KRow>
                                </KTableColumn>
                              </KTableRow>
                            </KTable>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>

                    <KRow
                      id="884757"
                      visibility={this.state.isComp884757Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 48,
                          paddingBottom: 24,
                          alignItems: "center",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="638566"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="14922"
                          value={ReactSystemFunctions.translate(this.ml, 14922, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="247678"
                          value={ReactSystemFunctions.translate(this.ml, 247678, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(208, 2, 27, 1)",
                              letterSpacing: "0.5px",
                              fontStyle: "italic"
                            } as any
                          }
                        ></Label>
                      </KCol>
                    </KRow>

                    <KRow
                      id="49749"
                      visibility={this.state.isComp49749Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 48,
                          paddingBottom: 24,
                          alignItems: "center",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="398490"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="537354"
                          value={ReactSystemFunctions.translate(this.ml, 537354, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 700,
                              fontSize: "12px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <KRow
                          id="355004"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 16,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="2614"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="2796"
                              value={ReactSystemFunctions.translate(this.ml, 2796, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 700,
                                  fontSize: "12px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>

                    <KRow
                      id="581358"
                      visibility={this.state.isComp581358Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 48,
                          alignItems: "center",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="35660"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="684888"
                          value={ReactSystemFunctions.translate(this.ml, 684888, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <KRow
                          id="537145"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 16,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="920492"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="985532"
                              value={ReactSystemFunctions.translate(this.ml, 985532, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="884483"
                              value={ReactSystemFunctions.translate(this.ml, 884483, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="59341"
                              value={ReactSystemFunctions.translate(this.ml, 59341, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>

                    <KRow
                      id="874517"
                      visibility={this.state.isComp874517Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 48,
                          alignItems: "center",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="994423"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="222266"
                          value={ReactSystemFunctions.translate(this.ml, 222266, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <KRow
                          id="364118"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 16,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="492190"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="797212"
                              value={ReactSystemFunctions.translate(this.ml, 797212, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </KCol>
                        </KRow>

                        <Label
                          id="514522"
                          value={ReactSystemFunctions.translate(this.ml, 514522, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(208, 2, 27, 1)",
                              letterSpacing: "0.5px",
                              fontStyle: "italic"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="959885"
                          value={ReactSystemFunctions.translate(this.ml, 959885, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(208, 2, 27, 1)",
                              letterSpacing: "0.5px",
                              fontStyle: "italic"
                            } as any
                          }
                        ></Label>
                      </KCol>
                    </KRow>

                    <KRow
                      id="440117"
                      visibility={this.state.isComp440117Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 48,
                          paddingBottom: 24,
                          alignItems: "center",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="368949"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="159404"
                          value={ReactSystemFunctions.translate(this.ml, 159404, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <Label
                          id="440146"
                          value={ReactSystemFunctions.translate(this.ml, 440146, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(208, 2, 27, 1)",
                              letterSpacing: "0.5px",
                              fontStyle: "italic"
                            } as any
                          }
                        ></Label>
                      </KCol>
                    </KRow>

                    <KRow
                      id="579023"
                      visibility={this.state.isComp579023Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 48,
                          paddingBottom: 24,
                          alignItems: "center",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="669830"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="900480"
                          value={ReactSystemFunctions.translate(this.ml, 900480, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 700,
                              fontSize: "12px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>
                      </KCol>
                    </KRow>

                    <KRow
                      id="710158"
                      visibility={this.state.isComp710158Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 48,
                          paddingBottom: 24,
                          alignItems: "center",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="304763"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="452090"
                          value={ReactSystemFunctions.translate(this.ml, 452090, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <KRow
                          id="540244"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 16,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="661393"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="448076"
                              value={ReactSystemFunctions.translate(this.ml, 448076, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </KCol>
                        </KRow>

                        <KRow
                          id="801131"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 16,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="575994"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="310511"
                              value={ReactSystemFunctions.translate(this.ml, 310511, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="24082"
                              value={ReactSystemFunctions.translate(this.ml, 24082, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  textAlign: "-webkit-left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="596201"
                              value={ReactSystemFunctions.translate(this.ml, 596201, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  textAlign: "-webkit-left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="618741"
                              value={ReactSystemFunctions.translate(this.ml, 618741, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  textAlign: "-webkit-left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>

                    <KRow
                      id="7343"
                      visibility={this.state.isComp7343Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 48,
                          alignItems: "center",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="91912"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="905657"
                          value={ReactSystemFunctions.translate(this.ml, 905657, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <KRow
                          id="595540"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 16,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="225682"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="666768"
                              value={ReactSystemFunctions.translate(this.ml, 666768, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="512992"
                              value={ReactSystemFunctions.translate(this.ml, 512992, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  textAlign: "-webkit-left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="848477"
                              value={ReactSystemFunctions.translate(this.ml, 848477, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>

                    <KRow
                      id="919967"
                      visibility={this.state.isComp919967Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 48,
                          alignItems: "center",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="407033"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Label
                          id="808019"
                          value={ReactSystemFunctions.translate(this.ml, 808019, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Label>

                        <KRow
                          id="723342"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 16,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="987004"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="758814"
                              value={ReactSystemFunctions.translate(this.ml, 758814, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="5803"
                              value={ReactSystemFunctions.translate(this.ml, 5803, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  textAlign: "-webkit-left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="92934"
                              value={ReactSystemFunctions.translate(this.ml, 92934, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(208, 2, 27, 1)",
                                  letterSpacing: "0.5px",
                                  fontStyle: "italic"
                                } as any
                              }
                            ></Label>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>

                    <KRow
                      id="341420"
                      visibility={this.state.isComp341420Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 16,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="418915"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KRow
                          id="980206"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="225586"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundColor: "rgba(208, 2, 27, 1)",
                                paddingTop: 4,
                                paddingBottom: 4,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="757506"
                              value={ReactSystemFunctions.translate(this.ml, 757506, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(255, 255, 255, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </KCol>
                        </KRow>

                        <KRow
                          id="545677"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="909793"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingLeft: 16,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="749136"
                              value={ReactSystemFunctions.translate(this.ml, 749136, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="791502"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_772477_value">
                              <KSelectBox
                                id="772477"
                                onChange={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.BFormuTicariComponent_772477_onChange();
                                }}
                                kuikaRef={this.bformuticari_772477_value_kuikaSelectBoxRef}
                                options={this.state.sbBasvuruTuruList}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  772477,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                autoClearSearchValue={true}
                                showSearch={false}
                                widthType="fill"
                                containsNullItem={false}
                                sortBy="none"
                                datavaluefield="id"
                                datatextfield="tanim"
                                style={
                                  {
                                    height: "36px",
                                    textAlign: "-webkit-left",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></KSelectBox>
                            </Form.Item>
                          </KCol>

                          <KCol
                            id="324980"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>
                        </KRow>

                        <KRow
                          id="5519"
                          visibility={this.state.isComp5519Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingBottom: 8,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="962571"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingLeft: 16,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="979145"
                              value={ReactSystemFunctions.translate(this.ml, 979145, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="830177"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="bformuticari_56794_value">
                              <KSelectBox
                                id="56794"
                                kuikaRef={this.bformuticari_56794_value_kuikaSelectBoxRef}
                                options={this.state.sbProjeFormTuruList}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  56794,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                autoClearSearchValue={true}
                                showSearch={false}
                                widthType="fill"
                                containsNullItem={false}
                                sortBy="none"
                                datavaluefield="id"
                                datatextfield="tanim"
                                style={
                                  {
                                    height: "36px",
                                    textAlign: "-webkit-left",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></KSelectBox>
                            </Form.Item>
                          </KCol>

                          <KCol
                            id="111687"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>
                        </KRow>

                        <KRow
                          id="296532"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="865238"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingLeft: 16,
                                alignItems: "center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>

                          <KCol
                            id="686805"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>

                          <KCol
                            id="34628"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Button
                              id="32516"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.BFormuTicariComponent_32516_onClick();
                              }}
                              showCursorPointer
                              visibility={this.state.isComp32516Visible}
                              label={ReactSystemFunctions.translate(this.ml, 32516, "label", this.defaultML)}
                              size="middle"
                              loading={false}
                              ghost={false}
                              block={false}
                              htmlType="button"
                              iconPosition="right"
                              danger={false}
                              style={
                                {
                                  borderTopLeftRadius: 32,
                                  borderTopRightRadius: 32,
                                  borderBottomRightRadius: 32,
                                  borderBottomLeftRadius: 32,
                                  borderTopWidth: 1,
                                  borderRightWidth: 1,
                                  borderBottomWidth: 1,
                                  borderLeftWidth: 1,
                                  borderColor: "rgba(172, 38, 42, 1)",
                                  borderStyle: "solid",
                                  backgroundColor: "rgba(172, 38, 42, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(255, 255, 255, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Button>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>

                    <KRow
                      id="169377"
                      visibility={this.state.isComp169377Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 48,
                          paddingBottom: 48,
                          alignItems: "flex-start",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="425599"
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        xxl={8}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingRight: 16,
                            paddingLeft: 16,
                            alignItems: "flex-start",
                            textAlign: "-webkit-center",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Button
                          id="633827"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.BFormuTicariComponent_633827_onClick();
                          }}
                          showCursorPointer
                          visibility={this.state.isComp633827Visible}
                          label={ReactSystemFunctions.translate(this.ml, 633827, "label", this.defaultML)}
                          size="middle"
                          loading={false}
                          ghost={false}
                          block={false}
                          htmlType="button"
                          iconPosition="left"
                          danger={false}
                          style={
                            {
                              borderTopLeftRadius: 32,
                              borderTopRightRadius: 32,
                              borderBottomRightRadius: 32,
                              borderBottomLeftRadius: 32,
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(172, 38, 42, 1)",
                              borderStyle: "solid",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              alignItems: "flex-start",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(172, 38, 42, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Button>
                      </KCol>

                      <KCol
                        id="867814"
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        xxl={8}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingRight: 16,
                            paddingLeft: 16,
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></KCol>

                      <KCol
                        id="61858"
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        xxl={8}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingRight: 16,
                            paddingLeft: 16,
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Button
                          id="714077"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.BFormuTicariComponent_714077_onClick();
                          }}
                          showCursorPointer
                          visibility={this.state.isComp714077Visible}
                          label={ReactSystemFunctions.translate(this.ml, 714077, "label", this.defaultML)}
                          size="middle"
                          loading={false}
                          ghost={false}
                          block={false}
                          htmlType="button"
                          iconPosition="right"
                          danger={false}
                          style={
                            {
                              borderTopLeftRadius: 32,
                              borderTopRightRadius: 32,
                              borderBottomRightRadius: 32,
                              borderBottomLeftRadius: 32,
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(172, 38, 42, 1)",
                              borderStyle: "solid",
                              backgroundColor: "rgba(172, 38, 42, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(255, 255, 255, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Button>

                        <Button
                          id="616689"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.BFormuTicariComponent_616689_onClick();
                          }}
                          showCursorPointer
                          visibility={this.state.isComp616689Visible}
                          label={ReactSystemFunctions.translate(this.ml, 616689, "label", this.defaultML)}
                          size="middle"
                          loading={false}
                          ghost={false}
                          block={false}
                          htmlType="button"
                          iconPosition="right"
                          danger={false}
                          style={
                            {
                              borderTopLeftRadius: 32,
                              borderTopRightRadius: 32,
                              borderBottomRightRadius: 32,
                              borderBottomLeftRadius: 32,
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(172, 38, 42, 1)",
                              borderStyle: "solid",
                              backgroundColor: "rgba(172, 38, 42, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingBottom: 8,
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(255, 255, 255, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Button>
                      </KCol>
                    </KRow>

                    <KRow
                      id="196228"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 16,
                          paddingBottom: 16,
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="708439"
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        xxl={8}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingRight: 16,
                            paddingLeft: 16,
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></KCol>

                      <KCol
                        id="286586"
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        xxl={8}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingRight: 16,
                            paddingLeft: 16,
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></KCol>

                      <KCol
                        id="387811"
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        xxl={8}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingRight: 16,
                            paddingLeft: 16,
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></KCol>
                    </KRow>
                  </KPanel>
                </KCol>
              </KRow>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that)),
  showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) =>
    dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
  hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
});
const tmp = withGoogleLogin(
  withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(BFormuTicari_Screen)))))
);
export { tmp as BFormuTicari_Screen };
//export default tmp;
//export { tmp as BFormuTicari_Screen };
