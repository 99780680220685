import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import moment from "moment";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IBFormuTicari_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IBFormuTicari_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  getBasvuruTuruKodById: any[];
  getBasvuruTuruKodById_dummy: any[];
  getProjeTuruFormKodById: any[];
  getProjeTuruFormKodById_dummy: any[];
  getFormNo: any[];
  getFormNo_dummy: any[];
  getUserInfo: any[];
  getUserInfo_dummy: any[];
  sbFirmaTuruList: any[];
  sbFirmaTuruList_dummy: any[];
  sbProjeTuruList: any[];
  sbProjeTuruList_dummy: any[];
  sbMecraList: any[];
  sbMecraList_dummy: any[];
  sbIzinTuruList: any[];
  sbIzinTuruList_dummy: any[];
  sbMunhasirlikList: any[];
  sbMunhasirlikList_dummy: any[];
  getBasvuruFormuById: any[];
  getBasvuruFormuById_dummy: any[];
  getListKullanimByBasvuruFId: any[];
  getListKullanimByBasvuruFId_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  getDokumansListByBFId: any[];
  getDokumansListByBFId_dummy: any[];
  sbBasvuruTuruList: any[];
  sbBasvuruTuruList_dummy: any[];
  sbProjeFormTuruList: any[];
  sbProjeFormTuruList_dummy: any[];
  rwDigeriseVisible01: any[];
  rwDigeriseVisible01_dummy: any[];
  GoBack: any;
  GoBack_dummy: any;
  delKullanimSureleriById: number;
  delKullanimSureleriById_dummy: number;
  UploadFile: any;
  UploadFile_dummy: any;
  setDokumansSave: number;
  setDokumansSave_dummy: number;
  DownloadFile: any;
  DownloadFile_dummy: any;
  setDokumansDelete: number;
  setDokumansDelete_dummy: number;
  setDigerTuruDegistir: number;
  setDigerTuruDegistir_dummy: number;
  spBasvuruFormuInsert_Ticari_F1: any[];
  spBasvuruFormuInsert_Ticari_F1_dummy: any[];
  spBasvuruFormuInsert_Ticari_F2: any[];
  spBasvuruFormuInsert_Ticari_F2_dummy: any[];
  spBasvuruFormuInsert_Ticari_F3: any[];
  spBasvuruFormuInsert_Ticari_F3_dummy: any[];
  spBasvuruFormuInsert_Ticari_F4: any[];
  spBasvuruFormuInsert_Ticari_F4_dummy: any[];
  spBasvuruFormuInsert_Ticari_F5: any[];
  spBasvuruFormuInsert_Ticari_F5_dummy: any[];
  spBasvuruFormuInsert_Ticari_F6: any[];
  spBasvuruFormuInsert_Ticari_F6_dummy: any[];
  spBasvuruFormuInsert_Ticari_F7: any[];
  spBasvuruFormuInsert_Ticari_F7_dummy: any[];
  spBasvuruFormuInsert_Ticari_F8: any[];
  spBasvuruFormuInsert_Ticari_F8_dummy: any[];
  spBasvuruFormuInsert_Ticari_F9: any[];
  spBasvuruFormuInsert_Ticari_F9_dummy: any[];
  spBasvuruFormuInsert_Ticari_F10: any[];
  spBasvuruFormuInsert_Ticari_F10_dummy: any[];
  spUpdateServisUcret: any[];
  spUpdateServisUcret_dummy: any[];
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  isComp437424Visible: "visible" | "hidden";
  isComp312802Visible: "visible" | "hidden";
  isComp128084Visible: "visible" | "hidden";
  isComp556886Visible: "visible" | "hidden";
  isComp561077Visible: "visible" | "hidden";
  isComp280419Visible: "visible" | "hidden";
  isComp173740Visible: "visible" | "hidden";
  isComp341420Visible: "visible" | "hidden";
  isComp714077Visible: "visible" | "hidden";
  isComp616689Visible: "visible" | "hidden";
  isComp32516Visible: "visible" | "hidden";
  isComp633827Visible: "visible" | "hidden";
  isComp119700Visible: "visible" | "hidden";
  isComp304820Visible: "visible" | "hidden";
  isComp541034Visible: "visible" | "hidden";
  isComp5519Visible: "visible" | "hidden";
  isComp236080Visible: "visible" | "hidden";
  isComp861186Visible: "visible" | "hidden";
  isComp468627Visible: "visible" | "hidden";
  isComp10355Visible: "visible" | "hidden";
  isComp139406Visible: "visible" | "hidden";
  isComp967257Visible: "visible" | "hidden";
  isComp938143Visible: "visible" | "hidden";
  isComp274058Visible: "visible" | "hidden";
  isComp256251Visible: "visible" | "hidden";
  isComp28903Visible: "visible" | "hidden";
  isComp918779Visible: "visible" | "hidden";
  isComp115665Visible: "visible" | "hidden";
  isComp3330Visible: "visible" | "hidden";
  isComp695955Visible: "visible" | "hidden";
  isComp25348Visible: "visible" | "hidden";
  isComp487276Visible: "visible" | "hidden";
  isComp981156Visible: "visible" | "hidden";
  isComp169331Visible: "visible" | "hidden";
  isComp998670Visible: "visible" | "hidden";
  isComp406152Visible: "visible" | "hidden";
  isComp712408Visible: "visible" | "hidden";
  isComp57055Visible: "visible" | "hidden";
  isComp951915Visible: "visible" | "hidden";
  isComp220908Visible: "visible" | "hidden";
  isComp846395Visible: "visible" | "hidden";
  isComp723855Visible: "visible" | "hidden";
  isComp306128Visible: "visible" | "hidden";
  isComp755011Visible: "visible" | "hidden";
  isComp543508Visible: "visible" | "hidden";
  isComp852087Visible: "visible" | "hidden";
  isComp466555Visible: "visible" | "hidden";
  isComp947730Visible: "visible" | "hidden";
  isComp744907Visible: "visible" | "hidden";
  isComp434573Visible: "visible" | "hidden";
  isComp845643Visible: "visible" | "hidden";
  isComp535471Visible: "visible" | "hidden";
  isComp160306Visible: "visible" | "hidden";
  isComp332643Visible: "visible" | "hidden";
  isComp564896Visible: "visible" | "hidden";
  isComp383924Visible: "visible" | "hidden";
  isComp720067Visible: "visible" | "hidden";
  isComp315445Visible: "visible" | "hidden";
  isComp393979Visible: "visible" | "hidden";
  isComp998433Visible: "visible" | "hidden";
  isComp783152Visible: "visible" | "hidden";
  isComp481428Visible: "visible" | "hidden";
  isComp165470Visible: "visible" | "hidden";
  isComp572237Visible: "visible" | "hidden";
  isComp149325Visible: "visible" | "hidden";
  isComp202654Visible: "visible" | "hidden";
  isComp307737Visible: "visible" | "hidden";
  isComp810747Visible: "visible" | "hidden";
  isComp201385Visible: "visible" | "hidden";
  isComp269167Visible: "visible" | "hidden";
  isComp977493Visible: "visible" | "hidden";
  isComp903184Visible: "visible" | "hidden";
  isComp67119Visible: "visible" | "hidden";
  isComp169770Visible: "visible" | "hidden";
  isComp810627Visible: "visible" | "hidden";
  isComp176741Visible: "visible" | "hidden";
  isComp227437Visible: "visible" | "hidden";
  isComp123993Visible: "visible" | "hidden";
  isComp307784Visible: "visible" | "hidden";
  isComp439359Visible: "visible" | "hidden";
  isComp241255Visible: "visible" | "hidden";
  isComp262195Visible: "visible" | "hidden";
  isComp550139Visible: "visible" | "hidden";
  isComp884757Visible: "visible" | "hidden";
  isComp49749Visible: "visible" | "hidden";
  isComp581358Visible: "visible" | "hidden";
  isComp874517Visible: "visible" | "hidden";
  isComp440117Visible: "visible" | "hidden";
  isComp579023Visible: "visible" | "hidden";
  isComp710158Visible: "visible" | "hidden";
  isComp7343Visible: "visible" | "hidden";
  isComp919967Visible: "visible" | "hidden";
  isComp169377Visible: "visible" | "hidden";
}

export class BFormuTicari_ScreenBase extends React.PureComponent<IBFormuTicari_ScreenProps, any> {
  bformuticari_907796_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_96573_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_655326_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_59667_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_499516_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_42241_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_151733_value_kuikaMultiSelectRef: React.RefObject<any>;
  bformuticari_314315_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_180071_value_kuikaDateRef: React.RefObject<any>;
  bformuticari_554864_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_193622_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_139423_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_175576_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_563208_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_398647_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_49563_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_763581_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_161165_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_599586_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_636418_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_952938_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_925097_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_776748_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_423768_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_508876_value_kuikaDateRef: React.RefObject<any>;
  bformuticari_958009_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_114157_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_945615_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_38491_value_kuikaDateRef: React.RefObject<any>;
  bformuticari_475588_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_841151_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_653975_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_830230_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_926329_value_kuikaDatePickerRef: React.RefObject<any>;
  bformuticari_577170_value_kuikaTableRef: React.RefObject<any>;
  bformuticari_614193_value_kuikaTableRef: React.RefObject<any>;
  bformuticari_772477_value_kuikaSelectBoxRef: React.RefObject<any>;
  bformuticari_56794_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "6afdbbbe-9501-4ad9-8984-3dc7cee06ea0",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 861186, PropertyName: "value", Value: "BELGESEL İÇİN MÜZİK KULLANIM LİSANSI BAŞVURU FORMU" },
        { Id: 468627, PropertyName: "value", Value: "MUSIC LICENCE REQUEST FORM FOR A DOCUMENTARY" },
        { Id: 10355, PropertyName: "value", Value: "DİZİ İÇİN MÜZİK KULLANIM LİSANSI BAŞVURU FORMU" },
        { Id: 139406, PropertyName: "value", Value: "MUSIC LICENCE REQUEST FORM FOR A SERIES" },
        { Id: 967257, PropertyName: "value", Value: "DİZİ (GENEL) İÇİN MÜZİK KULLANIM LİSANSI BAŞVURU FORMU" },
        { Id: 938143, PropertyName: "value", Value: "MUSIC LICENCE REQUEST FORM FOR A SERIES" },
        { Id: 274058, PropertyName: "value", Value: "FİLM İÇİN MÜZİK KULLANIM LİSANSI BAŞVURU FORMU" },
        { Id: 256251, PropertyName: "value", Value: "MUSIC LICENCE REQUEST FORM FOR A FILM" },
        { Id: 28903, PropertyName: "value", Value: "KONSER VE ETKİNLİKLER  İÇİN MÜZİK KULLANIM LİSANSI BAŞVURU FORMU" },
        { Id: 918779, PropertyName: "value", Value: "MUSIC LICENCE REQUEST FORM FOR CONCERTS & EVENTS" },
        { Id: 115665, PropertyName: "value", Value: "REKLAM İÇİN MÜZİK KULLANIM LİSANSI BAŞVURU FORMU" },
        { Id: 3330, PropertyName: "value", Value: "MUSIC LICENCE REQUEST FORM FOR A COMMERCIAL" },
        { Id: 695955, PropertyName: "value", Value: "TİYATRO İÇİN MÜZİK KULLANIM LİSANSI BAŞVURU FORMU" },
        { Id: 25348, PropertyName: "value", Value: "MUSIC LICENCE REQUEST FORM FOR A THEATRE PRODUCTION" },
        { Id: 487276, PropertyName: "value", Value: "PROGRAM İÇİN MÜZİK KULLANIM LİSANSI BAŞVURU FORMU" },
        { Id: 981156, PropertyName: "value", Value: "MUSIC LICENCE REQUEST FORM FOR A PROGRAMME" },
        { Id: 169331, PropertyName: "value", Value: "Diğer Projeler için Başvuru Formu" },
        { Id: 998670, PropertyName: "value", Value: "MUSIC LICENCE REQUEST FORM FOR OTHER PROJECTS" },
        { Id: 406152, PropertyName: "value", Value: "MÜZİK YAPIM (CD/PLAK/KASET/DIJITAL) BAŞVURU FORMU" },
        { Id: 712408, PropertyName: "value", Value: "MUSIC LICENCE REQUEST FORM FOR A MUSICAL RELEASE" },
        { Id: 346979, PropertyName: "value", Value: "Başvuruyu Yapan Kişi" },
        { Id: 802042, PropertyName: "value", Value: "Name Of Person Making The Request" },
        { Id: 750474, PropertyName: "placeholder", Value: "..." },
        { Id: 213845, PropertyName: "value", Value: "İletişim Bilgileri (E-mail) *" },
        { Id: 401310, PropertyName: "value", Value: "Contact E-mail" },
        { Id: 192848, PropertyName: "placeholder", Value: "..." },
        { Id: 186660, PropertyName: "value", Value: "Telefon Numarası" },
        { Id: 45251, PropertyName: "value", Value: "Telephone Number" },
        { Id: 390390, PropertyName: "placeholder", Value: "..." },
        { Id: 543730, PropertyName: "value", Value: "Başvuruyu Yapan Firma" },
        { Id: 643048, PropertyName: "value", Value: "Company Making The Request" },
        { Id: 389012, PropertyName: "placeholder", Value: "..." },
        { Id: 556749, PropertyName: "value", Value: "Firma Türü" },
        { Id: 376687, PropertyName: "value", Value: "Type Of Company" },
        { Id: 907796, PropertyName: "placeholder", Value: "..." },
        { Id: 176074, PropertyName: "value", Value: "İş Yapılacak Firma" },
        { Id: 654168, PropertyName: "placeholder", Value: "..." },
        { Id: 669324, PropertyName: "value", Value: "Kullanılacak Eserin Adı" },
        { Id: 424359, PropertyName: "value", Value: "Name Of The Requested Work" },
        { Id: 405451, PropertyName: "placeholder", Value: "..." },
        { Id: 295487, PropertyName: "value", Value: "Eser Sahibi Adı" },
        { Id: 166941, PropertyName: "value", Value: "Name Of The Right Owner" },
        { Id: 788421, PropertyName: "placeholder", Value: "..." },
        { Id: 89366, PropertyName: "value", Value: "Belgesel 1" },
        { Id: 739201, PropertyName: "value", Value: "Proje (Belgesel) Adı" },
        { Id: 181465, PropertyName: "value", Value: "Name Of The Documentary" },
        { Id: 757838, PropertyName: "placeholder", Value: "..." },
        { Id: 143432, PropertyName: "value", Value: "Toplam Bütçesi" },
        { Id: 464344, PropertyName: "value", Value: "Total Budget" },
        { Id: 613968, PropertyName: "placeholder", Value: "..." },
        { Id: 933944, PropertyName: "value", Value: "Prodüktör" },
        { Id: 135971, PropertyName: "value", Value: "Producer" },
        { Id: 819000, PropertyName: "placeholder", Value: "..." },
        { Id: 387556, PropertyName: "value", Value: "Yönetmen" },
        { Id: 179965, PropertyName: "value", Value: "Director" },
        { Id: 441327, PropertyName: "placeholder", Value: "..." },
        { Id: 528904, PropertyName: "value", Value: "Belgeselin Konusu" },
        { Id: 617592, PropertyName: "value", Value: "Subject Of The Documentary" },
        { Id: 164797, PropertyName: "placeholder", Value: "..." },
        { Id: 986753, PropertyName: "value", Value: "Eserin Kullanılacağı Sahnenin Tarifi" },
        { Id: 713473, PropertyName: "value", Value: "Describe The Scene In Which The Work Would Appear" },
        { Id: 709057, PropertyName: "placeholder", Value: "..." },
        { Id: 126444, PropertyName: "value", Value: "Yanlızca Türkiye Haklarımı İsteniyor?" },
        { Id: 322148, PropertyName: "value", Value: "Is The Territory Request For Turkey Only?" },
        { Id: 96573, PropertyName: "placeholder", Value: "..." },
        { Id: 56894, PropertyName: "value", Value: "T.C. dışı Gösterim Var İse Bölge/Ülkeleri Belirtiniz" },
        { Id: 259234, PropertyName: "value", Value: "If No, Please State All Other Territories" },
        { Id: 904040, PropertyName: "placeholder", Value: "..." },
        { Id: 675625, PropertyName: "value", Value: "Yurtdışında Herhangi Bir Festivalde Gösterilecek mi?" },
        { Id: 720911, PropertyName: "value", Value: "Will It Be Shown At Any Festivals?" },
        { Id: 655326, PropertyName: "placeholder", Value: "..." },
        { Id: 363998, PropertyName: "placeholder", Value: "..." },
        { Id: 958777, PropertyName: "value", Value: "Ne Kadar Süreyle Lisans İsteniyor?" },
        { Id: 810513, PropertyName: "value", Value: "Requested Term Of Licence" },
        { Id: 59667, PropertyName: "placeholder", Value: "..." },
        { Id: 846743, PropertyName: "placeholder", Value: "..." },
        { Id: 728545, PropertyName: "value", Value: "Önerilen Telif Bedeli" },
        { Id: 159456, PropertyName: "value", Value: "..." },
        { Id: 301253, PropertyName: "placeholder", Value: "..." },
        { Id: 760794, PropertyName: "value", Value: "Eserin Hangi Kayıttan Kullanılacağı Bilgisini Paylaşın" },
        { Id: 947230, PropertyName: "value", Value: "Which Recording Of The Work Will Be Used" },
        { Id: 499516, PropertyName: "placeholder", Value: "..." },
        { Id: 200574, PropertyName: "value", Value: "Kullanılacak Kaydın Linkini Ekleyin" },
        { Id: 385899, PropertyName: "value", Value: "Please Add A Link To The Recording In Question" },
        { Id: 806100, PropertyName: "placeholder", Value: "..." },
        { Id: 334745, PropertyName: "value", Value: "CD Kaset LP 2" },
        { Id: 457053, PropertyName: "value", Value: "Yapım (CD/PLAK/KASET/DIJITAL) Adı" },
        { Id: 56240, PropertyName: "value", Value: "Name Of The Release" },
        { Id: 727135, PropertyName: "placeholder", Value: "..." },
        { Id: 953111, PropertyName: "value", Value: "Yapımda Kullanılan Diğer Eserler ve Eser Sahipleri" },
        { Id: 646489, PropertyName: "value", Value: "..." },
        { Id: 419406, PropertyName: "placeholder", Value: "..." },
        { Id: 742073, PropertyName: "value", Value: "Yapımcı Firma" },
        { Id: 625218, PropertyName: "value", Value: "Recording Company" },
        { Id: 464905, PropertyName: "placeholder", Value: "..." },
        { Id: 33877, PropertyName: "value", Value: "Sanatçı / Grup Adı" },
        { Id: 647385, PropertyName: "value", Value: "Name Of The Artist / Group" },
        { Id: 919241, PropertyName: "placeholder", Value: "..." },
        { Id: 802533, PropertyName: "value", Value: "Sanatçı/Grup Sosyal Medya Hesaplarını Ekleyin;" },
        { Id: 297204, PropertyName: "value", Value: "Please Add The Socials Of The Artist;" },
        { Id: 16118, PropertyName: "value", Value: "Facebook" },
        { Id: 74883, PropertyName: "placeholder", Value: "facebook.com/" },
        { Id: 627888, PropertyName: "value", Value: "Instagram" },
        { Id: 136717, PropertyName: "placeholder", Value: "instagram.com/" },
        { Id: 158771, PropertyName: "value", Value: "TikTok" },
        { Id: 212231, PropertyName: "placeholder", Value: "@..." },
        { Id: 187627, PropertyName: "value", Value: "Twitter" },
        { Id: 628115, PropertyName: "placeholder", Value: "twitter.com/" },
        { Id: 151977, PropertyName: "value", Value: "YouTube" },
        { Id: 774689, PropertyName: "placeholder", Value: "youtube.com/channel/" },
        { Id: 378733, PropertyName: "value", Value: "Diğer" },
        { Id: 960125, PropertyName: "value", Value: "/ Other" },
        { Id: 176725, PropertyName: "placeholder", Value: "..." },
        { Id: 554583, PropertyName: "value", Value: "Yayınlanma Türü" },
        { Id: 93222, PropertyName: "value", Value: "Type Of Release" },
        { Id: 42241, PropertyName: "placeholder", Value: "..." },
        { Id: 921473, PropertyName: "value", Value: "Format" },
        { Id: 440226, PropertyName: "value", Value: "Release Format" },
        { Id: 151733, PropertyName: "placeholder", Value: "..." },
        { Id: 74421, PropertyName: "value", Value: "Aradığınız Format Menü’de Yok İse Detay Verin Lütfen" },
        { Id: 860210, PropertyName: "value", Value: "If The Your Format Is Missing Please Enter Below" },
        { Id: 643220, PropertyName: "placeholder", Value: "..." },
        { Id: 993383, PropertyName: "value", Value: "Planlanan Yayın Tarihi" },
        { Id: 15418, PropertyName: "value", Value: "Release Date" },
        { Id: 174173, PropertyName: "placeholder", Value: "..." },
        { Id: 399941, PropertyName: "value", Value: "Yayım Bölgesi" },
        { Id: 62671, PropertyName: "value", Value: "Territory Of Release" },
        { Id: 314315, PropertyName: "placeholder", Value: "..." },
        {
          Id: 317331,
          PropertyName: "value",
          Value: "Yapımın Üzerinde Bir Ürün / Marka Adı Olacak mı? Evet ise detayını rica ederiz"
        },
        {
          Id: 378854,
          PropertyName: "value",
          Value: "Will There Be Product Placement And/Or Sponsorship On The Release? If Yes, Please Give Detail"
        },
        { Id: 363699, PropertyName: "placeholder", Value: "..." },
        {
          Id: 53752,
          PropertyName: "value",
          Value:
            "Eserde orjinalinden farklı yapılan bir düzenlenme, sampling, melodi veya yeni söz yazımı var mı? Varsa detayını rica ederiz"
        },
        {
          Id: 227995,
          PropertyName: "value",
          Value:
            "If The Original Work Has Been Adapted, Sampled, Melody Changed And/Or New Lyrics Written Please Give Further Info"
        },
        { Id: 631318, PropertyName: "placeholder", Value: "..." },
        { Id: 227848, PropertyName: "value", Value: "Dizi 3" },
        { Id: 386576, PropertyName: "value", Value: "Proje (Dizinin) Adı" },
        { Id: 425832, PropertyName: "value", Value: "Name Of The Series" },
        { Id: 731780, PropertyName: "placeholder", Value: "..." },
        { Id: 417927, PropertyName: "value", Value: "Dizinin Yayınlandığı TV Kanalı ve/veya Platform" },
        { Id: 418119, PropertyName: "value", Value: "Name Of Channel Or Platform It’s Broadcast" },
        { Id: 530996, PropertyName: "placeholder", Value: "..." },
        { Id: 148877, PropertyName: "value", Value: "Prodüktör" },
        { Id: 554040, PropertyName: "value", Value: "Producer" },
        { Id: 35217, PropertyName: "placeholder", Value: "..." },
        { Id: 725698, PropertyName: "value", Value: "Yönetmen" },
        { Id: 445016, PropertyName: "value", Value: "Director" },
        { Id: 215099, PropertyName: "placeholder", Value: "..." },
        { Id: 203778, PropertyName: "value", Value: "Başrol Oyuncuları" },
        { Id: 722907, PropertyName: "value", Value: "Main Cast" },
        { Id: 503111, PropertyName: "placeholder", Value: "..." },
        { Id: 319972, PropertyName: "value", Value: "Dizinin Konusu" },
        { Id: 797730, PropertyName: "value", Value: "Subject Of The Series" },
        { Id: 800510, PropertyName: "placeholder", Value: "..." },
        { Id: 151120, PropertyName: "value", Value: "Sezon" },
        { Id: 15206, PropertyName: "value", Value: "Season" },
        { Id: 125636, PropertyName: "placeholder", Value: "..." },
        { Id: 880384, PropertyName: "value", Value: "Bölüm Numarası" },
        { Id: 48258, PropertyName: "value", Value: "Episode Number" },
        { Id: 949599, PropertyName: "placeholder", Value: "..." },
        { Id: 64119, PropertyName: "value", Value: "Yayın Tarihi" },
        { Id: 877174, PropertyName: "value", Value: "Release Date" },
        { Id: 180071, PropertyName: "placeholder", Value: "..." },
        { Id: 123612, PropertyName: "value", Value: "Eserin Kullanılacağı Sahnenin Tarifi" },
        { Id: 783474, PropertyName: "value", Value: "Describe The Scene In Which The Work Would Appear" },
        { Id: 496974, PropertyName: "placeholder", Value: "..." },
        { Id: 435457, PropertyName: "value", Value: "Eserin Kullanılacağı Yaklaşık Süresi" },
        { Id: 734824, PropertyName: "value", Value: "Approximate Duration Of Usage" },
        { Id: 408183, PropertyName: "placeholder", Value: "..." },
        { Id: 962391, PropertyName: "value", Value: "Yanlızca Türkiye Haklarımı İsteniyor?" },
        { Id: 506579, PropertyName: "value", Value: "Is The Territory Request For Turkey Only?" },
        { Id: 554864, PropertyName: "placeholder", Value: "..." },
        { Id: 761157, PropertyName: "value", Value: "T.C. dışı Gösterim Var İse Bölge/Ülkeleri Belirtiniz" },
        { Id: 8328, PropertyName: "value", Value: "If No, Please State All Other Territories" },
        { Id: 711138, PropertyName: "placeholder", Value: "..." },
        { Id: 357522, PropertyName: "value", Value: "Ne Kadar Süreyle Lisans İsteniyor?" },
        { Id: 869275, PropertyName: "value", Value: "Requested Term Of Licence" },
        { Id: 193622, PropertyName: "placeholder", Value: "..." },
        { Id: 296139, PropertyName: "placeholder", Value: "Diğer/Other..." },
        { Id: 988356, PropertyName: "value", Value: "Önerilen Telif Bedeli" },
        { Id: 918859, PropertyName: "value", Value: "..." },
        { Id: 54329, PropertyName: "placeholder", Value: "..." },
        { Id: 845989, PropertyName: "value", Value: "Eserin Hangi Kayıttan Kullanılacağı Bilgisini Paylaşın" },
        { Id: 192527, PropertyName: "value", Value: "Which Recording Of The Work Will Be Used" },
        { Id: 139423, PropertyName: "placeholder", Value: "..." },
        { Id: 782166, PropertyName: "value", Value: "Kullanılacak Kaydın Linkini Ekleyin" },
        { Id: 128287, PropertyName: "value", Value: "Please Add A Link To The Recording In Question" },
        { Id: 315845, PropertyName: "placeholder", Value: "..." },
        { Id: 345052, PropertyName: "value", Value: "Dizi Genel 4" },
        { Id: 476457, PropertyName: "value", Value: "Proje (Dizinin) Adı" },
        { Id: 291649, PropertyName: "value", Value: "Name Of The Series" },
        { Id: 953799, PropertyName: "placeholder", Value: "Proje Adı Giriniz..." },
        { Id: 324637, PropertyName: "value", Value: "Dizinin Yayınlandığı TV Kanalı" },
        { Id: 298608, PropertyName: "value", Value: "Name Of Channel Or Platform It’s Broadcast" },
        { Id: 795343, PropertyName: "placeholder", Value: "Toplam Bütçe Giriniz..." },
        { Id: 327229, PropertyName: "value", Value: "Prodüktör" },
        { Id: 757771, PropertyName: "value", Value: "Producer" },
        { Id: 894235, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 973067, PropertyName: "value", Value: "Yönetmen" },
        { Id: 546087, PropertyName: "value", Value: "Director" },
        { Id: 752355, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 223091, PropertyName: "value", Value: "Başrol Oyuncuları" },
        { Id: 392461, PropertyName: "value", Value: "Main Cast" },
        { Id: 37671, PropertyName: "placeholder", Value: "Toplam Bütçe Giriniz..." },
        { Id: 341326, PropertyName: "value", Value: "Kaç Bölüm İçin İsteniyor" },
        { Id: 887514, PropertyName: "value", Value: "..." },
        { Id: 183550, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 284585, PropertyName: "value", Value: "Sinopsis" },
        { Id: 780979, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 500664, PropertyName: "value", Value: "Eser Nasıl Kullanılacak" },
        { Id: 729249, PropertyName: "value", Value: "Which Recording Of The Work Will Be Used" },
        { Id: 175576, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 81696, PropertyName: "value", Value: "Yanlızca Türkiye Haklarımı İsteniyor?" },
        { Id: 932763, PropertyName: "value", Value: "Is The Territory Request For Turkey Only?" },
        { Id: 563208, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 512575, PropertyName: "value", Value: "T.C. dışı Gösterim Var İse Bölge/Ülkeleri Belirtiniz" },
        { Id: 325672, PropertyName: "value", Value: "If No, Please State All Other Territories" },
        { Id: 861385, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 883904, PropertyName: "value", Value: "Ne Kadar Süreyle Lisans İsteniyor ?" },
        { Id: 942289, PropertyName: "value", Value: "Which Recording Of The Work Will Be Used" },
        { Id: 482186, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 556956, PropertyName: "value", Value: "Eserin Nasıl Kullanılacağı" },
        { Id: 311922, PropertyName: "value", Value: "Which Recording Of The Work Will Be Used" },
        { Id: 398647, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 912114, PropertyName: "value", Value: "Sinema 5" },
        { Id: 896912, PropertyName: "value", Value: "Proje (Filmin) Adı" },
        { Id: 334888, PropertyName: "value", Value: "Name Of The Film" },
        { Id: 433250, PropertyName: "placeholder", Value: "..." },
        { Id: 841530, PropertyName: "value", Value: "Toplam Bütçesi" },
        { Id: 5627, PropertyName: "value", Value: "Total Budget" },
        { Id: 401545, PropertyName: "placeholder", Value: "..." },
        { Id: 348454, PropertyName: "value", Value: "Prodüktör" },
        { Id: 415291, PropertyName: "value", Value: "Producer" },
        { Id: 548831, PropertyName: "placeholder", Value: "..." },
        { Id: 487282, PropertyName: "value", Value: "Yönetmen" },
        { Id: 514296, PropertyName: "value", Value: "Director" },
        { Id: 241884, PropertyName: "placeholder", Value: "..." },
        { Id: 503556, PropertyName: "value", Value: "Başrol Oyuncuları" },
        { Id: 168384, PropertyName: "value", Value: "Main Cast" },
        { Id: 154279, PropertyName: "placeholder", Value: "..." },
        { Id: 904626, PropertyName: "value", Value: "Sinopsis" },
        { Id: 306823, PropertyName: "placeholder", Value: "..." },
        { Id: 504443, PropertyName: "value", Value: "Eserin Kullanılacağı Sahnenin Tarifi" },
        { Id: 616327, PropertyName: "value", Value: "Describe The Scene In Which The Work Would Appear" },
        { Id: 821567, PropertyName: "placeholder", Value: "..." },
        { Id: 199034, PropertyName: "value", Value: "Eserin Kullanılacağı Yaklaşık Süresi" },
        { Id: 56838, PropertyName: "value", Value: "Approximate Duration Of Usage Of The Work" },
        { Id: 135744, PropertyName: "placeholder", Value: "..." },
        { Id: 585806, PropertyName: "value", Value: "Eser; Film Tanıtım ve Fragmanlarında Geçecek mi?" },
        { Id: 284844, PropertyName: "value", Value: "Will The Work Be Featured In Teasers And/Or Trailers?" },
        { Id: 109874, PropertyName: "placeholder", Value: "..." },
        { Id: 896508, PropertyName: "value", Value: "Filmin öncelikli olarak yayınlanacağı mecra?" },
        { Id: 525934, PropertyName: "value", Value: "Where Will The Film Premiere?" },
        { Id: 43394, PropertyName: "placeholder", Value: "..." },
        { Id: 533678, PropertyName: "value", Value: "Yanlızca Türkiye Haklarımı İsteniyor?" },
        { Id: 960863, PropertyName: "value", Value: "Is The Territory Request For Turkey Only?" },
        { Id: 49563, PropertyName: "placeholder", Value: "..." },
        { Id: 366291, PropertyName: "value", Value: "T.C. dışı Gösterim Var İse Bölge/Ülkeleri Belirtiniz" },
        { Id: 746191, PropertyName: "value", Value: "In No, Please State Other Territories And Countries" },
        { Id: 437312, PropertyName: "placeholder", Value: "..." },
        { Id: 465402, PropertyName: "value", Value: "Yurtdışında Herhangi Bir Festivalde Gösterilecek mi?" },
        { Id: 303894, PropertyName: "value", Value: "Will It Be Shown At Any Festivals?" },
        { Id: 763581, PropertyName: "placeholder", Value: "..." },
        { Id: 478463, PropertyName: "placeholder", Value: "..." },
        { Id: 618413, PropertyName: "value", Value: "Ne Kadar Süreyle Lisans İsteniyor?" },
        { Id: 660459, PropertyName: "value", Value: "Requested Term Of Licence" },
        { Id: 161165, PropertyName: "placeholder", Value: "..." },
        { Id: 597208, PropertyName: "placeholder", Value: "Diğer/Other..." },
        { Id: 717525, PropertyName: "value", Value: "Önerilen Telif Bedeli" },
        { Id: 981943, PropertyName: "value", Value: "..." },
        { Id: 108219, PropertyName: "placeholder", Value: "..." },
        { Id: 533641, PropertyName: "value", Value: "Eserin Hangi Kayıttan Kullanılacağı Bilgisini Paylaşın" },
        { Id: 598093, PropertyName: "value", Value: "Which Recording Of The Work Will Be Used" },
        { Id: 599586, PropertyName: "placeholder", Value: "..." },
        { Id: 61789, PropertyName: "value", Value: "Kullanılacak Kaydın Linkini Ekleyin" },
        { Id: 129258, PropertyName: "value", Value: "Please Add A Link To The Recording In Question" },
        { Id: 179006, PropertyName: "placeholder", Value: "..." },
        { Id: 516000, PropertyName: "value", Value: "Konser 6" },
        { Id: 571477, PropertyName: "value", Value: "Proje (Konser/Etkinlik) Adı" },
        { Id: 346100, PropertyName: "value", Value: "Name Of The Project (Concert/Event)" },
        { Id: 779822, PropertyName: "placeholder", Value: "..." },
        { Id: 504417, PropertyName: "value", Value: "Sahne Alacak Sanatçı/Sanatçılar" },
        { Id: 39030, PropertyName: "value", Value: "Names of the Act/Acts" },
        { Id: 139034, PropertyName: "placeholder", Value: "..." },
        { Id: 103880, PropertyName: "value", Value: "Yapımcı" },
        { Id: 91568, PropertyName: "value", Value: "Producer" },
        { Id: 213030, PropertyName: "placeholder", Value: "..." },
        { Id: 852214, PropertyName: "value", Value: "Sponsor Var mı? / Kimler?" },
        { Id: 910891, PropertyName: "value", Value: "Is There A Sponser?" },
        { Id: 895754, PropertyName: "placeholder", Value: "..." },
        {
          Id: 817656,
          PropertyName: "value",
          Value: "Organizasyon Yeri ve Tarihi (Çoklu Bir Gösterim İse Her Bir Etkinlik Günü ve Tarihini Yazınız)"
        },
        {
          Id: 907140,
          PropertyName: "value",
          Value: "Event Location And Date (For Multiple Shows, State the Date & Location Of Each)"
        },
        { Id: 569899, PropertyName: "placeholder", Value: "..." },
        { Id: 72135, PropertyName: "value", Value: "Etkinlik Kişi Kapasitesi" },
        { Id: 221165, PropertyName: "value", Value: "Capacity Of The Event" },
        { Id: 179729, PropertyName: "placeholder", Value: "..." },
        { Id: 497710, PropertyName: "value", Value: "Bilet Fiyatları" },
        { Id: 306774, PropertyName: "value", Value: "Ticket Prices" },
        { Id: 936236, PropertyName: "placeholder", Value: "..." },
        {
          Id: 882188,
          PropertyName: "value",
          Value:
            "Konser Tanıtım ve Afişi Hakkında Bilgi (İzin Alınan Eser Yada Sanatçıya Tanıtım ve Afişte Yer Verilecek mi?)"
        },
        {
          Id: 988857,
          PropertyName: "value",
          Value: "Info About Marketing And Poster (Will The Work or Artist/Composer Be Featured?)"
        },
        { Id: 46982, PropertyName: "placeholder", Value: "..." },
        {
          Id: 37454,
          PropertyName: "value",
          Value: "Konser Profesyonel Kayıt Altına Alınacak mı? (Ses Ya da Görüntülü Olarak)"
        },
        {
          Id: 684377,
          PropertyName: "value",
          Value: "Will The Concert Be Professionally Recorded (Sound and/or Video)"
        },
        { Id: 925632, PropertyName: "placeholder", Value: "..." },
        {
          Id: 725703,
          PropertyName: "value",
          Value:
            "Konser Görüntüleri ya da  Tamamı Daha Sonra Herhangi bir mecra da Yayınlanacak mı? (Lütfen Detay Giriniz - Hangi Kanal, Sosyal Medya vb.)"
        },
        {
          Id: 679747,
          PropertyName: "value",
          Value:
            "Will The Event In Part Or In Full Be Broadcast Via Any Media? (Please Give Details i.e: Channel, Social Media etc)"
        },
        { Id: 753776, PropertyName: "placeholder", Value: "..." },
        { Id: 917119, PropertyName: "value", Value: "Kayıtlar CD/DVD Gibi Bir Yapım İçin de Kullanılacak mı?" },
        { Id: 175915, PropertyName: "value", Value: "Will The Recordings/Footage Be Made Into A CD/DVD?" },
        { Id: 118061, PropertyName: "placeholder", Value: "..." },
        { Id: 576150, PropertyName: "value", Value: "Önerilen Telif Bedeli" },
        { Id: 861564, PropertyName: "value", Value: "Önerilen Telif Bedeli" },
        { Id: 447965, PropertyName: "placeholder", Value: "..." },
        { Id: 445544, PropertyName: "value", Value: "Eserin Hangi Kayıttan Kullanılacağı Bilgisini Paylaşın" },
        { Id: 473701, PropertyName: "value", Value: "Which Recording Of The Work Will Be Used" },
        { Id: 636418, PropertyName: "placeholder", Value: "..." },
        { Id: 136186, PropertyName: "value", Value: "Kullanılacak Kaydın Linkini Ekleyin" },
        { Id: 403672, PropertyName: "value", Value: "Please Add A Link To The Recording In Question" },
        { Id: 606315, PropertyName: "placeholder", Value: "..." },
        {
          Id: 347217,
          PropertyName: "value",
          Value: "Hologram Benzeri Bir Kullanım, Sunum Ya da Görsel Bir Malzeme Kullanımı Var mı?"
        },
        { Id: 600466, PropertyName: "value", Value: "Will There Be Hologram Use / Presentation or Images Used?" },
        { Id: 243523, PropertyName: "placeholder", Value: "..." },
        {
          Id: 193478,
          PropertyName: "value",
          Value: "Konserden Bir Gelir Elde Ediliyor mu? (Direk Ya da Sponsor Destek Yolu İle Dolaylı)"
        },
        { Id: 76537, PropertyName: "value", Value: "Will Revenue Be Generated (Direct Or Through Sponsership)" },
        { Id: 252338, PropertyName: "placeholder", Value: "..." },
        {
          Id: 107141,
          PropertyName: "value",
          Value: "Elde Edilen Gelirin Paylaşanları Kimdir? Bağış vs. Söz Konusu İse Lütfen Belirtiniz."
        },
        {
          Id: 732757,
          PropertyName: "value",
          Value: "If Yes, Who Will Be Keeping/Sharing The Revenue? If Donating Please State."
        },
        { Id: 63361, PropertyName: "placeholder", Value: "..." },
        { Id: 594473, PropertyName: "value", Value: "Reklam 7" },
        { Id: 922693, PropertyName: "value", Value: "Proje (Reklam) Adı" },
        { Id: 361357, PropertyName: "value", Value: "Name Of The Project (Commercial)" },
        { Id: 160425, PropertyName: "placeholder", Value: "..." },
        { Id: 625517, PropertyName: "value", Value: "Ürün" },
        { Id: 910071, PropertyName: "value", Value: "Product" },
        { Id: 634083, PropertyName: "placeholder", Value: "..." },
        { Id: 231733, PropertyName: "value", Value: "Parça" },
        { Id: 579871, PropertyName: "value", Value: "..." },
        { Id: 375623, PropertyName: "placeholder", Value: "..." },
        { Id: 93632, PropertyName: "value", Value: "Besteci ve Söz Yazarı" },
        { Id: 972922, PropertyName: "value", Value: "..." },
        { Id: 84279, PropertyName: "placeholder", Value: "..." },
        { Id: 331858, PropertyName: "value", Value: "Talep Edilen İzin" },
        { Id: 711631, PropertyName: "value", Value: "..." },
        { Id: 952938, PropertyName: "placeholder", Value: "..." },
        { Id: 663220, PropertyName: "value", Value: "Mecra" },
        { Id: 524095, PropertyName: "value", Value: "Media" },
        { Id: 330499, PropertyName: "placeholder", Value: "..." },
        { Id: 890990, PropertyName: "value", Value: "Spot / Timing" },
        { Id: 828213, PropertyName: "value", Value: "..." },
        { Id: 575302, PropertyName: "placeholder", Value: "..." },
        { Id: 314967, PropertyName: "value", Value: "Film Sayısı" },
        { Id: 943966, PropertyName: "value", Value: "Number Of Main Commercial Films" },
        { Id: 275256, PropertyName: "placeholder", Value: "..." },
        { Id: 378150, PropertyName: "value", Value: "Versiyon Sayısı" },
        { Id: 659334, PropertyName: "value", Value: "Versiyon Sayısı" },
        { Id: 33436, PropertyName: "placeholder", Value: "..." },
        { Id: 615338, PropertyName: "value", Value: "Gösterim Yapılacak Bölge/Ülkeleri Belirtiniz" },
        { Id: 587303, PropertyName: "value", Value: "State The Territories/Countries It Will Be Broadcast" },
        { Id: 601579, PropertyName: "placeholder", Value: "..." },
        { Id: 186097, PropertyName: "value", Value: "Ne Kadar Süreyle Lisans İsteniyor?" },
        { Id: 496812, PropertyName: "value", Value: "Not: Reklam başvurularında süresiz izin verilmemektedir" },
        { Id: 415839, PropertyName: "value", Value: "Requested Term Of Licence" },
        {
          Id: 115178,
          PropertyName: "value",
          Value: "Please Note: Perpetuity licenses are not granted for commercials"
        },
        { Id: 925097, PropertyName: "placeholder", Value: "..." },
        { Id: 713576, PropertyName: "placeholder", Value: "Diğer/Other..." },
        {
          Id: 938367,
          PropertyName: "value",
          Value: "Sosyal Medya veya Websitesinden Arşiv Amaçlı İzin İsteniliyor Mu?"
        },
        { Id: 703006, PropertyName: "value", Value: "Not: Bu talepler verilen lisans bütçesini etkilemektedir" },
        {
          Id: 304379,
          PropertyName: "value",
          Value: "Is There A Request To Archive The Ad On Social Media And/Or A Website?"
        },
        { Id: 236248, PropertyName: "value", Value: "Please Note: These requests affect the license fee" },
        { Id: 776748, PropertyName: "placeholder", Value: "..." },
        { Id: 687776, PropertyName: "value", Value: "Ne Kadar Süreyle İsteniyor ?" },
        { Id: 17666, PropertyName: "value", Value: "Requested Term Of Licence" },
        { Id: 883613, PropertyName: "placeholder", Value: "..." },
        { Id: 488501, PropertyName: "value", Value: "Senaryo, Hikaye özeti" },
        { Id: 723115, PropertyName: "value", Value: "Script /Storyboard/Scenario" },
        { Id: 69287, PropertyName: "placeholder", Value: "..." },
        { Id: 572426, PropertyName: "value", Value: "Eserin Hangi Kayıttan Kullanılacağı Bilgisini Paylaşın" },
        { Id: 662780, PropertyName: "value", Value: "Which Recording Of The Work Will Be Used" },
        { Id: 423768, PropertyName: "placeholder", Value: "..." },
        { Id: 473920, PropertyName: "value", Value: "Kullanılacak Kaydın Linkini Ekleyin" },
        { Id: 773399, PropertyName: "value", Value: "Please Add A Link To The Recording In Question" },
        { Id: 680656, PropertyName: "placeholder", Value: "..." },
        {
          Id: 298287,
          PropertyName: "value",
          Value: "Söz Değişimi Varsa; Sözleri Yazınız veya Aşağıdaki  Belgeler Yükleme Bölümünden Yükleyin"
        },
        {
          Id: 490903,
          PropertyName: "value",
          Value:
            "If There Are Lyric Changes; Please Write Them In The Box Or Upload Them Via The File Upload Area Below."
        },
        { Id: 826306, PropertyName: "placeholder", Value: "..." },
        { Id: 264901, PropertyName: "value", Value: "Yayın Tarihi" },
        { Id: 645397, PropertyName: "value", Value: "Release Date" },
        { Id: 508876, PropertyName: "placeholder", Value: "..." },
        { Id: 524042, PropertyName: "value", Value: "Münhasırlık İsteniliyor mu?" },
        { Id: 70753, PropertyName: "value", Value: "Is Exclusivity Requested?" },
        { Id: 958009, PropertyName: "placeholder", Value: "..." },
        { Id: 429605, PropertyName: "value", Value: "Tiyatro Oyunu 8" },
        { Id: 156908, PropertyName: "value", Value: "Proje (Oyunun) Adı" },
        { Id: 653901, PropertyName: "value", Value: "Name Of The Theatre Production" },
        { Id: 41842, PropertyName: "placeholder", Value: "..." },
        { Id: 550195, PropertyName: "value", Value: "Toplam Bütçesi" },
        { Id: 646392, PropertyName: "value", Value: "Total Budget" },
        { Id: 769963, PropertyName: "placeholder", Value: "..." },
        { Id: 828086, PropertyName: "value", Value: "Prodüktör" },
        { Id: 147974, PropertyName: "value", Value: "Producer" },
        { Id: 745722, PropertyName: "placeholder", Value: "..." },
        { Id: 228415, PropertyName: "value", Value: "Yönetmen" },
        { Id: 180982, PropertyName: "value", Value: "Director" },
        { Id: 678596, PropertyName: "placeholder", Value: "..." },
        { Id: 528445, PropertyName: "value", Value: "Başrol Oyuncuları" },
        { Id: 592537, PropertyName: "value", Value: "Main Cast" },
        { Id: 13402, PropertyName: "placeholder", Value: "..." },
        { Id: 743001, PropertyName: "value", Value: "Sinopsis" },
        { Id: 298537, PropertyName: "value", Value: "Synopsis" },
        { Id: 98625, PropertyName: "placeholder", Value: "..." },
        { Id: 294851, PropertyName: "value", Value: "Eserin Kullanılacağı Sahnenin Tarifi" },
        { Id: 171599, PropertyName: "value", Value: "Describe The Scene In Which The Work Would Appear" },
        { Id: 977010, PropertyName: "placeholder", Value: "..." },
        { Id: 961458, PropertyName: "value", Value: "Nerede Sahneleniyor" },
        { Id: 91412, PropertyName: "value", Value: "Which Theatre Will It Be Shown At" },
        { Id: 939868, PropertyName: "placeholder", Value: "..." },
        { Id: 446724, PropertyName: "value", Value: "Koltuk Kapasitesi" },
        { Id: 23593, PropertyName: "value", Value: "Seat Capacity" },
        { Id: 605461, PropertyName: "placeholder", Value: "..." },
        { Id: 212652, PropertyName: "value", Value: "Oynanma Sıklığı" },
        { Id: 94689, PropertyName: "value", Value: "Performance Schedule" },
        { Id: 348650, PropertyName: "placeholder", Value: "..." },
        { Id: 306213, PropertyName: "value", Value: "Bilet Fiyatları" },
        { Id: 52685, PropertyName: "value", Value: "Ticket Prices" },
        { Id: 785985, PropertyName: "placeholder", Value: "..." },
        { Id: 865241, PropertyName: "value", Value: "Ne Kadar Süreyle Lisans İsteniyor?" },
        { Id: 691289, PropertyName: "value", Value: "Requested Term Of Licence" },
        { Id: 114157, PropertyName: "placeholder", Value: "..." },
        { Id: 961538, PropertyName: "placeholder", Value: "Diğer/Other..." },
        { Id: 127567, PropertyName: "value", Value: "Oyunun Sponsoru Var mı?" },
        { Id: 938530, PropertyName: "value", Value: "Does The Production Have A Sponsor?" },
        { Id: 531317, PropertyName: "placeholder", Value: "..." },
        { Id: 335364, PropertyName: "value", Value: "Önerilen Telif Bedeli" },
        { Id: 126531, PropertyName: "value", Value: "..." },
        { Id: 595383, PropertyName: "placeholder", Value: "..." },
        { Id: 249799, PropertyName: "value", Value: "Eserin Hangi Kayıttan Kullanılacağı Bilgisini Paylaşın" },
        { Id: 505005, PropertyName: "value", Value: "Which Recording Of The Work Will Be Used" },
        { Id: 945615, PropertyName: "placeholder", Value: "..." },
        { Id: 822386, PropertyName: "value", Value: "Kullanılacak Kaydın Linkini Ekleyin" },
        { Id: 668749, PropertyName: "value", Value: "Please Add A Link To The Recording In Question" },
        { Id: 997705, PropertyName: "placeholder", Value: "..." },
        { Id: 485494, PropertyName: "value", Value: "Eser; Tiyatronun Tanıtım ve Fragmanlarında Geçecek mi?" },
        { Id: 7316, PropertyName: "value", Value: "Will The Work Be Featured In Teasers And/Or Trailers?" },
        { Id: 834746, PropertyName: "placeholder", Value: "..." },
        { Id: 307262, PropertyName: "value", Value: "TV Program 9" },
        { Id: 888270, PropertyName: "value", Value: "Proje (Program) Adı" },
        { Id: 671637, PropertyName: "value", Value: "Name Of The Programme" },
        { Id: 970226, PropertyName: "placeholder", Value: "..." },
        { Id: 12307, PropertyName: "value", Value: "Programın Yayınlandığı TV Kanalı ve/veya Platform" },
        { Id: 392486, PropertyName: "value", Value: "Name Of Channel Or Platform It’s Broadcast" },
        { Id: 284840, PropertyName: "placeholder", Value: "..." },
        { Id: 679905, PropertyName: "value", Value: "Prodüktör" },
        { Id: 109213, PropertyName: "value", Value: "Producer" },
        { Id: 584964, PropertyName: "placeholder", Value: "..." },
        { Id: 359392, PropertyName: "value", Value: "Yönetmen" },
        { Id: 611955, PropertyName: "value", Value: "Director" },
        { Id: 151522, PropertyName: "placeholder", Value: "..." },
        { Id: 782842, PropertyName: "value", Value: "Programın İçeriği" },
        { Id: 928045, PropertyName: "value", Value: "Subject Of The Programme" },
        { Id: 818678, PropertyName: "placeholder", Value: "..." },
        { Id: 390909, PropertyName: "value", Value: "Sezon" },
        { Id: 209584, PropertyName: "value", Value: "Season" },
        { Id: 300311, PropertyName: "placeholder", Value: "..." },
        { Id: 439235, PropertyName: "value", Value: "Bölüm Numarası" },
        { Id: 40130, PropertyName: "value", Value: "Episode Number" },
        { Id: 596953, PropertyName: "placeholder", Value: "..." },
        { Id: 435909, PropertyName: "value", Value: "Yayın Tarihi" },
        { Id: 517660, PropertyName: "value", Value: "Release Date" },
        { Id: 38491, PropertyName: "placeholder", Value: "..." },
        { Id: 346715, PropertyName: "value", Value: "Programın Yayınlanma Sıklığı" },
        { Id: 206320, PropertyName: "value", Value: "The Programmes Broadcast Schedule" },
        { Id: 701444, PropertyName: "placeholder", Value: "..." },
        { Id: 975995, PropertyName: "value", Value: "Yanlızca Türkiye Haklarımı İsteniyor?" },
        { Id: 403387, PropertyName: "value", Value: "Is The Territory Request For Turkey Only?" },
        { Id: 475588, PropertyName: "placeholder", Value: "..." },
        { Id: 725834, PropertyName: "value", Value: "T.C. dışı Gösterim Var İse Bölge/Ülkeleri Belirtiniz" },
        { Id: 146790, PropertyName: "value", Value: "If No, Please State All Other Territories" },
        { Id: 382382, PropertyName: "placeholder", Value: "..." },
        { Id: 921985, PropertyName: "value", Value: "Ne Kadar Süreyle Lisans İsteniyor?" },
        { Id: 897299, PropertyName: "value", Value: "Requested Term Of Licence" },
        { Id: 841151, PropertyName: "placeholder", Value: "..." },
        { Id: 691237, PropertyName: "placeholder", Value: "Diğer/Other..." },
        { Id: 735192, PropertyName: "value", Value: "Önerilen Telif Bedeli" },
        { Id: 137547, PropertyName: "value", Value: "..." },
        { Id: 232600, PropertyName: "placeholder", Value: "..." },
        { Id: 206723, PropertyName: "value", Value: "Eserin Hangi Kayıttan Kullanılacağı Bilgisini Paylaşın" },
        { Id: 595972, PropertyName: "value", Value: "Which Recording Of The Work Will Be Used" },
        { Id: 653975, PropertyName: "placeholder", Value: "..." },
        { Id: 614126, PropertyName: "value", Value: "Kullanılacak Kaydın Linkini Ekleyin" },
        { Id: 40989, PropertyName: "value", Value: "Please Add A Link To The Recording In Question" },
        { Id: 607210, PropertyName: "placeholder", Value: "..." },
        { Id: 281356, PropertyName: "value", Value: "Diğer 10" },
        { Id: 562480, PropertyName: "value", Value: "Proje Adı" },
        { Id: 61977, PropertyName: "value", Value: "Name Of Your Project" },
        { Id: 709919, PropertyName: "placeholder", Value: "..." },
        { Id: 242048, PropertyName: "value", Value: "Projenin Türü" },
        { Id: 638593, PropertyName: "value", Value: "Type Of Your Project" },
        { Id: 830230, PropertyName: "placeholder", Value: "..." },
        { Id: 490197, PropertyName: "value", Value: "Projenizi Anlatın" },
        { Id: 763387, PropertyName: "value", Value: "Tell Us About Your Project" },
        { Id: 169770, PropertyName: "placeholder", Value: "..." },
        { Id: 211926, PropertyName: "placeholder", Value: "..." },
        { Id: 185089, PropertyName: "value", Value: "Teslimat / Deadline Bilgisi" },
        { Id: 267584, PropertyName: "value", Value: "Deadline" },
        { Id: 926329, PropertyName: "placeholder", Value: "..." },
        { Id: 966662, PropertyName: "value", Value: "Acil ise ayrıca tıklayın" },
        { Id: 20503, PropertyName: "value", Value: "Please mark if urgent" },
        { Id: 701822, PropertyName: "value", Value: "Sizin için Müzik Seçimi mi İstiyorusnuz?" },
        { Id: 312461, PropertyName: "value", Value: "..." },
        { Id: 417431, PropertyName: "children", Value: "Evet" },
        { Id: 879397, PropertyName: "value", Value: "/ Yes" },
        { Id: 283848, PropertyName: "value", Value: "Proje/Versiyon Bilgi" },
        { Id: 304820, PropertyName: "value", Value: "Kullanılacak Eserin Adı / Sahibi" },
        { Id: 541034, PropertyName: "value", Value: "Kullanılacak Eser Seçimi İçin Açıklama Giriniz..!" },
        { Id: 122781, PropertyName: "value", Value: "Saat" },
        { Id: 344261, PropertyName: "value", Value: "Dakika" },
        { Id: 444884, PropertyName: "value", Value: "Saniye" },
        { Id: 141657, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 570080, PropertyName: "placeholder", Value: "Giriniz..." },
        { Id: 770932, PropertyName: "placeholder", Value: "0" },
        { Id: 707397, PropertyName: "placeholder", Value: "0" },
        { Id: 968501, PropertyName: "placeholder", Value: "0" },
        { Id: 166525, PropertyName: "value", Value: "Proje/Versiyon Bilgi" },
        { Id: 415808, PropertyName: "value", Value: "Kullanılacak Eser Adı / Eser Sahibi" },
        { Id: 569809, PropertyName: "value", Value: "Saat" },
        { Id: 237488, PropertyName: "value", Value: "Dakika" },
        { Id: 851322, PropertyName: "value", Value: "Saniye" },
        { Id: 577170, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 211540, PropertyName: "title", Value: "Bilgi" },
        { Id: 284897, PropertyName: "value", Value: "[datafield:bilgi]" },
        { Id: 315736, PropertyName: "value", Value: "[datafield:muzikkod]" },
        { Id: 871564, PropertyName: "value", Value: "[datafield:suresaat]" },
        { Id: 922552, PropertyName: "value", Value: "[datafield:suredakika]" },
        { Id: 962051, PropertyName: "value", Value: "[datafield:suresaniye]" },
        { Id: 489554, PropertyName: "value", Value: "Ek Bilgi / Soru" },
        { Id: 280014, PropertyName: "value", Value: "Additional Info / Questions" },
        { Id: 409878, PropertyName: "placeholder", Value: "..." },
        { Id: 338718, PropertyName: "value", Value: "İlgili Belgeler" },
        { Id: 605839, PropertyName: "value", Value: "Related Documents" },
        { Id: 241255, PropertyName: "placeholder", Value: "..." },
        { Id: 262195, PropertyName: "label", Value: "Upload" },
        { Id: 614193, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 837076, PropertyName: "title", Value: "Title" },
        { Id: 12374, PropertyName: "value", Value: "[datafield:dosyatanim]" },
        {
          Id: 14922,
          PropertyName: "value",
          Value:
            "Önemli Notlar : Eğer Internet Yayını, DVD, VCD gibi haklar da istenecek ise lütfen ayrıntılı olarak belirtin. Bu tür hakların kullanıma ek maliyetler getirdiğini hatırlatmak isteriz."
        },
        {
          Id: 247678,
          PropertyName: "value",
          Value:
            "Important Note: If you also want the rights to Online Screenings and/or DVD, VCD’s please state the usage of these clearly. We would like to remind you that these rights are subject to additional usage fees."
        },
        { Id: 537354, PropertyName: "value", Value: "Not: ..." },
        { Id: 2796, PropertyName: "value", Value: "..." },
        {
          Id: 684888,
          PropertyName: "value",
          Value:
            "Önemli Notlar: Orijinal kayıttan eser kullanımlarında verilen eser sahibi bütçelerinin yapım hakları bütçelerinden az olması durumda, telif bedelleri başvuru detaylarından bağımsız olarak yapım hakları telif bedellerine çıkacaktır."
        },
        {
          Id: 985532,
          PropertyName: "value",
          Value:
            "Eğer Internet Yayını, DVD, VCD gibi haklar da istenecek ise lütfen ayrıntılı olarak belirtiniz. Bu tür hakların kullanıma ek maliyetler getirdiğini hatırlatmak isteriz."
        },
        {
          Id: 884483,
          PropertyName: "value",
          Value:
            "Important Note: In the event, the fee given for the Master Rights surpasses the fee for the publishing, the publishing fees will automatically be raised to match the fee of the Master Rights."
        },
        {
          Id: 59341,
          PropertyName: "value",
          Value:
            "If you also want the rights to Online Screenings and/or DVD, VCD’s please state the usage of these clearly. We would like to remind you that these rights are subject to additional usage fees."
        },
        {
          Id: 222266,
          PropertyName: "value",
          Value:
            "Önemli Notlar : Orijinal kayıttan eser kullanımlarında verilen eser sahibi bütçelerinin yapım hakları bütçelerinden az olması durumda, telif bedelleri başvuru detaylarından bağımsız olarak yapım hakları telif bedellerine çıkacaktır."
        },
        {
          Id: 797212,
          PropertyName: "value",
          Value:
            "Eğer Internet Yayını, DVD, VCD gibi haklar da istenecek ise lütfen ayrıntılı olarak belirtiniz. Bu tür hakların kullanıma ek maliyetler getirdiğini hatırlatmak isteriz."
        },
        {
          Id: 514522,
          PropertyName: "value",
          Value:
            "Important Note: In the event, the fee given for the master rights surpasses the fee for the publishing, the publishing fees will automatically be raised to match the fee of the master rights."
        },
        {
          Id: 959885,
          PropertyName: "value",
          Value:
            "If you also want the rights to Online Screenings and/or DVD, VCD’s please state the usage of these clearly. We would like to remind you that these rights are subject to additional usage fees."
        },
        {
          Id: 159404,
          PropertyName: "value",
          Value:
            "Önemli Notlar: Eğer Internet Yayını, DVD, VCD gibi haklar da istenecek ise lütfen ayrıntılı olarak belirtin. Bu tür hakların kullanıma ek maliyetler getirdiğini hatırlatmak isteriz."
        },
        {
          Id: 440146,
          PropertyName: "value",
          Value:
            "Important Note: If You Also Want The Rights To Online Screenings And/Or Dvd, Vcd’s Please State The Usage Of These Clearly. We Would Like To Remind You That These Rights Are Subject To Additional Usage Fees."
        },
        { Id: 900480, PropertyName: "value", Value: "Not:-" },
        {
          Id: 452090,
          PropertyName: "value",
          Value:
            "Önemli Notlar : Bu başvuruda belirtilen eserin, reklam ve/ veya kampanyada tek başına kullanımı için oluşturulmuştur. Aynı reklam ya da kampanya kapsamında,ynı zamanda ve / veya daha sonra,başka eser başka bir eser / eserler kullanılacak ise lütfen bilgi veriniz. Aksi durumda verilen fiyat ve onaylar geçersiz olacaktır."
        },
        {
          Id: 448076,
          PropertyName: "value",
          Value:
            "Bütçelemeler verilen detaylara göre yapılır. Başvuru şartlarında yer almayan detaylar ve değişiklikler,her türlü ek süre, mecra vb. talebi ek onaya tabidir."
        },
        {
          Id: 310511,
          PropertyName: "value",
          Value:
            "Orjinal kayıttan eser kullanımlarında verilen eser sahibi bütçelerinin yapım hakları bütçelerinden az olması durumunda, telif bedelleri başvuru detaylarından bağımsız olarak yapım hakları telif bedellerine çıkacaktır."
        },
        { Id: 24082, PropertyName: "value", Value: "..." },
        { Id: 596201, PropertyName: "value", Value: "..." },
        { Id: 618741, PropertyName: "value", Value: "..." },
        {
          Id: 905657,
          PropertyName: "value",
          Value:
            "Önemli Notlar : Orijinal kayıttan eser kullanımlarında verilen eser sahibi bütçelerinin yapım hakları bütçelerinden az olması durumda, telif bedelleri başvuru detaylarından bağımsız olarak yapım hakları telif bedellerine çıkacaktır."
        },
        {
          Id: 666768,
          PropertyName: "value",
          Value:
            "Eğer Internet Yayını, DVD, VCD gibi haklar da istenecek ise lütfen ayrıntılı olarak belirtiniz. Bu tür hakların kullanıma ek maliyetler getirdiğini hatırlatmak isteriz."
        },
        {
          Id: 512992,
          PropertyName: "value",
          Value:
            "Important Note: In the event, the fee given for the master rights surpasses the fee for the publishing, the publishing fees will automatically be raised to match the fee of the master rights."
        },
        {
          Id: 848477,
          PropertyName: "value",
          Value:
            "If you also want the rights to Online Screenings and/or DVD, VCD’s please state the usage of these clearly. We would like to remind you that these rights are subject to additional usage fees."
        },
        {
          Id: 808019,
          PropertyName: "value",
          Value:
            "Önemli Notlar : Orijinal kayıttan eser kullanımlarında verilen eser sahibi bütçelerinin yapım hakları bütçelerinden az olması durumda, telif bedelleri başvuru detaylarından bağımsız olarak yapım hakları telif bedellerine çıkacaktır."
        },
        {
          Id: 758814,
          PropertyName: "value",
          Value:
            "Eğer Internet Yayını, DVD, VCD gibi haklar da istenecek ise lütfen ayrıntılı olarak belirtiniz. Bu tür hakların kullanıma ek maliyetler getirdiğini hatırlatmak isteriz."
        },
        {
          Id: 5803,
          PropertyName: "value",
          Value:
            "Important Note: In the event, the fee given for the master rights surpasses the fee for the publishing, the publishing fees will automatically be raised to match the fee of the master rights."
        },
        {
          Id: 92934,
          PropertyName: "value",
          Value:
            "If you also want the rights to Online Screenings and/or DVD, VCD’s please state the usage of these clearly. We would like to remind you that these rights are subject to additional usage fees."
        },
        { Id: 757506, PropertyName: "value", Value: "Diğer Formu  Türünü Değiştir" },
        { Id: 749136, PropertyName: "value", Value: "Başvuru Türü" },
        { Id: 772477, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 979145, PropertyName: "value", Value: "Proje Türü" },
        { Id: 56794, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 32516, PropertyName: "label", Value: "Degiştir" },
        { Id: 633827, PropertyName: "label", Value: "Vazgeç/Cancel" },
        { Id: 714077, PropertyName: "label", Value: "Başvuru Yap / Send" },
        { Id: 616689, PropertyName: "label", Value: "Güncelle" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.bformuticari_750474_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_192848_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_390390_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_389012_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_907796_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_654168_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_405451_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_788421_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_757838_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_613968_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_819000_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_441327_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_164797_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_96573_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_904040_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_655326_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_363998_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_59667_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_846743_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_301253_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_499516_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_806100_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_727135_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_419406_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_464905_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_919241_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_74883_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_136717_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_212231_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_628115_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_774689_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_176725_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_42241_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_151733_value_kuikaMultiSelectRef = React.createRef();
    this.bformuticari_643220_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_174173_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_314315_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_363699_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_631318_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_731780_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_530996_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_35217_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_215099_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_503111_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_125636_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_949599_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_180071_value_kuikaDateRef = React.createRef();
    this.bformuticari_408183_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_554864_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_711138_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_193622_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_296139_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_54329_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_139423_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_315845_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_953799_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_795343_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_894235_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_752355_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_37671_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_183550_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_780979_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_175576_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_563208_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_861385_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_482186_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_398647_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_433250_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_401545_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_548831_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_241884_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_154279_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_306823_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_135744_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_109874_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_43394_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_49563_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_437312_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_763581_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_478463_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_161165_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_597208_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_108219_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_599586_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_179006_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_779822_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_139034_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_213030_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_895754_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_569899_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_179729_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_936236_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_46982_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_925632_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_753776_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_118061_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_447965_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_636418_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_606315_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_243523_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_252338_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_63361_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_160425_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_634083_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_375623_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_84279_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_952938_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_575302_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_275256_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_33436_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_601579_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_925097_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_713576_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_776748_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_883613_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_69287_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_423768_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_680656_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_508876_value_kuikaDateRef = React.createRef();
    this.bformuticari_958009_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_41842_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_769963_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_745722_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_678596_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_13402_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_98625_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_939868_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_605461_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_348650_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_785985_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_114157_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_961538_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_531317_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_595383_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_945615_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_997705_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_834746_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_970226_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_284840_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_584964_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_151522_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_818678_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_300311_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_596953_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_38491_value_kuikaDateRef = React.createRef();
    this.bformuticari_701444_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_475588_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_382382_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_841151_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_691237_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_232600_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_653975_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_607210_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_709919_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_830230_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_169770_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_926329_value_kuikaDatePickerRef = React.createRef();
    this.bformuticari_141657_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_570080_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_577170_value_kuikaTableRef = React.createRef();
    this.bformuticari_241255_value_kuikaTextInputRef = React.createRef();
    this.bformuticari_614193_value_kuikaTableRef = React.createRef();
    this.bformuticari_772477_value_kuikaSelectBoxRef = React.createRef();
    this.bformuticari_56794_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      getBasvuruTuruKodById: [],
      getProjeTuruFormKodById: [],
      getFormNo: [],
      getUserInfo: [],
      sbFirmaTuruList: [],
      sbProjeTuruList: [],
      sbMecraList: [],
      sbIzinTuruList: [],
      sbMunhasirlikList: [],
      getBasvuruFormuById: [],
      getListKullanimByBasvuruFId: [],
      SetValueOf: "",
      ChangeVisibilityOf: "",
      getDokumansListByBFId: [],
      sbBasvuruTuruList: [],
      sbProjeFormTuruList: [],
      rwDigeriseVisible01: [],
      GoBack: "",
      delKullanimSureleriById: 0,
      UploadFile: "",
      setDokumansSave: 0,
      DownloadFile: "",
      setDokumansDelete: "",
      setDigerTuruDegistir: 0,
      spBasvuruFormuInsert_Ticari_F1: [],
      spBasvuruFormuInsert_Ticari_F2: [],
      spBasvuruFormuInsert_Ticari_F3: [],
      spBasvuruFormuInsert_Ticari_F4: [],
      spBasvuruFormuInsert_Ticari_F5: [],
      spBasvuruFormuInsert_Ticari_F6: [],
      spBasvuruFormuInsert_Ticari_F7: [],
      spBasvuruFormuInsert_Ticari_F8: [],
      spBasvuruFormuInsert_Ticari_F9: [],
      spBasvuruFormuInsert_Ticari_F10: [],
      spUpdateServisUcret: [],
      NAVIGATE: "",
      dataSource_96573: [
        { key: "1", text: "Evet / Yes" },
        { key: "0", text: "Hayır / No" }
      ],
      dataSource_655326: [
        { key: "1", text: "Evet / Yes" },
        { key: "0", text: "Hayır / No" }
      ],
      dataSource_59667: [
        { key: "1", text: "1 Ay / Month" },
        { key: "3", text: "3 Ay / Month" },
        { key: "6", text: "6 Ay / Month" },
        { key: "12", text: "1 Yıl / Year" },
        { key: "-1", text: "Süresiz / Perpetuity" },
        { key: "0", text: "Diğer / Other" }
      ],
      dataSource_499516: [
        { key: "1", text: "Orjinal Kayıttan / Original Recording" },
        { key: "2", text: "Yeniden Kayıttan / Re-Recording" },
        { key: "3", text: "Canlı Okunacak / Performed Live" }
      ],
      dataSource_42241: [
        { key: "1", text: "Albüm" },
        { key: "2", text: "Single" },
        { key: "3", text: "EP" },
        { key: "4", text: "Derleme (Compilation) Albüm" },
        { key: "5", text: "Soundtrack" }
      ],
      dataSource_151733: [
        { key: "1", text: "CD" },
        { key: "2", text: "Plak" },
        { key: "3", text: "Kaset" },
        { key: "4", text: "Dijital" }
      ],
      dataSource_314315: [
        { key: "1", text: "Türkiye / Turkey" },
        { key: "2", text: "Avrupa / Europe " },
        { key: "3", text: "Dünya / World" }
      ],
      dataSource_554864: [
        { key: "1", text: "Evet / Yes" },
        { key: "0", text: "Hayır / No" }
      ],
      dataSource_193622: [
        { key: "1", text: "1 Ay / Month" },
        { key: "3", text: "3 Ay / Month" },
        { key: "6", text: "6 Ay / Month" },
        { key: "12", text: "1 Yıl / Year" },
        { key: "-1", text: "Süresiz / Perpetuity" },
        { key: "0", text: "Diğer / Other" }
      ],
      dataSource_139423: [
        { key: "1", text: "Orjinal Kayıttan / Original Recording" },
        { key: "2", text: "Yeniden Kayıttan / Re-Recording" },
        { key: "3", text: "Canlı Okunacak / Performed Live" }
      ],
      dataSource_175576: [
        { key: "1", text: "Jenerik" },
        { key: "2", text: "Muhtelif bölüm için sahneler" },
        { key: "3", text: "Tanıtım" },
        { key: "4", text: "Dizinin Adı" },
        { key: "5", text: "Hepsi" }
      ],
      dataSource_563208: [
        { key: "1", text: "Evet" },
        { key: "0", text: "Hayır" }
      ],
      dataSource_398647: [
        { key: "1", text: "Orjinal Kayıttan" },
        { key: "2", text: "Yeniden Kayıttan" }
      ],
      dataSource_49563: [
        { key: "1", text: "Evet / Yes" },
        { key: "0", text: "Hayır / No" }
      ],
      dataSource_763581: [
        { key: "1", text: "Evet / Yes" },
        { key: "0", text: "Hayır / No" }
      ],
      dataSource_161165: [
        { key: "1", text: "1 Ay / Month" },
        { key: "3", text: "3 Ay / Month" },
        { key: "6", text: "6 Ay / Month" },
        { key: "12", text: "1 Yıl / Year" },
        { key: "-1", text: "Süresiz / Perpetuity" },
        { key: "0", text: "Diğer / Other" }
      ],
      dataSource_599586: [
        { key: "1", text: "Orjinal Kayıttan / Original Recording" },
        { key: "2", text: "Yeniden Kayıttan / Re-Recording" },
        { key: "3", text: "Canlı Okunacak / Performed Live" }
      ],
      dataSource_636418: [
        { key: "1", text: "Orjinal Kayıttan / Original Recording" },
        { key: "2", text: "Yeniden Kayıttan / Re-Recording" },
        { key: "3", text: "Canlı Okunacak / Performed Live" }
      ],
      dataSource_925097: [
        { key: "1", text: "1 Ay / Month" },
        { key: "3", text: "3 Ay / Month" },
        { key: "6", text: "6 Ay / Month" },
        { key: "12", text: "1 Yıl / Year" },
        { key: "0", text: "Diğer / Other" }
      ],
      dataSource_776748: [
        { key: "0", text: "Hayır / No" },
        { key: "1", text: "Evet / Yes" }
      ],
      dataSource_423768: [
        { key: "1", text: "Orjinal Kayıttan / Original Recording" },
        { key: "2", text: "Yeniden Kayıttan / Re-Recording" },
        { key: "3", text: "Canlı Okunacak / Performed Live" }
      ],
      dataSource_114157: [
        { key: "1", text: "1 Ay / Month" },
        { key: "3", text: "3 Ay / Month" },
        { key: "6", text: "6 Ay / Month" },
        { key: "12", text: "1 Yıl / Year" },
        { key: "-1", text: "Süresiz / Perpetuity" },
        { key: "0", text: "Diğer / Other" }
      ],
      dataSource_945615: [
        { key: "1", text: "Orjinal Kayıttan / Original Recording" },
        { key: "2", text: "Yeniden Kayıttan / Re-Recording" },
        { key: "3", text: "Canlı Okunacak / Performed Live" }
      ],
      dataSource_475588: [
        { key: "1", text: "Evet / Yes" },
        { key: "0", text: "Hayır / No" }
      ],
      dataSource_841151: [
        { key: "1", text: "1 Ay / Month" },
        { key: "3", text: "3 Ay / Month" },
        { key: "6", text: "6 Ay / Month" },
        { key: "12", text: "1 Yıl / Year" },
        { key: "-1", text: "Süresiz / Perpetuity" },
        { key: "0", text: "Diğer / Other" }
      ],
      dataSource_653975: [
        { key: "1", text: "Orjinal Kayıttan / Original Recording" },
        { key: "2", text: "Yeniden Kayıttan/ Re-Recording" },
        { key: "3", text: "Canlı Okunacak/ Performed Live" }
      ],
      isComp437424Visible: "hidden",
      isComp312802Visible: "hidden",
      isComp128084Visible: "hidden",
      isComp556886Visible: "hidden",
      isComp561077Visible: "hidden",
      isComp280419Visible: "hidden",
      isComp173740Visible: "hidden",
      isComp341420Visible: "hidden",
      isComp714077Visible: "hidden",
      isComp616689Visible: "hidden",
      isComp32516Visible: "hidden",
      isComp633827Visible: "visible",
      isComp119700Visible: "hidden",
      isComp304820Visible: "visible",
      isComp541034Visible: "hidden",
      isComp5519Visible: "hidden",
      isComp236080Visible: "hidden",
      isComp861186Visible: "hidden",
      isComp468627Visible: "hidden",
      isComp10355Visible: "hidden",
      isComp139406Visible: "hidden",
      isComp967257Visible: "hidden",
      isComp938143Visible: "hidden",
      isComp274058Visible: "hidden",
      isComp256251Visible: "hidden",
      isComp28903Visible: "hidden",
      isComp918779Visible: "hidden",
      isComp115665Visible: "hidden",
      isComp3330Visible: "hidden",
      isComp695955Visible: "hidden",
      isComp25348Visible: "hidden",
      isComp487276Visible: "hidden",
      isComp981156Visible: "hidden",
      isComp169331Visible: "hidden",
      isComp998670Visible: "hidden",
      isComp406152Visible: "hidden",
      isComp712408Visible: "hidden",
      isComp57055Visible: "hidden",
      isComp951915Visible: "hidden",
      isComp220908Visible: "hidden",
      isComp846395Visible: "hidden",
      isComp723855Visible: "hidden",
      isComp306128Visible: "hidden",
      isComp755011Visible: "hidden",
      isComp543508Visible: "hidden",
      isComp852087Visible: "hidden",
      isComp466555Visible: "hidden",
      isComp947730Visible: "hidden",
      isComp744907Visible: "hidden",
      isComp434573Visible: "hidden",
      isComp845643Visible: "hidden",
      isComp535471Visible: "hidden",
      isComp160306Visible: "hidden",
      isComp332643Visible: "hidden",
      isComp564896Visible: "hidden",
      isComp383924Visible: "hidden",
      isComp720067Visible: "hidden",
      isComp315445Visible: "hidden",
      isComp393979Visible: "hidden",
      isComp998433Visible: "hidden",
      isComp783152Visible: "hidden",
      isComp481428Visible: "hidden",
      isComp165470Visible: "hidden",
      isComp572237Visible: "hidden",
      isComp149325Visible: "hidden",
      isComp202654Visible: "hidden",
      isComp307737Visible: "hidden",
      isComp810747Visible: "hidden",
      isComp201385Visible: "hidden",
      isComp269167Visible: "hidden",
      isComp977493Visible: "hidden",
      isComp903184Visible: "hidden",
      isComp67119Visible: "hidden",
      isComp169770Visible: "hidden",
      isComp810627Visible: "hidden",
      isComp176741Visible: "hidden",
      isComp227437Visible: "hidden",
      isComp123993Visible: "hidden",
      isComp307784Visible: "hidden",
      isComp439359Visible: "hidden",
      isComp241255Visible: "hidden",
      isComp262195Visible: "hidden",
      isComp550139Visible: "hidden",
      isComp884757Visible: "hidden",
      isComp49749Visible: "hidden",
      isComp581358Visible: "hidden",
      isComp874517Visible: "hidden",
      isComp440117Visible: "hidden",
      isComp579023Visible: "hidden",
      isComp710158Visible: "hidden",
      isComp7343Visible: "hidden",
      isComp919967Visible: "hidden",
      isComp169377Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("bformuticari", "");
      return;
    }

    if (true) {
      await this.BFormuTicariPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("bformuticari", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("bformuticari", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.BFormuTicariPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({
      bformuticari_389012_value: this.state.getBasvuruFormuById?.at?.(0)?.basvuranSirketAd ?? undefined,
      bformuticari_907796_value: this.state.getBasvuruFormuById?.at?.(0)?.firmaTuruID ?? undefined,
      bformuticari_654168_value: this.state.getBasvuruFormuById?.at?.(0)?.sirketAd ?? undefined,
      bformuticari_405451_value: this.state.getBasvuruFormuById?.at?.(0)?.eserAd ?? undefined,
      bformuticari_788421_value: this.state.getBasvuruFormuById?.at?.(0)?.eserSahibi ?? undefined,
      bformuticari_757838_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
      bformuticari_613968_value: this.state.getBasvuruFormuById?.at?.(0)?.toplamButce ?? undefined,
      bformuticari_819000_value: this.state.getBasvuruFormuById?.at?.(0)?.produktor ?? undefined,
      bformuticari_441327_value: this.state.getBasvuruFormuById?.at?.(0)?.yonetmen ?? undefined,
      bformuticari_164797_value: this.state.getBasvuruFormuById?.at?.(0)?.belgeselinKonusu ?? undefined,
      bformuticari_709057_value: this.state.getBasvuruFormuById?.at?.(0)?.sahneTarifi ?? undefined,
      bformuticari_96573_value: this.state.getBasvuruFormuById?.at?.(0)?.turkiyeHakki ?? undefined,
      bformuticari_904040_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiUlke ?? undefined,
      bformuticari_655326_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiFestivalmi ?? undefined,
      bformuticari_363998_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiFestivalAciklama ?? undefined,
      bformuticari_59667_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresi ?? undefined,
      bformuticari_846743_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresiDiger ?? undefined,
      bformuticari_301253_value: this.state.getBasvuruFormuById?.at?.(0)?.telifBedeli ?? undefined,
      bformuticari_499516_value: this.state.getBasvuruFormuById?.at?.(0)?.eserKullanimSekli ?? undefined,
      bformuticari_806100_value: this.state.getBasvuruFormuById?.at?.(0)?.linkInfo ?? undefined,
      bformuticari_727135_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
      bformuticari_419406_value: this.state.getBasvuruFormuById?.at?.(0)?.yonetmen ?? undefined,
      bformuticari_464905_value: this.state.getBasvuruFormuById?.at?.(0)?.yapimci ?? undefined,
      bformuticari_919241_value: this.state.getBasvuruFormuById?.at?.(0)?.sanatcilar ?? undefined,
      bformuticari_74883_value: this.state.getBasvuruFormuById?.at?.(0)?.facebook ?? undefined,
      bformuticari_136717_value: this.state.getBasvuruFormuById?.at?.(0)?.instagram ?? undefined,
      bformuticari_212231_value: this.state.getBasvuruFormuById?.at?.(0)?.tikTok ?? undefined,
      bformuticari_628115_value: this.state.getBasvuruFormuById?.at?.(0)?.twitter ?? undefined,
      bformuticari_774689_value: this.state.getBasvuruFormuById?.at?.(0)?.youTube ?? undefined,
      bformuticari_176725_value: this.state.getBasvuruFormuById?.at?.(0)?.digerSosyalMedya ?? undefined,
      bformuticari_42241_value: this.state.getBasvuruFormuById?.at?.(0)?.kullanimSekliCD ?? undefined,
      bformuticari_151733_value: this.state.getBasvuruFormuById?.at?.(0)?.formats ?? undefined,
      bformuticari_643220_value: this.state.getBasvuruFormuById?.at?.(0)?.formatAciklama ?? undefined,
      bformuticari_174173_value: this.state.getBasvuruFormuById?.at?.(0)?.yayinTarihi ?? undefined,
      bformuticari_314315_value: this.state.getBasvuruFormuById?.at?.(0)?.yayimBolgesi ?? undefined,
      bformuticari_363699_value: this.state.getBasvuruFormuById?.at?.(0)?.markaUrunVarsa ?? undefined,
      bformuticari_631318_value: this.state.getBasvuruFormuById?.at?.(0)?.sozDegisimi ?? undefined,
      bformuticari_731780_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
      bformuticari_530996_value: this.state.getBasvuruFormuById?.at?.(0)?.tvKanal ?? undefined,
      bformuticari_35217_value: this.state.getBasvuruFormuById?.at?.(0)?.produktor ?? undefined,
      bformuticari_215099_value: this.state.getBasvuruFormuById?.at?.(0)?.yonetmen ?? undefined,
      bformuticari_503111_value: this.state.getBasvuruFormuById?.at?.(0)?.basrol ?? undefined,
      bformuticari_800510_value: this.state.getBasvuruFormuById?.at?.(0)?.diziKonu ?? undefined,
      bformuticari_125636_value: this.state.getBasvuruFormuById?.at?.(0)?.sezon ?? undefined,
      bformuticari_949599_value: this.state.getBasvuruFormuById?.at?.(0)?.diziBolumNo ?? undefined,
      bformuticari_180071_value: this.state.getBasvuruFormuById?.at?.(0)?.yayinTarihi ?? undefined,
      bformuticari_496974_value: this.state.getBasvuruFormuById?.at?.(0)?.sahneTarifi ?? undefined,
      bformuticari_408183_value: this.state.getBasvuruFormuById?.at?.(0)?.eserSure ?? undefined,
      bformuticari_554864_value: this.state.getBasvuruFormuById?.at?.(0)?.turkiyeHakki ?? undefined,
      bformuticari_711138_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiUlke ?? undefined,
      bformuticari_193622_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresi ?? undefined,
      bformuticari_296139_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresiDiger ?? undefined,
      bformuticari_54329_value: this.state.getBasvuruFormuById?.at?.(0)?.telifBedeli ?? undefined,
      bformuticari_139423_value: this.state.getBasvuruFormuById?.at?.(0)?.eserKullanimSekli ?? undefined,
      bformuticari_315845_value: this.state.getBasvuruFormuById?.at?.(0)?.linkInfo ?? undefined,
      bformuticari_953799_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
      bformuticari_795343_value: this.state.getBasvuruFormuById?.at?.(0)?.tvKanal ?? undefined,
      bformuticari_894235_value: this.state.getBasvuruFormuById?.at?.(0)?.produktor ?? undefined,
      bformuticari_752355_value: this.state.getBasvuruFormuById?.at?.(0)?.yonetmen ?? undefined,
      bformuticari_37671_value: this.state.getBasvuruFormuById?.at?.(0)?.basrol ?? undefined,
      bformuticari_183550_value: this.state.getBasvuruFormuById?.at?.(0)?.kacBolum ?? undefined,
      bformuticari_780979_value: this.state.getBasvuruFormuById?.at?.(0)?.sinopsis ?? undefined,
      bformuticari_175576_value: this.state.getBasvuruFormuById?.at?.(0)?.eserKullanimYeri ?? undefined,
      bformuticari_563208_value: this.state.getBasvuruFormuById?.at?.(0)?.turkiyeHakki ?? undefined,
      bformuticari_861385_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiUlke ?? undefined,
      bformuticari_482186_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresi ?? undefined,
      bformuticari_398647_value: this.state.getBasvuruFormuById?.at?.(0)?.eserKullanimSekli ?? undefined,
      bformuticari_433250_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
      bformuticari_401545_value: this.state.getBasvuruFormuById?.at?.(0)?.toplamButce ?? undefined,
      bformuticari_548831_value: this.state.getBasvuruFormuById?.at?.(0)?.produktor ?? undefined,
      bformuticari_241884_value: this.state.getBasvuruFormuById?.at?.(0)?.yonetmen ?? undefined,
      bformuticari_154279_value: this.state.getBasvuruFormuById?.at?.(0)?.basrol ?? undefined,
      bformuticari_306823_value: this.state.getBasvuruFormuById?.at?.(0)?.senaryoOzeti ?? undefined,
      bformuticari_821567_value: this.state.getBasvuruFormuById?.at?.(0)?.sahneTarifi ?? undefined,
      bformuticari_135744_value: this.state.getBasvuruFormuById?.at?.(0)?.eserSure ?? undefined,
      bformuticari_109874_value: this.state.getBasvuruFormuById?.at?.(0)?.fragmandaVarmi ?? undefined,
      bformuticari_43394_value: this.state.getBasvuruFormuById?.at?.(0)?.filmMecra ?? undefined,
      bformuticari_49563_value: this.state.getBasvuruFormuById?.at?.(0)?.turkiyeHakki ?? undefined,
      bformuticari_437312_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiUlke ?? undefined,
      bformuticari_763581_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiFestivalmi ?? undefined,
      bformuticari_478463_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiFestivalAciklama ?? undefined,
      bformuticari_161165_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresi ?? undefined,
      bformuticari_597208_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresiDiger ?? undefined,
      bformuticari_108219_value: this.state.getBasvuruFormuById?.at?.(0)?.telifBedeli ?? undefined,
      bformuticari_599586_value: this.state.getBasvuruFormuById?.at?.(0)?.eserKullanimSekli ?? undefined,
      bformuticari_179006_value: this.state.getBasvuruFormuById?.at?.(0)?.linkInfo ?? undefined,
      bformuticari_779822_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
      bformuticari_139034_value: this.state.getBasvuruFormuById?.at?.(0)?.sanatcilar ?? undefined,
      bformuticari_213030_value: this.state.getBasvuruFormuById?.at?.(0)?.yapimci ?? undefined,
      bformuticari_895754_value: this.state.getBasvuruFormuById?.at?.(0)?.sponsor ?? undefined,
      bformuticari_569899_value: this.state.getBasvuruFormuById?.at?.(0)?.konserYerTarih ?? undefined,
      bformuticari_179729_value: this.state.getBasvuruFormuById?.at?.(0)?.konserKapasitesi ?? undefined,
      bformuticari_936236_value: this.state.getBasvuruFormuById?.at?.(0)?.biletFiyatlari ?? undefined,
      bformuticari_46982_value: this.state.getBasvuruFormuById?.at?.(0)?.konserAfis ?? undefined,
      bformuticari_925632_value: this.state.getBasvuruFormuById?.at?.(0)?.konserKayit ?? undefined,
      bformuticari_753776_value: this.state.getBasvuruFormuById?.at?.(0)?.konserGoruntu ?? undefined,
      bformuticari_118061_value: this.state.getBasvuruFormuById?.at?.(0)?.konserCDvd ?? undefined,
      bformuticari_447965_value: this.state.getBasvuruFormuById?.at?.(0)?.telifBedeli ?? undefined,
      bformuticari_636418_value: this.state.getBasvuruFormuById?.at?.(0)?.eserKullanimSekli ?? undefined,
      bformuticari_606315_value: this.state.getBasvuruFormuById?.at?.(0)?.linkInfo ?? undefined,
      bformuticari_243523_value: this.state.getBasvuruFormuById?.at?.(0)?.konserHologram ?? undefined,
      bformuticari_252338_value: this.state.getBasvuruFormuById?.at?.(0)?.konserGelir ?? undefined,
      bformuticari_63361_value: this.state.getBasvuruFormuById?.at?.(0)?.konserGelirPay ?? undefined,
      bformuticari_160425_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
      bformuticari_634083_value: this.state.getBasvuruFormuById?.at?.(0)?.urun ?? undefined,
      bformuticari_375623_value: this.state.getBasvuruFormuById?.at?.(0)?.parca ?? undefined,
      bformuticari_84279_value: this.state.getBasvuruFormuById?.at?.(0)?.besteciSozYazari ?? undefined,
      bformuticari_952938_value: this.state.getBasvuruFormuById?.at?.(0)?.izinTuruID ?? undefined,
      bformuticari_330499_value: this.state.getBasvuruFormuById?.at?.(0)?.mecras ?? undefined,
      bformuticari_575302_value: this.state.getBasvuruFormuById?.at?.(0)?.spot ?? undefined,
      bformuticari_275256_value: this.state.getBasvuruFormuById?.at?.(0)?.filmSayisi ?? undefined,
      bformuticari_33436_value: this.state.getBasvuruFormuById?.at?.(0)?.versiyonSayisi ?? undefined,
      bformuticari_601579_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiUlke ?? undefined,
      bformuticari_925097_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresi ?? undefined,
      bformuticari_713576_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresiDiger ?? undefined,
      bformuticari_776748_value: this.state.getBasvuruFormuById?.at?.(0)?.arsiv ?? undefined,
      bformuticari_883613_value: this.state.getBasvuruFormuById?.at?.(0)?.arsivSure ?? undefined,
      bformuticari_69287_value: this.state.getBasvuruFormuById?.at?.(0)?.script ?? undefined,
      bformuticari_423768_value: this.state.getBasvuruFormuById?.at?.(0)?.eserKullanimSekli ?? undefined,
      bformuticari_680656_value: this.state.getBasvuruFormuById?.at?.(0)?.linkInfo ?? undefined,
      bformuticari_826306_value: this.state.getBasvuruFormuById?.at?.(0)?.sozDegisimi ?? undefined,
      bformuticari_508876_value: this.state.getBasvuruFormuById?.at?.(0)?.yayinTarihi ?? undefined,
      bformuticari_958009_value: this.state.getBasvuruFormuById?.at?.(0)?.munhasirlikID ?? undefined,
      bformuticari_41842_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
      bformuticari_769963_value: this.state.getBasvuruFormuById?.at?.(0)?.toplamButce ?? undefined,
      bformuticari_745722_value: this.state.getBasvuruFormuById?.at?.(0)?.produktor ?? undefined,
      bformuticari_678596_value: this.state.getBasvuruFormuById?.at?.(0)?.yonetmen ?? undefined,
      bformuticari_13402_value: this.state.getBasvuruFormuById?.at?.(0)?.basrol ?? undefined,
      bformuticari_98625_value: this.state.getBasvuruFormuById?.at?.(0)?.senaryoOzeti ?? undefined,
      bformuticari_977010_value: this.state.getBasvuruFormuById?.at?.(0)?.sahneTarifi ?? undefined,
      bformuticari_939868_value: this.state.getBasvuruFormuById?.at?.(0)?.sahneVeKoltuk ?? undefined,
      bformuticari_605461_value: this.state.getBasvuruFormuById?.at?.(0)?.koltuk ?? undefined,
      bformuticari_348650_value: this.state.getBasvuruFormuById?.at?.(0)?.yayinlanmaFrekans ?? undefined,
      bformuticari_785985_value: this.state.getBasvuruFormuById?.at?.(0)?.biletFiyatlari ?? undefined,
      bformuticari_114157_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresi ?? undefined,
      bformuticari_961538_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresiDiger ?? undefined,
      bformuticari_531317_value: this.state.getBasvuruFormuById?.at?.(0)?.sponsor ?? undefined,
      bformuticari_595383_value: this.state.getBasvuruFormuById?.at?.(0)?.telifBedeli ?? undefined,
      bformuticari_945615_value: this.state.getBasvuruFormuById?.at?.(0)?.eserKullanimSekli ?? undefined,
      bformuticari_997705_value: this.state.getBasvuruFormuById?.at?.(0)?.linkInfo ?? undefined,
      bformuticari_834746_value: this.state.getBasvuruFormuById?.at?.(0)?.fragmandaVarmi ?? undefined,
      bformuticari_970226_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
      bformuticari_284840_value: this.state.getBasvuruFormuById?.at?.(0)?.tvKanal ?? undefined,
      bformuticari_584964_value: this.state.getBasvuruFormuById?.at?.(0)?.produktor ?? undefined,
      bformuticari_151522_value: this.state.getBasvuruFormuById?.at?.(0)?.yonetmen ?? undefined,
      bformuticari_818678_value: this.state.getBasvuruFormuById?.at?.(0)?.programIcerik ?? undefined,
      bformuticari_300311_value: this.state.getBasvuruFormuById?.at?.(0)?.sezon ?? undefined,
      bformuticari_596953_value: this.state.getBasvuruFormuById?.at?.(0)?.tvBolumNo ?? undefined,
      bformuticari_38491_value: this.state.getBasvuruFormuById?.at?.(0)?.tvYayimTarihi ?? undefined,
      bformuticari_701444_value: this.state.getBasvuruFormuById?.at?.(0)?.yayinlanmaFrekans ?? undefined,
      bformuticari_475588_value: this.state.getBasvuruFormuById?.at?.(0)?.turkiyeHakki ?? undefined,
      bformuticari_382382_value: this.state.getBasvuruFormuById?.at?.(0)?.tcDisiUlke ?? undefined,
      bformuticari_841151_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresi ?? undefined,
      bformuticari_691237_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresiDiger ?? undefined,
      bformuticari_232600_value: this.state.getBasvuruFormuById?.at?.(0)?.telifBedeli ?? undefined,
      bformuticari_653975_value: this.state.getBasvuruFormuById?.at?.(0)?.eserKullanimSekli ?? undefined,
      bformuticari_607210_value: this.state.getBasvuruFormuById?.at?.(0)?.linkInfo ?? undefined,
      bformuticari_709919_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
      bformuticari_830230_value: this.state.getBasvuruFormuById?.at?.(0)?.projeTuruID ?? undefined,
      bformuticari_169770_value: this.state.getBasvuruFormuById?.at?.(0)?.programIcerik ?? undefined,
      bformuticari_211926_value: this.state.getBasvuruFormuById?.at?.(0)?.programIcerik ?? undefined,
      bformuticari_926329_value: this.state.getBasvuruFormuById?.at?.(0)?.terminTarihi ?? undefined,
      bformuticari_950974_value: this.state.getBasvuruFormuById?.at?.(0)?.acil ?? undefined,
      bformuticari_417431_value: false,
      bformuticari_770932_value: NaN,
      bformuticari_707397_value: NaN,
      bformuticari_968501_value: NaN,
      bformuticari_409878_value: this.state.getBasvuruFormuById?.at?.(0)?.aciklama ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  BFormuTicariPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBavuruTuruID ?? this.props.screenInputs.prmbavuruturuid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProjeTuruID ?? this.props.screenInputs.prmprojeturuid,
        "Guid"
      ),
      BTuru_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruTuruKodById?.length > 0
            ? stateVars.getBasvuruTuruKodById[0].kod
            : this.state.getBasvuruTuruKodById?.length > 0
            ? this.state.getBasvuruTuruKodById[0].kod
            : null
        ),
        "number"
      ),
      PTuru_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getProjeTuruFormKodById?.length > 0
            ? stateVars.getProjeTuruFormKodById[0].kod
            : this.state.getProjeTuruFormKodById?.length > 0
            ? this.state.getProjeTuruFormKodById[0].kod
            : null
        ),
        "number"
      ),
      Id_9: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      Rol_9: ReactSystemFunctions.convertToTypeByName("", "string"),
      BasvuruFormuID_10: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "BFormuTicari/BFormuTicariPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getBasvuruTuruKodById = result?.data.getBasvuruTuruKodById;
    stateVars.getProjeTuruFormKodById = result?.data.getProjeTuruFormKodById;
    stateVars.getFormNo = result?.data.getFormNo;
    stateVars.isComp861186Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp468627Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp10355Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp139406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp967257Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp938143Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp274058Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp256251Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp28903Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp918779Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp115665Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp3330Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp695955Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp25348Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp487276Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981156Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp169331Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp998670Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp406152Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp712408Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp951915Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp220908Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp846395Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp755011Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp466555Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434573Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp535471Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp564896Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp315445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp572237Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp307737Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp269167Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp884757Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp49749Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "22"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp581358Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp874517Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440117Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp579023Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "66"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp710158Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7343Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp919967Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.getUserInfo = result?.data.getUserInfo;
    stateVars.sbFirmaTuruList = result?.data.sbFirmaTuruList;

    formVars.bformuticari_907796_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].firmaTuruID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].firmaTuruID
        : null;
    formVars.bformuticari_907796_options = stateVars.sbFirmaTuruList;
    stateVars.sbProjeTuruList = result?.data.sbProjeTuruList;
    stateVars.sbMecraList = result?.data.sbMecraList;
    stateVars.sbIzinTuruList = result?.data.sbIzinTuruList;

    formVars.bformuticari_952938_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].izinTuruID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].izinTuruID
        : null;
    formVars.bformuticari_952938_options = stateVars.sbIzinTuruList;
    stateVars.sbMunhasirlikList = result?.data.sbMunhasirlikList;

    formVars.bformuticari_958009_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].munhasirlikID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].munhasirlikID
        : null;
    formVars.bformuticari_958009_options = stateVars.sbMunhasirlikList;
    stateVars.getBasvuruFormuById = result?.data.getBasvuruFormuById;
    formVars.bformuticari_389012_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].basvuranSirketAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].basvuranSirketAd
        : null
    );
    formVars.bformuticari_907796_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].firmaTuruID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].firmaTuruID
        : null;
    formVars.bformuticari_907796_options = stateVars.sbFirmaTuruList;
    formVars.bformuticari_654168_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sirketAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sirketAd
        : null
    );
    formVars.bformuticari_405451_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserAd
        : null
    );
    formVars.bformuticari_788421_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserSahibi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserSahibi
        : null
    );
    formVars.bformuticari_757838_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );
    formVars.bformuticari_613968_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].toplamButce
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].toplamButce
        : null
    );
    formVars.bformuticari_819000_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].produktor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].produktor
        : null
    );
    formVars.bformuticari_441327_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );
    formVars.bformuticari_164797_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].belgeselinKonusu
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].belgeselinKonusu
        : null
    );
    formVars.bformuticari_709057_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sahneTarifi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sahneTarifi
        : null
    );
    formVars.bformuticari_96573_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].turkiyeHakki
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].turkiyeHakki
        : null;
    formVars.bformuticari_904040_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiUlke
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiUlke
        : null
    );
    formVars.bformuticari_655326_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiFestivalmi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiFestivalmi
        : null;
    formVars.bformuticari_363998_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiFestivalAciklama
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiFestivalAciklama
        : null
    );
    formVars.bformuticari_59667_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null;
    formVars.bformuticari_846743_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresiDiger
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresiDiger
        : null
    );
    formVars.bformuticari_301253_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].telifBedeli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].telifBedeli
        : null
    );
    formVars.bformuticari_499516_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null;
    formVars.bformuticari_806100_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );
    formVars.bformuticari_727135_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );
    formVars.bformuticari_419406_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );
    formVars.bformuticari_464905_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yapimci
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yapimci
        : null
    );
    formVars.bformuticari_919241_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sanatcilar
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sanatcilar
        : null
    );
    formVars.bformuticari_74883_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].facebook
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].facebook
        : null
    );
    formVars.bformuticari_136717_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].instagram
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].instagram
        : null
    );
    formVars.bformuticari_212231_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tikTok
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tikTok
        : null
    );
    formVars.bformuticari_628115_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].twitter
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].twitter
        : null
    );
    formVars.bformuticari_774689_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].youTube
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].youTube
        : null
    );
    formVars.bformuticari_176725_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].digerSosyalMedya
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].digerSosyalMedya
        : null
    );
    formVars.bformuticari_42241_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].kullanimSekliCD
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].kullanimSekliCD
        : null;
    formVars.bformuticari_151733_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].formats
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].formats
        : null;
    formVars.bformuticari_643220_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].formatAciklama
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].formatAciklama
        : null
    );
    formVars.bformuticari_174173_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayinTarihi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayinTarihi
        : null
    );
    formVars.bformuticari_314315_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayimBolgesi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayimBolgesi
        : null;
    formVars.bformuticari_363699_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].markaUrunVarsa
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].markaUrunVarsa
        : null
    );
    formVars.bformuticari_631318_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sozDegisimi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sozDegisimi
        : null
    );
    formVars.bformuticari_731780_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );
    formVars.bformuticari_530996_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tvKanal
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tvKanal
        : null
    );
    formVars.bformuticari_35217_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].produktor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].produktor
        : null
    );
    formVars.bformuticari_215099_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );
    formVars.bformuticari_503111_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].basrol
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].basrol
        : null
    );
    formVars.bformuticari_800510_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].diziKonu
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].diziKonu
        : null
    );
    formVars.bformuticari_125636_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sezon
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sezon
        : null
    );
    formVars.bformuticari_949599_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].diziBolumNo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].diziBolumNo
        : null
    );
    formVars.bformuticari_180071_value = ReactSystemFunctions.toDate(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayinTarihi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayinTarihi
        : null
    );
    formVars.bformuticari_496974_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sahneTarifi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sahneTarifi
        : null
    );
    formVars.bformuticari_408183_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserSure
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserSure
        : null
    );
    formVars.bformuticari_554864_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].turkiyeHakki
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].turkiyeHakki
        : null;
    formVars.bformuticari_711138_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiUlke
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiUlke
        : null
    );
    formVars.bformuticari_193622_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null;
    formVars.bformuticari_296139_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresiDiger
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresiDiger
        : null
    );
    formVars.bformuticari_54329_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].telifBedeli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].telifBedeli
        : null
    );
    formVars.bformuticari_139423_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null;
    formVars.bformuticari_315845_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );
    formVars.bformuticari_953799_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );
    formVars.bformuticari_795343_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tvKanal
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tvKanal
        : null
    );
    formVars.bformuticari_894235_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].produktor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].produktor
        : null
    );
    formVars.bformuticari_752355_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );
    formVars.bformuticari_37671_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].basrol
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].basrol
        : null
    );
    formVars.bformuticari_183550_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].kacBolum
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].kacBolum
        : null
    );
    formVars.bformuticari_780979_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sinopsis
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sinopsis
        : null
    );
    formVars.bformuticari_175576_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimYeri
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimYeri
        : null;
    formVars.bformuticari_563208_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].turkiyeHakki
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].turkiyeHakki
        : null;
    formVars.bformuticari_861385_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiUlke
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiUlke
        : null
    );
    formVars.bformuticari_482186_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null
    );
    formVars.bformuticari_398647_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null;
    formVars.bformuticari_433250_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );
    formVars.bformuticari_401545_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].toplamButce
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].toplamButce
        : null
    );
    formVars.bformuticari_548831_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].produktor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].produktor
        : null
    );
    formVars.bformuticari_241884_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );
    formVars.bformuticari_154279_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].basrol
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].basrol
        : null
    );
    formVars.bformuticari_306823_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].senaryoOzeti
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].senaryoOzeti
        : null
    );
    formVars.bformuticari_821567_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sahneTarifi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sahneTarifi
        : null
    );
    formVars.bformuticari_135744_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserSure
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserSure
        : null
    );
    formVars.bformuticari_109874_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].fragmandaVarmi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].fragmandaVarmi
        : null
    );
    formVars.bformuticari_43394_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].filmMecra
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].filmMecra
        : null
    );
    formVars.bformuticari_49563_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].turkiyeHakki
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].turkiyeHakki
        : null;
    formVars.bformuticari_437312_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiUlke
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiUlke
        : null
    );
    formVars.bformuticari_763581_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiFestivalmi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiFestivalmi
        : null;
    formVars.bformuticari_478463_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiFestivalAciklama
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiFestivalAciklama
        : null
    );
    formVars.bformuticari_161165_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null;
    formVars.bformuticari_597208_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresiDiger
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresiDiger
        : null
    );
    formVars.bformuticari_108219_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].telifBedeli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].telifBedeli
        : null
    );
    formVars.bformuticari_599586_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null;
    formVars.bformuticari_179006_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );
    formVars.bformuticari_779822_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );
    formVars.bformuticari_139034_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sanatcilar
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sanatcilar
        : null
    );
    formVars.bformuticari_213030_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yapimci
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yapimci
        : null
    );
    formVars.bformuticari_895754_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sponsor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sponsor
        : null
    );
    formVars.bformuticari_569899_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserYerTarih
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserYerTarih
        : null
    );
    formVars.bformuticari_179729_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserKapasitesi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserKapasitesi
        : null
    );
    formVars.bformuticari_936236_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].biletFiyatlari
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].biletFiyatlari
        : null
    );
    formVars.bformuticari_46982_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserAfis
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserAfis
        : null
    );
    formVars.bformuticari_925632_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserKayit
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserKayit
        : null
    );
    formVars.bformuticari_753776_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserGoruntu
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserGoruntu
        : null
    );
    formVars.bformuticari_118061_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserCDvd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserCDvd
        : null
    );
    formVars.bformuticari_447965_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].telifBedeli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].telifBedeli
        : null
    );
    formVars.bformuticari_636418_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null;
    formVars.bformuticari_606315_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );
    formVars.bformuticari_243523_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserHologram
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserHologram
        : null
    );
    formVars.bformuticari_252338_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserGelir
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserGelir
        : null
    );
    formVars.bformuticari_63361_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserGelirPay
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserGelirPay
        : null
    );
    formVars.bformuticari_160425_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );
    formVars.bformuticari_634083_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].urun
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].urun
        : null
    );
    formVars.bformuticari_375623_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].parca
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].parca
        : null
    );
    formVars.bformuticari_84279_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].besteciSozYazari
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].besteciSozYazari
        : null
    );
    formVars.bformuticari_952938_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].izinTuruID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].izinTuruID
        : null;
    formVars.bformuticari_952938_options = stateVars.sbIzinTuruList;
    formVars.bformuticari_330499_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].mecras
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].mecras
        : null
    );
    formVars.bformuticari_575302_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].spot
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].spot
        : null
    );
    formVars.bformuticari_275256_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].filmSayisi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].filmSayisi
        : null
    );
    formVars.bformuticari_33436_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].versiyonSayisi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].versiyonSayisi
        : null
    );
    formVars.bformuticari_601579_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiUlke
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiUlke
        : null
    );
    formVars.bformuticari_925097_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null;
    formVars.bformuticari_713576_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresiDiger
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresiDiger
        : null
    );
    formVars.bformuticari_776748_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].arsiv
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].arsiv
        : null;
    formVars.bformuticari_883613_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].arsivSure
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].arsivSure
        : null
    );
    formVars.bformuticari_69287_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].script
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].script
        : null
    );
    formVars.bformuticari_423768_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null;
    formVars.bformuticari_680656_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );
    formVars.bformuticari_826306_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sozDegisimi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sozDegisimi
        : null
    );
    formVars.bformuticari_508876_value = ReactSystemFunctions.toDate(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayinTarihi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayinTarihi
        : null
    );
    formVars.bformuticari_958009_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].munhasirlikID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].munhasirlikID
        : null;
    formVars.bformuticari_958009_options = stateVars.sbMunhasirlikList;
    formVars.bformuticari_41842_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );
    formVars.bformuticari_769963_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].toplamButce
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].toplamButce
        : null
    );
    formVars.bformuticari_745722_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].produktor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].produktor
        : null
    );
    formVars.bformuticari_678596_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );
    formVars.bformuticari_13402_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].basrol
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].basrol
        : null
    );
    formVars.bformuticari_98625_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].senaryoOzeti
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].senaryoOzeti
        : null
    );
    formVars.bformuticari_977010_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sahneTarifi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sahneTarifi
        : null
    );
    formVars.bformuticari_939868_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sahneVeKoltuk
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sahneVeKoltuk
        : null
    );
    formVars.bformuticari_605461_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].koltuk
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].koltuk
        : null
    );
    formVars.bformuticari_348650_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayinlanmaFrekans
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayinlanmaFrekans
        : null
    );
    formVars.bformuticari_785985_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].biletFiyatlari
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].biletFiyatlari
        : null
    );
    formVars.bformuticari_114157_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null;
    formVars.bformuticari_961538_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresiDiger
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresiDiger
        : null
    );
    formVars.bformuticari_531317_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sponsor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sponsor
        : null
    );
    formVars.bformuticari_595383_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].telifBedeli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].telifBedeli
        : null
    );
    formVars.bformuticari_945615_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null;
    formVars.bformuticari_997705_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );
    formVars.bformuticari_834746_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].fragmandaVarmi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].fragmandaVarmi
        : null
    );
    formVars.bformuticari_970226_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );
    formVars.bformuticari_284840_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tvKanal
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tvKanal
        : null
    );
    formVars.bformuticari_584964_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].produktor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].produktor
        : null
    );
    formVars.bformuticari_151522_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );
    formVars.bformuticari_818678_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].programIcerik
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].programIcerik
        : null
    );
    formVars.bformuticari_300311_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sezon
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sezon
        : null
    );
    formVars.bformuticari_596953_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tvBolumNo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tvBolumNo
        : null
    );
    formVars.bformuticari_38491_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tvYayimTarihi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tvYayimTarihi
        : null
    );
    formVars.bformuticari_701444_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayinlanmaFrekans
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayinlanmaFrekans
        : null
    );
    formVars.bformuticari_475588_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].turkiyeHakki
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].turkiyeHakki
        : null;
    formVars.bformuticari_382382_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiUlke
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiUlke
        : null
    );
    formVars.bformuticari_841151_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null;
    formVars.bformuticari_691237_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresiDiger
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresiDiger
        : null
    );
    formVars.bformuticari_232600_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].telifBedeli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].telifBedeli
        : null
    );
    formVars.bformuticari_653975_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null;
    formVars.bformuticari_607210_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );
    formVars.bformuticari_709919_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );
    formVars.bformuticari_830230_value =
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeTuruID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeTuruID
        : null;
    formVars.bformuticari_169770_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].programIcerik
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].programIcerik
        : null
    );
    formVars.bformuticari_211926_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].programIcerik
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].programIcerik
        : null
    );
    formVars.bformuticari_926329_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].terminTarihi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].terminTarihi
        : null
    );
    formVars.bformuticari_926329_disablebeforedate = moment().format("YYYY-MM-DDTHH:mm:ss");
    formVars.bformuticari_950974_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].acil
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].acil
        : null
    );
    formVars.bformuticari_409878_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].aciklama
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].aciklama
        : null
    );
    stateVars.isComp123993Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].formReadOnly
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].formReadOnly
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp439359Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].formReadOnly
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].formReadOnly
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp262195Visible =
      (ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].id
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].id
            : null
        ),
        true
      ) ||
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0
              ? stateVars.getBasvuruFormuById[0].formReadOnly
              : this.state.getBasvuruFormuById?.length > 0
              ? this.state.getBasvuruFormuById[0].formReadOnly
              : null
          ),
          "0"
        )) === true
        ? "visible"
        : "hidden";
    stateVars.isComp550139Visible =
      (ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].id
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].id
            : null
        ),
        true
      ) ||
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0
              ? stateVars.getBasvuruFormuById[0].formReadOnly
              : this.state.getBasvuruFormuById?.length > 0
              ? this.state.getBasvuruFormuById[0].formReadOnly
              : null
          ),
          "0"
        )) === true
        ? "visible"
        : "hidden";
    stateVars.getListKullanimByBasvuruFId = result?.data.getListKullanimByBasvuruFId;

    stateVars.sbProjeTuruList = result?.data.sbProjeTuruList;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuTicariPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformuticari_750474_value", "string"),
      ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0
          ? stateVars.getBasvuruFormuById[0].adSoyadTEMP
          : this.state.getBasvuruFormuById?.length > 0
          ? this.state.getBasvuruFormuById[0].adSoyadTEMP
          : null
      ),
      null
    );

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuTicariPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BFormuTicariPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].adSoyadTEMP
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].adSoyadTEMP
            : null
        ),
        null
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        ReactSystemFunctions.convertToTypeByName("bformuticari_750474_value", "string"),
        ReactSystemFunctions.toString(
          this,
          stateVars.getUserInfo?.length > 0
            ? stateVars.getUserInfo[0].fullName
            : this.state.getUserInfo?.length > 0
            ? this.state.getUserInfo[0].fullName
            : null
        ),
        null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformuticari_192848_value", "string"),
      ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0
          ? stateVars.getBasvuruFormuById[0].eMailTEMP
          : this.state.getBasvuruFormuById?.length > 0
          ? this.state.getBasvuruFormuById[0].eMailTEMP
          : null
      ),
      null
    );

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuTicariPageInit4_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BFormuTicariPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].eMailTEMP
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].eMailTEMP
            : null
        ),
        null
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        ReactSystemFunctions.convertToTypeByName("bformuticari_192848_value", "string"),
        ReactSystemFunctions.toString(
          this,
          stateVars.getUserInfo?.length > 0
            ? stateVars.getUserInfo[0].userName
            : this.state.getUserInfo?.length > 0
            ? this.state.getUserInfo[0].userName
            : null
        ),
        null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformuticari_390390_value", "string"),
      ReactSystemFunctions.toString(
        this,
        stateVars.getBasvuruFormuById?.length > 0
          ? stateVars.getBasvuruFormuById[0].gsmtemp
          : this.state.getBasvuruFormuById?.length > 0
          ? this.state.getBasvuruFormuById[0].gsmtemp
          : null
      ),
      null
    );

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuTicariPageInit6_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BFormuTicariPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].gsmtemp
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].gsmtemp
            : null
        ),
        null
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        ReactSystemFunctions.convertToTypeByName("bformuticari_390390_value", "string"),
        ReactSystemFunctions.toString(
          this,
          stateVars.getUserInfo?.length > 0
            ? stateVars.getUserInfo[0].gsm
            : this.state.getUserInfo?.length > 0
            ? this.state.getUserInfo[0].gsm
            : null
        ),
        null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_653975_value", "value", "", "key", "")
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp437424Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_653975_value", "value", "", "key", "")
        ),
        "2"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp437424Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_653975_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp437424Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_945615_value", "value", "", "key", "")
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp312802Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit11_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit11_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_945615_value", "value", "", "key", "")
        ),
        "2"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp312802Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit12_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_945615_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp312802Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit13_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit13_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_423768_value", "value", "", "key", "")
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp128084Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit14_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit14_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_423768_value", "value", "", "key", "")
        ),
        "2"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp128084Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit15_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit15_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit15_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_423768_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp128084Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit16_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit16_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit16_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_636418_value", "value", "", "key", "")
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp556886Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit17_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit17_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit17_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_636418_value", "value", "", "key", "")
        ),
        "2"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp556886Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit18_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit18_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit18_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_636418_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp556886Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit19_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit19_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit19_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_599586_value", "value", "", "key", "")
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp561077Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit20_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit20_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit20_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_599586_value", "value", "", "key", "")
        ),
        "2"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp561077Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit21_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit21_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit21_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_599586_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp561077Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit22_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit22_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit22_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_139423_value", "value", "", "key", "")
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp280419Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit23_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit23_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit23_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_139423_value", "value", "", "key", "")
        ),
        "2"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp280419Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit24_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit24_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit24_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_139423_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp280419Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit25_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit25_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit25_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_499516_value", "value", "", "key", "")
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp173740Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit26_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit26_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit26_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_499516_value", "value", "", "key", "")
        ),
        "2"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp173740Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit27_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit27_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit27_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_499516_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp173740Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit28_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit28_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit28_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "BFormuTicari/BFormuTicariPageInit28_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDokumansListByBFId = result?.data.getDokumansListByBFId;

    stateVars.sbBasvuruTuruList = result?.data.sbBasvuruTuruList;

    formVars.bformuticari_772477_options = stateVars.sbBasvuruTuruList;
    stateVars.sbProjeFormTuruList = result?.data.sbProjeFormTuruList;

    formVars.bformuticari_56794_options = stateVars.sbProjeFormTuruList;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuTicariPageInit29_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariPageInit29_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp341420Visible", "string"),
      ReactSystemFunctions.convertToTypeByName("hidden", "string")
    );

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuTicariPageInit30_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BFormuTicariPageInit30_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      FormNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "number"
      ),
      Nerden_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "BFormuTicari/BFormuTicariPageInit30_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.rwDigeriseVisible01 = result?.data.rwDigeriseVisible01;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuTicariPageInit31_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariPageInit31_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.rwDigeriseVisible01?.length > 0
            ? stateVars.rwDigeriseVisible01[0].rwDigeriseVisible01
            : this.state.rwDigeriseVisible01?.length > 0
            ? this.state.rwDigeriseVisible01[0].rwDigeriseVisible01
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp341420Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit32_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit32_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit32_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "ticket"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp714077Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit33_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit33_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit33_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "istakip"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp616689Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit34_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit34_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit34_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].formReadOnly
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].formReadOnly
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp714077Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit35_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit35_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit35_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].formReadOnly
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].formReadOnly
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp616689Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit36_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit36_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit36_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].formReadOnly
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].formReadOnly
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp32516Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit37_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit37_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit37_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].formReadOnly
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].formReadOnly
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp633827Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit38_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariPageInit38_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariPageInit38_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.isComp236080Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.NewDisplay ?? this.props.screenInputs.newdisplay, "D") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp861186Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp468627Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp10355Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp139406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp967257Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp938143Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp274058Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp256251Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp28903Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp918779Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp115665Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp3330Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp695955Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp25348Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp487276Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981156Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp169331Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp998670Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp406152Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp712408Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformuticari_389012_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].basvuranSirketAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].basvuranSirketAd
        : null
    );

    formVars.bformuticari_907796_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].firmaTuruID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].firmaTuruID
        : null
    );

    stateVars.dataSource_907796 = this.state.sbFirmaTuruList;
    stateVars.dataSource_907796 = this.state.sbFirmaTuruList;
    formVars.bformuticari_654168_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sirketAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sirketAd
        : null
    );

    stateVars.isComp951915Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformuticari_405451_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserAd
        : null
    );

    stateVars.isComp220908Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformuticari_788421_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserSahibi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserSahibi
        : null
    );

    stateVars.isComp846395Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformuticari_757838_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );

    formVars.bformuticari_613968_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].toplamButce
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].toplamButce
        : null
    );

    formVars.bformuticari_819000_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].produktor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].produktor
        : null
    );

    formVars.bformuticari_441327_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );

    formVars.bformuticari_164797_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].belgeselinKonusu
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].belgeselinKonusu
        : null
    );

    formVars.bformuticari_709057_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sahneTarifi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sahneTarifi
        : null
    );

    formVars.bformuticari_96573_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].turkiyeHakki
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].turkiyeHakki
        : null
    );

    formVars.bformuticari_904040_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiUlke
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiUlke
        : null
    );

    formVars.bformuticari_655326_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiFestivalmi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiFestivalmi
        : null
    );

    formVars.bformuticari_363998_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiFestivalAciklama
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiFestivalAciklama
        : null
    );

    formVars.bformuticari_59667_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null
    );

    formVars.bformuticari_846743_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresiDiger
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresiDiger
        : null
    );

    formVars.bformuticari_301253_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].telifBedeli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].telifBedeli
        : null
    );

    formVars.bformuticari_499516_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null
    );

    formVars.bformuticari_806100_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );

    stateVars.isComp755011Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformuticari_727135_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );

    formVars.bformuticari_419406_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );

    formVars.bformuticari_464905_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yapimci
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yapimci
        : null
    );

    formVars.bformuticari_919241_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sanatcilar
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sanatcilar
        : null
    );

    formVars.bformuticari_74883_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].facebook
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].facebook
        : null
    );

    formVars.bformuticari_136717_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].instagram
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].instagram
        : null
    );

    formVars.bformuticari_212231_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tikTok
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tikTok
        : null
    );

    formVars.bformuticari_628115_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].twitter
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].twitter
        : null
    );

    formVars.bformuticari_774689_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].youTube
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].youTube
        : null
    );

    formVars.bformuticari_176725_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].digerSosyalMedya
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].digerSosyalMedya
        : null
    );

    formVars.bformuticari_42241_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].kullanimSekliCD
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].kullanimSekliCD
        : null
    );

    formVars.bformuticari_151733_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].formats
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].formats
        : null
    );

    formVars.bformuticari_643220_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].formatAciklama
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].formatAciklama
        : null
    );

    formVars.bformuticari_174173_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayinTarihi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayinTarihi
        : null
    );

    formVars.bformuticari_314315_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayimBolgesi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayimBolgesi
        : null
    );

    formVars.bformuticari_363699_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].markaUrunVarsa
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].markaUrunVarsa
        : null
    );

    formVars.bformuticari_631318_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sozDegisimi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sozDegisimi
        : null
    );

    stateVars.isComp466555Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformuticari_731780_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );

    formVars.bformuticari_530996_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tvKanal
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tvKanal
        : null
    );

    formVars.bformuticari_35217_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].produktor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].produktor
        : null
    );

    formVars.bformuticari_215099_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );

    formVars.bformuticari_503111_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].basrol
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].basrol
        : null
    );

    formVars.bformuticari_800510_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].diziKonu
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].diziKonu
        : null
    );

    formVars.bformuticari_125636_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sezon
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sezon
        : null
    );

    formVars.bformuticari_949599_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].diziBolumNo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].diziBolumNo
        : null
    );

    formVars.bformuticari_180071_value = ReactSystemFunctions.toDate(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayinTarihi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayinTarihi
        : null
    );

    formVars.bformuticari_496974_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sahneTarifi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sahneTarifi
        : null
    );

    formVars.bformuticari_408183_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserSure
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserSure
        : null
    );

    formVars.bformuticari_554864_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].turkiyeHakki
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].turkiyeHakki
        : null
    );

    formVars.bformuticari_711138_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiUlke
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiUlke
        : null
    );

    formVars.bformuticari_193622_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null
    );

    formVars.bformuticari_296139_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresiDiger
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresiDiger
        : null
    );

    formVars.bformuticari_54329_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].telifBedeli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].telifBedeli
        : null
    );

    formVars.bformuticari_139423_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null
    );

    formVars.bformuticari_315845_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );

    stateVars.isComp434573Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformuticari_953799_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );

    formVars.bformuticari_795343_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tvKanal
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tvKanal
        : null
    );

    formVars.bformuticari_894235_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].produktor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].produktor
        : null
    );

    formVars.bformuticari_752355_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );

    formVars.bformuticari_37671_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].basrol
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].basrol
        : null
    );

    formVars.bformuticari_183550_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].kacBolum
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].kacBolum
        : null
    );

    formVars.bformuticari_780979_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sinopsis
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sinopsis
        : null
    );

    formVars.bformuticari_175576_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimYeri
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimYeri
        : null
    );

    formVars.bformuticari_563208_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].turkiyeHakki
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].turkiyeHakki
        : null
    );

    formVars.bformuticari_861385_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiUlke
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiUlke
        : null
    );

    formVars.bformuticari_482186_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null
    );

    formVars.bformuticari_398647_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null
    );

    stateVars.isComp535471Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformuticari_433250_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );

    formVars.bformuticari_401545_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].toplamButce
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].toplamButce
        : null
    );

    formVars.bformuticari_548831_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].produktor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].produktor
        : null
    );

    formVars.bformuticari_241884_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );

    formVars.bformuticari_154279_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].basrol
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].basrol
        : null
    );

    formVars.bformuticari_306823_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].senaryoOzeti
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].senaryoOzeti
        : null
    );

    formVars.bformuticari_821567_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sahneTarifi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sahneTarifi
        : null
    );

    formVars.bformuticari_135744_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserSure
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserSure
        : null
    );

    formVars.bformuticari_109874_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].fragmandaVarmi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].fragmandaVarmi
        : null
    );

    formVars.bformuticari_43394_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].filmMecra
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].filmMecra
        : null
    );

    formVars.bformuticari_49563_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].turkiyeHakki
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].turkiyeHakki
        : null
    );

    formVars.bformuticari_437312_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiUlke
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiUlke
        : null
    );

    formVars.bformuticari_763581_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiFestivalmi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiFestivalmi
        : null
    );

    formVars.bformuticari_478463_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiFestivalAciklama
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiFestivalAciklama
        : null
    );

    formVars.bformuticari_161165_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null
    );

    formVars.bformuticari_597208_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresiDiger
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresiDiger
        : null
    );

    formVars.bformuticari_108219_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].telifBedeli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].telifBedeli
        : null
    );

    formVars.bformuticari_599586_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null
    );

    formVars.bformuticari_179006_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );

    stateVars.isComp564896Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformuticari_779822_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );

    formVars.bformuticari_139034_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sanatcilar
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sanatcilar
        : null
    );

    formVars.bformuticari_213030_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yapimci
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yapimci
        : null
    );

    formVars.bformuticari_895754_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sponsor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sponsor
        : null
    );

    formVars.bformuticari_569899_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserYerTarih
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserYerTarih
        : null
    );

    formVars.bformuticari_179729_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserKapasitesi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserKapasitesi
        : null
    );

    formVars.bformuticari_936236_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].biletFiyatlari
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].biletFiyatlari
        : null
    );

    formVars.bformuticari_46982_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserAfis
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserAfis
        : null
    );

    formVars.bformuticari_925632_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserKayit
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserKayit
        : null
    );

    formVars.bformuticari_753776_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserGoruntu
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserGoruntu
        : null
    );

    formVars.bformuticari_118061_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserCDvd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserCDvd
        : null
    );

    formVars.bformuticari_447965_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].telifBedeli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].telifBedeli
        : null
    );

    formVars.bformuticari_636418_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null
    );

    formVars.bformuticari_606315_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );

    formVars.bformuticari_243523_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserHologram
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserHologram
        : null
    );

    formVars.bformuticari_252338_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserGelir
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserGelir
        : null
    );

    formVars.bformuticari_63361_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].konserGelirPay
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].konserGelirPay
        : null
    );

    stateVars.isComp315445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformuticari_160425_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );

    formVars.bformuticari_634083_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].urun
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].urun
        : null
    );

    formVars.bformuticari_375623_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].parca
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].parca
        : null
    );

    formVars.bformuticari_84279_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].besteciSozYazari
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].besteciSozYazari
        : null
    );

    formVars.bformuticari_952938_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].izinTuruID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].izinTuruID
        : null
    );

    stateVars.dataSource_952938 = this.state.sbIzinTuruList;
    stateVars.dataSource_952938 = this.state.sbIzinTuruList;
    formVars.bformuticari_330499_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].mecras
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].mecras
        : null
    );

    formVars.bformuticari_575302_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].spot
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].spot
        : null
    );

    formVars.bformuticari_275256_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].filmSayisi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].filmSayisi
        : null
    );

    formVars.bformuticari_33436_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].versiyonSayisi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].versiyonSayisi
        : null
    );

    formVars.bformuticari_601579_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiUlke
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiUlke
        : null
    );

    formVars.bformuticari_925097_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null
    );

    formVars.bformuticari_713576_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresiDiger
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresiDiger
        : null
    );

    formVars.bformuticari_776748_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].arsiv
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].arsiv
        : null
    );

    formVars.bformuticari_883613_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].arsivSure
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].arsivSure
        : null
    );

    formVars.bformuticari_69287_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].script
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].script
        : null
    );

    formVars.bformuticari_423768_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null
    );

    formVars.bformuticari_680656_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );

    formVars.bformuticari_826306_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sozDegisimi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sozDegisimi
        : null
    );

    formVars.bformuticari_508876_value = ReactSystemFunctions.toDate(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayinTarihi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayinTarihi
        : null
    );

    formVars.bformuticari_958009_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].munhasirlikID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].munhasirlikID
        : null
    );

    stateVars.dataSource_958009 = this.state.sbMunhasirlikList;
    stateVars.dataSource_958009 = this.state.sbMunhasirlikList;
    stateVars.isComp572237Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformuticari_41842_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );

    formVars.bformuticari_769963_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].toplamButce
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].toplamButce
        : null
    );

    formVars.bformuticari_745722_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].produktor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].produktor
        : null
    );

    formVars.bformuticari_678596_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );

    formVars.bformuticari_13402_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].basrol
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].basrol
        : null
    );

    formVars.bformuticari_98625_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].senaryoOzeti
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].senaryoOzeti
        : null
    );

    formVars.bformuticari_977010_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sahneTarifi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sahneTarifi
        : null
    );

    formVars.bformuticari_939868_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sahneVeKoltuk
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sahneVeKoltuk
        : null
    );

    formVars.bformuticari_605461_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].koltuk
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].koltuk
        : null
    );

    formVars.bformuticari_348650_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayinlanmaFrekans
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayinlanmaFrekans
        : null
    );

    formVars.bformuticari_785985_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].biletFiyatlari
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].biletFiyatlari
        : null
    );

    formVars.bformuticari_114157_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null
    );

    formVars.bformuticari_961538_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresiDiger
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresiDiger
        : null
    );

    formVars.bformuticari_531317_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sponsor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sponsor
        : null
    );

    formVars.bformuticari_595383_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].telifBedeli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].telifBedeli
        : null
    );

    formVars.bformuticari_945615_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null
    );

    formVars.bformuticari_997705_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );

    formVars.bformuticari_834746_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].fragmandaVarmi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].fragmandaVarmi
        : null
    );

    stateVars.isComp307737Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformuticari_970226_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );

    formVars.bformuticari_284840_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tvKanal
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tvKanal
        : null
    );

    formVars.bformuticari_584964_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].produktor
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].produktor
        : null
    );

    formVars.bformuticari_151522_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yonetmen
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yonetmen
        : null
    );

    formVars.bformuticari_818678_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].programIcerik
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].programIcerik
        : null
    );

    formVars.bformuticari_300311_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].sezon
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].sezon
        : null
    );

    formVars.bformuticari_596953_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tvBolumNo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tvBolumNo
        : null
    );

    formVars.bformuticari_38491_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tvYayimTarihi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tvYayimTarihi
        : null
    );

    formVars.bformuticari_701444_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].yayinlanmaFrekans
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].yayinlanmaFrekans
        : null
    );

    formVars.bformuticari_475588_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].turkiyeHakki
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].turkiyeHakki
        : null
    );

    formVars.bformuticari_382382_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].tcDisiUlke
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].tcDisiUlke
        : null
    );

    formVars.bformuticari_841151_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresi
        : null
    );

    formVars.bformuticari_691237_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].lisansSuresiDiger
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].lisansSuresiDiger
        : null
    );

    formVars.bformuticari_232600_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].telifBedeli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].telifBedeli
        : null
    );

    formVars.bformuticari_653975_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].eserKullanimSekli
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].eserKullanimSekli
        : null
    );

    formVars.bformuticari_607210_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].linkInfo
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].linkInfo
        : null
    );

    stateVars.isComp269167Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformuticari_709919_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeAd
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeAd
        : null
    );

    formVars.bformuticari_830230_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].projeTuruID
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].projeTuruID
        : null
    );

    formVars.bformuticari_169770_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].programIcerik
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].programIcerik
        : null
    );

    formVars.bformuticari_211926_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].programIcerik
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].programIcerik
        : null
    );

    formVars.bformuticari_926329_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].terminTarihi
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].terminTarihi
        : null
    );

    formVars.bformuticari_950974_value = ReactSystemFunctions.value(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].acil
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].acil
        : null
    );

    stateVars.isComp123993Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].formReadOnly
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].formReadOnly
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_577170 = this.state.getListKullanimByBasvuruFId;
    stateVars.isComp439359Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].formReadOnly
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].formReadOnly
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.bformuticari_409878_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBasvuruFormuById?.length > 0
        ? stateVars.getBasvuruFormuById[0].aciklama
        : this.state.getBasvuruFormuById?.length > 0
        ? this.state.getBasvuruFormuById[0].aciklama
        : null
    );

    stateVars.isComp262195Visible =
      (ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].id
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].id
            : null
        ),
        true
      ) ||
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0
              ? stateVars.getBasvuruFormuById[0].formReadOnly
              : this.state.getBasvuruFormuById?.length > 0
              ? this.state.getBasvuruFormuById[0].formReadOnly
              : null
          ),
          "0"
        )) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_614193 = this.state.getDokumansListByBFId;
    stateVars.isComp550139Visible =
      (ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruFormuById?.length > 0
            ? stateVars.getBasvuruFormuById[0].id
            : this.state.getBasvuruFormuById?.length > 0
            ? this.state.getBasvuruFormuById[0].id
            : null
        ),
        true
      ) ||
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.getBasvuruFormuById?.length > 0
              ? stateVars.getBasvuruFormuById[0].formReadOnly
              : this.state.getBasvuruFormuById?.length > 0
              ? this.state.getBasvuruFormuById[0].formReadOnly
              : null
          ),
          "0"
        )) === true
        ? "visible"
        : "hidden";
    stateVars.isComp884757Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp49749Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "22"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp581358Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp874517Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440117Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp579023Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "66"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp710158Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7343Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp919967Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_772477 = this.state.sbBasvuruTuruList;
    stateVars.dataSource_772477 = this.state.sbBasvuruTuruList;

    stateVars.dataSource_56794 = this.state.sbProjeFormTuruList;
    stateVars.dataSource_56794 = this.state.sbProjeFormTuruList;
    stateVars.isComp32516Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "istakip"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp169377Visible =
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "info"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp714077Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmNerdenGeldi ?? this.props.screenInputs.prmnerdengeldi,
        "ticket"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  BFormuTicariComponent_236080_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuTicariComponent_499516_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_499516_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp173740Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_499516_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_499516_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariComponent_499516_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_499516_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp173740Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  BFormuTicariComponent_139423_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_139423_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp280419Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_139423_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_139423_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariComponent_139423_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_139423_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp280419Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  BFormuTicariComponent_599586_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_599586_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp561077Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_599586_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_599586_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariComponent_599586_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_599586_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp561077Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  BFormuTicariComponent_636418_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_636418_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp556886Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_636418_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_636418_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariComponent_636418_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_636418_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp556886Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  BFormuTicariComponent_776748_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_776748_value", "value", "", "key", "")
        ),
        "1"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp119700Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_776748_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_776748_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariComponent_776748_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_776748_value", "value", "", "key", "")
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp119700Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  BFormuTicariComponent_423768_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_423768_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp128084Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_423768_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_423768_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariComponent_423768_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_423768_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp128084Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  BFormuTicariComponent_945615_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_945615_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp312802Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_945615_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_945615_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariComponent_945615_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_945615_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp312802Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  BFormuTicariComponent_653975_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_653975_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp437424Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_653975_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_653975_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariComponent_653975_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_653975_value", "value", "", "key", "")
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp437424Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  BFormuTicariComponent_417431_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_417431_value", "value", "", "", "")
        ),
        false
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp304820Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_417431_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_417431_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariComponent_417431_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_417431_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp541034Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_417431_onChange2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_417431_onChange2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariComponent_417431_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_417431_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp304820Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_417431_onChange3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_417431_onChange3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariComponent_417431_onChange3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_417431_value", "value", "", "", "")
        ),
        false
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp541034Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  BFormuTicariComponent_123993_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "BFormuTicari/BFormuTicariComponent_123993_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getListKullanimByBasvuruFId = result?.data.getListKullanimByBasvuruFId;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuTicariComponent_123993_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_123993_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformuticari_141657_value", "string"),
      "",
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformuticari_570080_value", "string"),
      "",
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformuticari_770932_value", "string"),
      null,
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformuticari_707397_value", "string"),
      null,
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("bformuticari_968501_value", "string"),
      null,
      null
    );

    stateVars.dataSource_577170 = this.state.getListKullanimByBasvuruFId;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuTicariComponent_439359_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "bformuticari_577170_value", "id"),
        "Guid"
      ),
      BasvuruFormuID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "BFormuTicari/BFormuTicariComponent_439359_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.delKullanimSureleriById = result?.data.delKullanimSureleriById;
    stateVars.getListKullanimByBasvuruFId = result?.data.getListKullanimByBasvuruFId;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuTicariComponent_439359_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_439359_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_577170 = this.state.getListKullanimByBasvuruFId;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuTicariComponent_262195_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuTicariComponent_262195_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BFormuTicariComponent_262195_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      Dosya_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      DosyaTanim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      )
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuTicari/BFormuTicariComponent_262195_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setDokumansSave = result?.data.setDokumansSave;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_262195_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_262195_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_262195_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuTicari/BFormuTicariComponent_262195_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.getDokumansListByBFId = result?.data.getDokumansListByBFId;

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_262195_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_262195_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_262195_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        ReactSystemFunctions.convertToTypeByName("bformuticari_241255_value", "string"),
        null,
        null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_262195_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_262195_onClick4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariComponent_262195_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_262195_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_262195_onClick5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariComponent_262195_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.dataSource_614193 = this.state.getDokumansListByBFId;

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  BFormuTicariComponent_12374_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
      ReactSystemFunctions.value(this, "bformuticari_614193_value", "dosya"),
      ReactSystemFunctions.value(this, "bformuticari_614193_value", "dosyaTanim")
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuTicariComponent_550139_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "bformuticari_614193_value", "id"),
        "Guid"
      ),
      BasvuruFormuID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "BFormuTicari/BFormuTicariComponent_550139_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setDokumansDelete = result?.data.setDokumansDelete;
    stateVars.getDokumansListByBFId = result?.data.getDokumansListByBFId;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuTicariComponent_550139_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_550139_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_614193 = this.state.getDokumansListByBFId;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuTicariComponent_772477_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_772477_value", "value", "sbBasvuruTuruList", "id", "kod")
        ),
        "2"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp5519Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("visible", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_772477_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_772477_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BFormuTicariComponent_772477_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_772477_value", "value", "sbBasvuruTuruList", "id", "kod")
        ),
        "2"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp5519Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  BFormuTicariComponent_32516_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_772477_value", "value", "sbBasvuruTuruList", "id", "id")
        ),
        "Guid"
      ),
      ProjeTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_56794_value", "value", "sbProjeFormTuruList", "id", "id")
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "BFormuTicari/BFormuTicariComponent_32516_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setDigerTuruDegistir = result?.data.setDigerTuruDegistir;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuTicariComponent_32516_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_32516_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuTicariComponent_633827_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuTicariComponent_714077_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bformuticari_192848_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBavuruTuruID ?? this.props.screenInputs.prmbavuruturuid,
        "Guid"
      ),
      ProjeTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProjeTuruID ?? this.props.screenInputs.prmprojeturuid,
        "Guid"
      ),
      LisansTipiID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_654168_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_757838_value", "value", "", "", "")
        ),
        "string"
      ),
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        "string"
      ),
      GSM_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_390390_value", "value", "", "", "")
        ),
        "string"
      ),
      AdSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_750474_value", "value", "", "", "")
        ),
        "string"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_409878_value", "value", "", "", "")
        ),
        "string"
      ),
      Acil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_950974_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TerminTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_926329_value", "value", "", "", "")
        ),
        "Date"
      ),
      ToplamButce_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_613968_value", "value", "", "", "")
        ),
        "string"
      ),
      Produktor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_819000_value", "value", "", "", "")
        ),
        "string"
      ),
      Yonetmen_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_441327_value", "value", "", "", "")
        ),
        "string"
      ),
      BelgeselinKonusu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_164797_value", "value", "", "", "")
        ),
        "string"
      ),
      SahneTarifi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_709057_value", "value", "", "", "")
        ),
        "string"
      ),
      TurkiyeHakki_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_96573_value", "value", "", "key", "")
        ),
        "number"
      ),
      TCDisiUlke_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_904040_value", "value", "", "", "")
        ),
        "string"
      ),
      TCDisiFestivalmi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_655326_value", "value", "", "key", "")
        ),
        "number"
      ),
      TCDisiFestivalAciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_363998_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansSuresi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_59667_value", "value", "", "key", "")
        ),
        "string"
      ),
      TelifBedeli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_301253_value", "value", "", "", "")
        ),
        "string"
      ),
      EserKullanimSekli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_499516_value", "value", "", "key", "")
        ),
        "number"
      ),
      EserAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_405451_value", "value", "", "", "")
        ),
        "string"
      ),
      EserSahibi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_788421_value", "value", "", "", "")
        ),
        "string"
      ),
      FirmaTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_907796_value", "value", "sbFirmaTuruList", "id", "id")
        ),
        "Guid"
      ),
      BasvuranSirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_389012_value", "value", "", "", "")
        ),
        "string"
      ),
      LinkInfo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_806100_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansSuresiDiger_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_846743_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "1"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuTicari/BFormuTicariComponent_714077_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spBasvuruFormuInsert_Ticari_F1 = result?.data.spBasvuruFormuInsert_Ticari_F1;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_714077_onClick1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_714077_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_714077_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bformuticari_192848_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBavuruTuruID ?? this.props.screenInputs.prmbavuruturuid,
        "Guid"
      ),
      ProjeTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProjeTuruID ?? this.props.screenInputs.prmprojeturuid,
        "Guid"
      ),
      LisansTipiID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_654168_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_727135_value", "value", "", "", "")
        ),
        "string"
      ),
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        "string"
      ),
      GSM_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_390390_value", "value", "", "", "")
        ),
        "string"
      ),
      AdSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_750474_value", "value", "", "", "")
        ),
        "string"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_409878_value", "value", "", "", "")
        ),
        "string"
      ),
      Acil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_950974_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TerminTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_926329_value", "value", "", "", "")
        ),
        "Date"
      ),
      Yapimci_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_464905_value", "value", "", "", "")
        ),
        "string"
      ),
      Sanatcilar_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_919241_value", "value", "", "", "")
        ),
        "string"
      ),
      YapimAd_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      KullanimSekliCD_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_42241_value", "value", "", "key", "")
        ),
        "number"
      ),
      Format_0: ReactSystemFunctions.convertToTypeByName(null, "number"),
      FormatAciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_643220_value", "value", "", "", "")
        ),
        "string"
      ),
      YayinTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_174173_value", "value", "", "", "")
        ),
        "string"
      ),
      YayimBolgesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_314315_value", "value", "", "key", "")
        ),
        "number"
      ),
      MarkaUrunVarsa_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_363699_value", "value", "", "", "")
        ),
        "string"
      ),
      SozDegisimi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_631318_value", "value", "", "", "")
        ),
        "string"
      ),
      Formats_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_151733_value", "value", "", "key", "")
        ),
        "string"
      ),
      Facebook_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_74883_value", "value", "", "", "")
        ),
        "string"
      ),
      Instagram_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_136717_value", "value", "", "", "")
        ),
        "string"
      ),
      TikTok_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_212231_value", "value", "", "", "")
        ),
        "string"
      ),
      Twitter_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_628115_value", "value", "", "", "")
        ),
        "string"
      ),
      YouTube_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_774689_value", "value", "", "", "")
        ),
        "string"
      ),
      DigerSosyalMedya_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_176725_value", "value", "", "", "")
        ),
        "string"
      ),
      EserAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_405451_value", "value", "", "", "")
        ),
        "string"
      ),
      EserSahibi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_788421_value", "value", "", "", "")
        ),
        "string"
      ),
      FirmaTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_907796_value", "value", "sbFirmaTuruList", "id", "id")
        ),
        "Guid"
      ),
      BasvuranSirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_389012_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "2"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuTicari/BFormuTicariComponent_714077_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spBasvuruFormuInsert_Ticari_F2 = result?.data.spBasvuruFormuInsert_Ticari_F2;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_714077_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_714077_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_714077_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bformuticari_192848_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBavuruTuruID ?? this.props.screenInputs.prmbavuruturuid,
        "Guid"
      ),
      ProjeTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProjeTuruID ?? this.props.screenInputs.prmprojeturuid,
        "Guid"
      ),
      LisansTipiID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_654168_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_731780_value", "value", "", "", "")
        ),
        "string"
      ),
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        "string"
      ),
      GSM_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_390390_value", "value", "", "", "")
        ),
        "string"
      ),
      AdSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_750474_value", "value", "", "", "")
        ),
        "string"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_409878_value", "value", "", "", "")
        ),
        "string"
      ),
      Acil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_950974_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TerminTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_926329_value", "value", "", "", "")
        ),
        "Date"
      ),
      Produktor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_35217_value", "value", "", "", "")
        ),
        "string"
      ),
      Yonetmen_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_215099_value", "value", "", "", "")
        ),
        "string"
      ),
      SahneTarifi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_496974_value", "value", "", "", "")
        ),
        "string"
      ),
      TurkiyeHakki_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_554864_value", "value", "", "key", "")
        ),
        "number"
      ),
      TCDisiUlke_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_711138_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansSuresi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_193622_value", "value", "", "key", "")
        ),
        "string"
      ),
      TelifBedeli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_54329_value", "value", "", "", "")
        ),
        "string"
      ),
      EserKullanimSekli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_139423_value", "value", "", "key", "")
        ),
        "number"
      ),
      TVKanal_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_530996_value", "value", "", "", "")
        ),
        "string"
      ),
      Basrol_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_503111_value", "value", "", "", "")
        ),
        "string"
      ),
      YayinTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_180071_value", "value", "", "", "")
        ),
        "string"
      ),
      EserAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_405451_value", "value", "", "", "")
        ),
        "string"
      ),
      EserSahibi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_788421_value", "value", "", "", "")
        ),
        "string"
      ),
      LinkInfo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_315845_value", "value", "", "", "")
        ),
        "string"
      ),
      DiziKonu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_800510_value", "value", "", "", "")
        ),
        "string"
      ),
      DiziBolumNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_949599_value", "value", "", "", "")
        ),
        "string"
      ),
      EserSure_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_408183_value", "value", "", "", "")
        ),
        "string"
      ),
      FirmaTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_907796_value", "value", "sbFirmaTuruList", "id", "id")
        ),
        "Guid"
      ),
      BasvuranSirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_389012_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansSuresiDiger_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_296139_value", "value", "", "", "")
        ),
        "string"
      ),
      Sezon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_125636_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "3"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuTicari/BFormuTicariComponent_714077_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spBasvuruFormuInsert_Ticari_F3 = result?.data.spBasvuruFormuInsert_Ticari_F3;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_714077_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_714077_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_714077_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bformuticari_192848_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBavuruTuruID ?? this.props.screenInputs.prmbavuruturuid,
        "Guid"
      ),
      ProjeTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProjeTuruID ?? this.props.screenInputs.prmprojeturuid,
        "Guid"
      ),
      LisansTipiID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_654168_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_953799_value", "value", "", "", "")
        ),
        "string"
      ),
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        "string"
      ),
      GSM_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_390390_value", "value", "", "", "")
        ),
        "string"
      ),
      AdSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_750474_value", "value", "", "", "")
        ),
        "string"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_409878_value", "value", "", "", "")
        ),
        "string"
      ),
      Acil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_950974_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TerminTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_926329_value", "value", "", "", "")
        ),
        "Date"
      ),
      Produktor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_894235_value", "value", "", "", "")
        ),
        "string"
      ),
      Yonetmen_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_752355_value", "value", "", "", "")
        ),
        "string"
      ),
      TurkiyeHakki_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_563208_value", "value", "", "key", "")
        ),
        "number"
      ),
      TCDisiUlke_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_861385_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansSuresi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_482186_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansSuresiDiger_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_482186_value", "value", "", "", "")
        ),
        "string"
      ),
      EserKullanimSekli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_398647_value", "value", "", "key", "")
        ),
        "number"
      ),
      TVKanal_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_795343_value", "value", "", "", "")
        ),
        "string"
      ),
      Basrol_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_37671_value", "value", "", "", "")
        ),
        "string"
      ),
      YayinTarihi_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      EserKullanimYeri_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_175576_value", "value", "", "key", "")
        ),
        "number"
      ),
      Sinopsis_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_780979_value", "value", "", "", "")
        ),
        "string"
      ),
      KacBolum_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_183550_value", "value", "", "", "")
        ),
        "string"
      ),
      FirmaTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_907796_value", "value", "sbFirmaTuruList", "id", "id")
        ),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "4"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuTicari/BFormuTicariComponent_714077_onClick3_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spBasvuruFormuInsert_Ticari_F4 = result?.data.spBasvuruFormuInsert_Ticari_F4;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_714077_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_714077_onClick4_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_714077_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bformuticari_192848_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBavuruTuruID ?? this.props.screenInputs.prmbavuruturuid,
        "Guid"
      ),
      ProjeTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProjeTuruID ?? this.props.screenInputs.prmprojeturuid,
        "Guid"
      ),
      LisansTipiID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_654168_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_433250_value", "value", "", "", "")
        ),
        "string"
      ),
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        "string"
      ),
      GSM_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_390390_value", "value", "", "", "")
        ),
        "string"
      ),
      AdSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_750474_value", "value", "", "", "")
        ),
        "string"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_409878_value", "value", "", "", "")
        ),
        "string"
      ),
      Acil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_950974_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TerminTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_926329_value", "value", "", "", "")
        ),
        "Date"
      ),
      ToplamButce_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_401545_value", "value", "", "", "")
        ),
        "string"
      ),
      Produktor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_548831_value", "value", "", "", "")
        ),
        "string"
      ),
      Yonetmen_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_241884_value", "value", "", "", "")
        ),
        "string"
      ),
      SahneTarifi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_821567_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansSuresi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_161165_value", "value", "", "key", "")
        ),
        "string"
      ),
      TelifBedeli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_108219_value", "value", "", "", "")
        ),
        "string"
      ),
      EserKullanimSekli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_599586_value", "value", "", "key", "")
        ),
        "number"
      ),
      Basrol_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_154279_value", "value", "", "", "")
        ),
        "string"
      ),
      SenaryoOzeti_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_306823_value", "value", "", "", "")
        ),
        "string"
      ),
      FragmandaVarmi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_109874_value", "value", "", "", "")
        ),
        "string"
      ),
      TurkiyeHakki_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_49563_value", "value", "", "key", "")
        ),
        "number"
      ),
      TCDisiUlke_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_437312_value", "value", "", "", "")
        ),
        "string"
      ),
      TCDisiFestivalmi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_763581_value", "value", "", "key", "")
        ),
        "number"
      ),
      TCDisiFestivalAciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_478463_value", "value", "", "", "")
        ),
        "string"
      ),
      EserAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_405451_value", "value", "", "", "")
        ),
        "string"
      ),
      EserSahibi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_788421_value", "value", "", "", "")
        ),
        "string"
      ),
      LinkInfo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_179006_value", "value", "", "", "")
        ),
        "string"
      ),
      EserSure_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_135744_value", "value", "", "", "")
        ),
        "string"
      ),
      FirmaTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_907796_value", "value", "sbFirmaTuruList", "id", "id")
        ),
        "Guid"
      ),
      BasvuranSirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_389012_value", "value", "", "", "")
        ),
        "string"
      ),
      FilmMecra_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_43394_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansSuresiDiger_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_597208_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "5"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuTicari/BFormuTicariComponent_714077_onClick4_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spBasvuruFormuInsert_Ticari_F5 = result?.data.spBasvuruFormuInsert_Ticari_F5;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_714077_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_714077_onClick5_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_714077_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bformuticari_192848_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBavuruTuruID ?? this.props.screenInputs.prmbavuruturuid,
        "Guid"
      ),
      ProjeTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProjeTuruID ?? this.props.screenInputs.prmprojeturuid,
        "Guid"
      ),
      LisansTipiID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_654168_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_779822_value", "value", "", "", "")
        ),
        "string"
      ),
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        "string"
      ),
      GSM_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_390390_value", "value", "", "", "")
        ),
        "string"
      ),
      AdSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_750474_value", "value", "", "", "")
        ),
        "string"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_409878_value", "value", "", "", "")
        ),
        "string"
      ),
      Acil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_950974_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TerminTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_926329_value", "value", "", "", "")
        ),
        "Date"
      ),
      Sanatcilar_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_139034_value", "value", "", "", "")
        ),
        "string"
      ),
      Yapimci_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_213030_value", "value", "", "", "")
        ),
        "string"
      ),
      Sponsor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_895754_value", "value", "", "", "")
        ),
        "string"
      ),
      KonserYerTarih_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_569899_value", "value", "", "", "")
        ),
        "string"
      ),
      KonserKapasitesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_179729_value", "value", "", "", "")
        ),
        "string"
      ),
      BiletFiyatlari_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_936236_value", "value", "", "", "")
        ),
        "string"
      ),
      KonserAfis_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_46982_value", "value", "", "", "")
        ),
        "string"
      ),
      KonserKayit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_925632_value", "value", "", "", "")
        ),
        "string"
      ),
      KonserGoruntu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_753776_value", "value", "", "", "")
        ),
        "string"
      ),
      KonserCDvd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_118061_value", "value", "", "", "")
        ),
        "string"
      ),
      TelifBedeli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_447965_value", "value", "", "", "")
        ),
        "string"
      ),
      EserKullanimSekli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_636418_value", "value", "", "key", "")
        ),
        "number"
      ),
      KonserHologram_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_243523_value", "value", "", "", "")
        ),
        "string"
      ),
      KonserGelir_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_252338_value", "value", "", "", "")
        ),
        "string"
      ),
      KonserGelirPay_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_63361_value", "value", "", "", "")
        ),
        "string"
      ),
      EserAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_405451_value", "value", "", "", "")
        ),
        "string"
      ),
      EserSahibi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_788421_value", "value", "", "", "")
        ),
        "string"
      ),
      LinkInfo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_606315_value", "value", "", "", "")
        ),
        "string"
      ),
      FirmaTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_907796_value", "value", "sbFirmaTuruList", "id", "id")
        ),
        "Guid"
      ),
      BasvuranSirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_389012_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "6"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuTicari/BFormuTicariComponent_714077_onClick5_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spBasvuruFormuInsert_Ticari_F6 = result?.data.spBasvuruFormuInsert_Ticari_F6;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_714077_onClick6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_714077_onClick6_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_714077_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bformuticari_192848_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBavuruTuruID ?? this.props.screenInputs.prmbavuruturuid,
        "Guid"
      ),
      ProjeTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProjeTuruID ?? this.props.screenInputs.prmprojeturuid,
        "Guid"
      ),
      LisansTipiID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_654168_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_160425_value", "value", "", "", "")
        ),
        "string"
      ),
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        "string"
      ),
      GSM_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_390390_value", "value", "", "", "")
        ),
        "string"
      ),
      AdSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_750474_value", "value", "", "", "")
        ),
        "string"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_409878_value", "value", "", "", "")
        ),
        "string"
      ),
      Acil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_950974_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TerminTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_926329_value", "value", "", "", "")
        ),
        "Date"
      ),
      Parca_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_375623_value", "value", "", "", "")
        ),
        "string"
      ),
      Urun_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_634083_value", "value", "", "", "")
        ),
        "string"
      ),
      BesteciSozYazari_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_84279_value", "value", "", "", "")
        ),
        "string"
      ),
      IzinTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_952938_value", "value", "sbIzinTuruList", "id", "id")
        ),
        "Guid"
      ),
      Spot_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_575302_value", "value", "", "", "")
        ),
        "string"
      ),
      FilmSayisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_275256_value", "value", "", "", "")
        ),
        "string"
      ),
      VersiyonSayisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_33436_value", "value", "", "", "")
        ),
        "string"
      ),
      TCDisiUlke_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_601579_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansSuresi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_925097_value", "value", "", "key", "")
        ),
        "string"
      ),
      LisansSuresiDiger_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_713576_value", "value", "", "", "")
        ),
        "string"
      ),
      Script_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_69287_value", "value", "", "", "")
        ),
        "string"
      ),
      EserKullanimSekli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_423768_value", "value", "", "key", "")
        ),
        "number"
      ),
      SozDegisimi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_826306_value", "value", "", "", "")
        ),
        "string"
      ),
      YayinTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_508876_value", "value", "", "", "")
        ),
        "string"
      ),
      MunhasirlikID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_958009_value", "value", "sbMunhasirlikList", "id", "id")
        ),
        "Guid"
      ),
      EserAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_405451_value", "value", "", "", "")
        ),
        "string"
      ),
      EserSahibi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_788421_value", "value", "", "", "")
        ),
        "string"
      ),
      LinkInfo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_680656_value", "value", "", "", "")
        ),
        "string"
      ),
      FirmaTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_907796_value", "value", "sbFirmaTuruList", "id", "id")
        ),
        "Guid"
      ),
      BasvuranSirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_389012_value", "value", "", "", "")
        ),
        "string"
      ),
      Mecras_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_330499_value", "value", "", "", "")
        ),
        "string"
      ),
      Arsiv_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_776748_value", "value", "", "key", "")
        ),
        "number"
      ),
      ArsivSure_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_883613_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "7"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuTicari/BFormuTicariComponent_714077_onClick6_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spBasvuruFormuInsert_Ticari_F7 = result?.data.spBasvuruFormuInsert_Ticari_F7;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_714077_onClick7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_714077_onClick7_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_714077_onClick7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bformuticari_192848_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBavuruTuruID ?? this.props.screenInputs.prmbavuruturuid,
        "Guid"
      ),
      ProjeTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProjeTuruID ?? this.props.screenInputs.prmprojeturuid,
        "Guid"
      ),
      LisansTipiID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_654168_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_41842_value", "value", "", "", "")
        ),
        "string"
      ),
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        "string"
      ),
      GSM_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_390390_value", "value", "", "", "")
        ),
        "string"
      ),
      AdSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_750474_value", "value", "", "", "")
        ),
        "string"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_409878_value", "value", "", "", "")
        ),
        "string"
      ),
      Acil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_950974_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TerminTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_926329_value", "value", "", "", "")
        ),
        "Date"
      ),
      ToplamButce_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_769963_value", "value", "", "", "")
        ),
        "string"
      ),
      Produktor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_745722_value", "value", "", "", "")
        ),
        "string"
      ),
      Yonetmen_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_678596_value", "value", "", "", "")
        ),
        "string"
      ),
      SahneTarifi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_977010_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansSuresi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_114157_value", "value", "", "key", "")
        ),
        "string"
      ),
      TelifBedeli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_595383_value", "value", "", "", "")
        ),
        "string"
      ),
      EserKullanimSekli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_945615_value", "value", "", "key", "")
        ),
        "number"
      ),
      Basrol_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_13402_value", "value", "", "", "")
        ),
        "string"
      ),
      SenaryoOzeti_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_98625_value", "value", "", "", "")
        ),
        "string"
      ),
      SahneVeKoltuk_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_939868_value", "value", "", "", "")
        ),
        "string"
      ),
      YayinlanmaFrekans_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_348650_value", "value", "", "", "")
        ),
        "string"
      ),
      BiletFiyatlari_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_785985_value", "value", "", "", "")
        ),
        "string"
      ),
      Sponsor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_531317_value", "value", "", "", "")
        ),
        "string"
      ),
      FragmandaVarmi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_834746_value", "value", "", "", "")
        ),
        "string"
      ),
      EserAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_405451_value", "value", "", "", "")
        ),
        "string"
      ),
      EserSahibi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_788421_value", "value", "", "", "")
        ),
        "string"
      ),
      LinkInfo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_997705_value", "value", "", "", "")
        ),
        "string"
      ),
      Koltuk_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_605461_value", "value", "", "", "")
        ),
        "string"
      ),
      FirmaTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_907796_value", "value", "sbFirmaTuruList", "id", "id")
        ),
        "Guid"
      ),
      BasvuranSirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_389012_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansSuresiDiger_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_961538_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "8"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuTicari/BFormuTicariComponent_714077_onClick7_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spBasvuruFormuInsert_Ticari_F8 = result?.data.spBasvuruFormuInsert_Ticari_F8;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_714077_onClick8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_714077_onClick8_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_714077_onClick8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bformuticari_192848_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBavuruTuruID ?? this.props.screenInputs.prmbavuruturuid,
        "Guid"
      ),
      ProjeTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProjeTuruID ?? this.props.screenInputs.prmprojeturuid,
        "Guid"
      ),
      LisansTipiID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_654168_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_970226_value", "value", "", "", "")
        ),
        "string"
      ),
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        "string"
      ),
      GSM_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_390390_value", "value", "", "", "")
        ),
        "string"
      ),
      AdSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_750474_value", "value", "", "", "")
        ),
        "string"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_409878_value", "value", "", "", "")
        ),
        "string"
      ),
      Acil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_950974_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TerminTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_926329_value", "value", "", "", "")
        ),
        "Date"
      ),
      Produktor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_584964_value", "value", "", "", "")
        ),
        "string"
      ),
      Yonetmen_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_151522_value", "value", "", "", "")
        ),
        "string"
      ),
      TurkiyeHakki_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_475588_value", "value", "", "key", "")
        ),
        "number"
      ),
      TCDisiUlke_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_382382_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansSuresi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_841151_value", "value", "", "key", "")
        ),
        "string"
      ),
      TelifBedeli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_232600_value", "value", "", "", "")
        ),
        "string"
      ),
      EserKullanimSekli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_653975_value", "value", "", "key", "")
        ),
        "number"
      ),
      YayinlanmaFrekans_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_701444_value", "value", "", "", "")
        ),
        "string"
      ),
      ProgramIcerik_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_818678_value", "value", "", "", "")
        ),
        "string"
      ),
      TVKanal_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_284840_value", "value", "", "", "")
        ),
        "string"
      ),
      EserAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_405451_value", "value", "", "", "")
        ),
        "string"
      ),
      EserSahibi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_788421_value", "value", "", "", "")
        ),
        "string"
      ),
      LinkInfo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_607210_value", "value", "", "", "")
        ),
        "string"
      ),
      FirmaTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_907796_value", "value", "sbFirmaTuruList", "id", "id")
        ),
        "Guid"
      ),
      BasvuranSirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_389012_value", "value", "", "", "")
        ),
        "string"
      ),
      TVBolumNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_596953_value", "value", "", "", "")
        ),
        "string"
      ),
      TVYayimTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_38491_value", "value", "", "", "")
        ),
        "Date"
      ),
      LisansSuresiDiger_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_691237_value", "value", "", "", "")
        ),
        "string"
      ),
      Sezon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_300311_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "9"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuTicari/BFormuTicariComponent_714077_onClick8_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spBasvuruFormuInsert_Ticari_F9 = result?.data.spBasvuruFormuInsert_Ticari_F9;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_714077_onClick9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_714077_onClick9_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_714077_onClick9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bformuticari_192848_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBavuruTuruID ?? this.props.screenInputs.prmbavuruturuid,
        "Guid"
      ),
      ProjeTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_830230_value", "value", "", "", "id")
        ),
        "Guid"
      ),
      LisansTipiID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_654168_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_709919_value", "value", "", "", "")
        ),
        "string"
      ),
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        "string"
      ),
      GSM_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_390390_value", "value", "", "", "")
        ),
        "string"
      ),
      AdSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_750474_value", "value", "", "", "")
        ),
        "string"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_409878_value", "value", "", "", "")
        ),
        "string"
      ),
      Acil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_950974_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TerminTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_926329_value", "value", "", "", "")
        ),
        "Date"
      ),
      ProgramIcerik_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_211926_value", "value", "", "", "")
        ),
        "string"
      ),
      EserAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_405451_value", "value", "", "", "")
        ),
        "string"
      ),
      EserSahibi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_788421_value", "value", "", "", "")
        ),
        "string"
      ),
      FirmaTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_907796_value", "value", "sbFirmaTuruList", "id", "id")
        ),
        "Guid"
      ),
      BasvuranSirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_389012_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "10"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuTicari/BFormuTicariComponent_714077_onClick9_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spBasvuruFormuInsert_Ticari_F10 = result?.data.spBasvuruFormuInsert_Ticari_F10;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_714077_onClick10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_714077_onClick10_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_714077_onClick10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      ServisUcretiVar_0: ReactSystemFunctions.convertToTypeByName("True", "boolean")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.getUserInfo?.length > 0
            ? stateVars.getUserInfo[0].rolName
            : this.state.getUserInfo?.length > 0
            ? this.state.getUserInfo[0].rolName
            : null
        ),
        "Bayi"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuTicari/BFormuTicariComponent_714077_onClick10_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spUpdateServisUcret = result?.data.spUpdateServisUcret;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_714077_onClick11_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_714077_onClick11_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_714077_onClick11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "BFormuTicari",
      "BFormuTesekkur",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuTicariComponent_616689_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BTuru_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getBasvuruTuruKodById?.length > 0
            ? stateVars.getBasvuruTuruKodById[0].kod
            : this.state.getBasvuruTuruKodById?.length > 0
            ? this.state.getBasvuruTuruKodById[0].kod
            : null
        ),
        "number"
      ),
      PTuru_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getProjeTuruFormKodById?.length > 0
            ? stateVars.getProjeTuruFormKodById[0].kod
            : this.state.getProjeTuruFormKodById?.length > 0
            ? this.state.getProjeTuruFormKodById[0].kod
            : null
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "BFormuTicari/BFormuTicariComponent_616689_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getFormNo = result?.data.getFormNo;
    stateVars.isComp861186Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp468627Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp10355Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp139406Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp967257Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp938143Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp274058Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp256251Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp28903Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp918779Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp115665Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp3330Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp695955Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp25348Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp487276Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981156Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp169331Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp998670Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp406152Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp712408Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp951915Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp220908Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp846395Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp755011Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp466555Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434573Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp535471Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp564896Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp315445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp572237Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp307737Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp269167Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp884757Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp49749Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "22"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp581358Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp874517Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440117Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp579023Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "66"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp710158Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7343Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp919967Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuTicariComponent_616689_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_616689_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bformuticari_192848_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBavuruTuruID ?? this.props.screenInputs.prmbavuruturuid,
        "Guid"
      ),
      ProjeTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProjeTuruID ?? this.props.screenInputs.prmprojeturuid,
        "Guid"
      ),
      LisansTipiID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000001", "Guid"),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_654168_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_757838_value", "value", "", "", "")
        ),
        "string"
      ),
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        "string"
      ),
      GSM_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_390390_value", "value", "", "", "")
        ),
        "string"
      ),
      AdSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_750474_value", "value", "", "", "")
        ),
        "string"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_409878_value", "value", "", "", "")
        ),
        "string"
      ),
      Acil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_950974_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TerminTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_926329_value", "value", "", "", "")
        ),
        "Date"
      ),
      ToplamButce_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_613968_value", "value", "", "", "")
        ),
        "string"
      ),
      Produktor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_819000_value", "value", "", "", "")
        ),
        "string"
      ),
      Yonetmen_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_441327_value", "value", "", "", "")
        ),
        "string"
      ),
      BelgeselinKonusu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_164797_value", "value", "", "", "")
        ),
        "string"
      ),
      SahneTarifi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_709057_value", "value", "", "", "")
        ),
        "string"
      ),
      TurkiyeHakki_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_96573_value", "value", "", "key", "")
        ),
        "number"
      ),
      TCDisiUlke_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_904040_value", "value", "", "", "")
        ),
        "string"
      ),
      TCDisiFestivalmi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_655326_value", "value", "", "key", "")
        ),
        "number"
      ),
      TCDisiFestivalAciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_363998_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansSuresi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_59667_value", "value", "", "key", "")
        ),
        "string"
      ),
      TelifBedeli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_301253_value", "value", "", "", "")
        ),
        "string"
      ),
      EserKullanimSekli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_499516_value", "value", "", "key", "")
        ),
        "number"
      ),
      EserAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_405451_value", "value", "", "", "")
        ),
        "string"
      ),
      EserSahibi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_788421_value", "value", "", "", "")
        ),
        "string"
      ),
      FirmaTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_907796_value", "value", "sbFirmaTuruList", "id", "id")
        ),
        "Guid"
      ),
      BasvuranSirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_389012_value", "value", "", "", "")
        ),
        "string"
      ),
      LinkInfo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_806100_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansSuresiDiger_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_846743_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "1"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuTicari/BFormuTicariComponent_616689_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spBasvuruFormuInsert_Ticari_F1 = result?.data.spBasvuruFormuInsert_Ticari_F1;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_616689_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_616689_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_616689_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bformuticari_192848_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBavuruTuruID ?? this.props.screenInputs.prmbavuruturuid,
        "Guid"
      ),
      ProjeTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProjeTuruID ?? this.props.screenInputs.prmprojeturuid,
        "Guid"
      ),
      LisansTipiID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000001", "Guid"),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_654168_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_727135_value", "value", "", "", "")
        ),
        "string"
      ),
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        "string"
      ),
      GSM_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_390390_value", "value", "", "", "")
        ),
        "string"
      ),
      AdSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_750474_value", "value", "", "", "")
        ),
        "string"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_409878_value", "value", "", "", "")
        ),
        "string"
      ),
      Acil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_950974_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TerminTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_926329_value", "value", "", "", "")
        ),
        "Date"
      ),
      Yapimci_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_464905_value", "value", "", "", "")
        ),
        "string"
      ),
      Sanatcilar_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_919241_value", "value", "", "", "")
        ),
        "string"
      ),
      YapimAd_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      KullanimSekliCD_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_42241_value", "value", "", "key", "")
        ),
        "number"
      ),
      Format_0: ReactSystemFunctions.convertToTypeByName(null, "number"),
      FormatAciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_643220_value", "value", "", "", "")
        ),
        "string"
      ),
      YayinTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_174173_value", "value", "", "", "")
        ),
        "string"
      ),
      YayimBolgesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_314315_value", "value", "", "key", "")
        ),
        "number"
      ),
      MarkaUrunVarsa_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_363699_value", "value", "", "", "")
        ),
        "string"
      ),
      SozDegisimi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_631318_value", "value", "", "", "")
        ),
        "string"
      ),
      Formats_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_151733_value", "value", "", "key", "")
        ),
        "string"
      ),
      Facebook_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_74883_value", "value", "", "", "")
        ),
        "string"
      ),
      Instagram_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_136717_value", "value", "", "", "")
        ),
        "string"
      ),
      TikTok_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_212231_value", "value", "", "", "")
        ),
        "string"
      ),
      Twitter_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_628115_value", "value", "", "", "")
        ),
        "string"
      ),
      YouTube_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_774689_value", "value", "", "", "")
        ),
        "string"
      ),
      DigerSosyalMedya_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_176725_value", "value", "", "", "")
        ),
        "string"
      ),
      EserAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_405451_value", "value", "", "", "")
        ),
        "string"
      ),
      EserSahibi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_788421_value", "value", "", "", "")
        ),
        "string"
      ),
      FirmaTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_907796_value", "value", "sbFirmaTuruList", "id", "id")
        ),
        "Guid"
      ),
      BasvuranSirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_389012_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "2"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuTicari/BFormuTicariComponent_616689_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spBasvuruFormuInsert_Ticari_F2 = result?.data.spBasvuruFormuInsert_Ticari_F2;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_616689_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_616689_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_616689_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bformuticari_192848_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBavuruTuruID ?? this.props.screenInputs.prmbavuruturuid,
        "Guid"
      ),
      ProjeTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProjeTuruID ?? this.props.screenInputs.prmprojeturuid,
        "Guid"
      ),
      LisansTipiID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000001", "Guid"),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_654168_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_731780_value", "value", "", "", "")
        ),
        "string"
      ),
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        "string"
      ),
      GSM_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_390390_value", "value", "", "", "")
        ),
        "string"
      ),
      AdSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_750474_value", "value", "", "", "")
        ),
        "string"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_409878_value", "value", "", "", "")
        ),
        "string"
      ),
      Acil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_950974_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TerminTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_926329_value", "value", "", "", "")
        ),
        "Date"
      ),
      Produktor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_35217_value", "value", "", "", "")
        ),
        "string"
      ),
      Yonetmen_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_215099_value", "value", "", "", "")
        ),
        "string"
      ),
      SahneTarifi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_496974_value", "value", "", "", "")
        ),
        "string"
      ),
      TurkiyeHakki_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_554864_value", "value", "", "key", "")
        ),
        "number"
      ),
      TCDisiUlke_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_711138_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansSuresi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_193622_value", "value", "", "key", "")
        ),
        "string"
      ),
      TelifBedeli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_54329_value", "value", "", "", "")
        ),
        "string"
      ),
      EserKullanimSekli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_139423_value", "value", "", "key", "")
        ),
        "number"
      ),
      TVKanal_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_530996_value", "value", "", "", "")
        ),
        "string"
      ),
      Basrol_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_503111_value", "value", "", "", "")
        ),
        "string"
      ),
      YayinTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_180071_value", "value", "", "", "")
        ),
        "string"
      ),
      EserAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_405451_value", "value", "", "", "")
        ),
        "string"
      ),
      EserSahibi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_788421_value", "value", "", "", "")
        ),
        "string"
      ),
      LinkInfo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_315845_value", "value", "", "", "")
        ),
        "string"
      ),
      DiziKonu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_800510_value", "value", "", "", "")
        ),
        "string"
      ),
      DiziBolumNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_949599_value", "value", "", "", "")
        ),
        "string"
      ),
      EserSure_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_408183_value", "value", "", "", "")
        ),
        "string"
      ),
      FirmaTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_907796_value", "value", "sbFirmaTuruList", "id", "id")
        ),
        "Guid"
      ),
      BasvuranSirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_389012_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansSuresiDiger_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_296139_value", "value", "", "", "")
        ),
        "string"
      ),
      Sezon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_125636_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "3"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuTicari/BFormuTicariComponent_616689_onClick3_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spBasvuruFormuInsert_Ticari_F3 = result?.data.spBasvuruFormuInsert_Ticari_F3;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_616689_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_616689_onClick4_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_616689_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bformuticari_192848_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBavuruTuruID ?? this.props.screenInputs.prmbavuruturuid,
        "Guid"
      ),
      ProjeTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProjeTuruID ?? this.props.screenInputs.prmprojeturuid,
        "Guid"
      ),
      LisansTipiID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000001", "Guid"),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_654168_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_953799_value", "value", "", "", "")
        ),
        "string"
      ),
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        "string"
      ),
      GSM_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_390390_value", "value", "", "", "")
        ),
        "string"
      ),
      AdSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_750474_value", "value", "", "", "")
        ),
        "string"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_409878_value", "value", "", "", "")
        ),
        "string"
      ),
      Acil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_950974_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TerminTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_926329_value", "value", "", "", "")
        ),
        "Date"
      ),
      Produktor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_894235_value", "value", "", "", "")
        ),
        "string"
      ),
      Yonetmen_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_752355_value", "value", "", "", "")
        ),
        "string"
      ),
      TurkiyeHakki_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_563208_value", "value", "", "key", "")
        ),
        "number"
      ),
      TCDisiUlke_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_861385_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansSuresi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_482186_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansSuresiDiger_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_482186_value", "value", "", "", "")
        ),
        "string"
      ),
      EserKullanimSekli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_398647_value", "value", "", "key", "")
        ),
        "number"
      ),
      TVKanal_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_795343_value", "value", "", "", "")
        ),
        "string"
      ),
      Basrol_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_37671_value", "value", "", "", "")
        ),
        "string"
      ),
      YayinTarihi_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      EserKullanimYeri_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_175576_value", "value", "", "key", "")
        ),
        "number"
      ),
      Sinopsis_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_780979_value", "value", "", "", "")
        ),
        "string"
      ),
      KacBolum_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_183550_value", "value", "", "", "")
        ),
        "string"
      ),
      FirmaTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_907796_value", "value", "sbFirmaTuruList", "id", "id")
        ),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "4"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuTicari/BFormuTicariComponent_616689_onClick4_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spBasvuruFormuInsert_Ticari_F4 = result?.data.spBasvuruFormuInsert_Ticari_F4;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_616689_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_616689_onClick5_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_616689_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bformuticari_192848_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBavuruTuruID ?? this.props.screenInputs.prmbavuruturuid,
        "Guid"
      ),
      ProjeTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProjeTuruID ?? this.props.screenInputs.prmprojeturuid,
        "Guid"
      ),
      LisansTipiID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000001", "Guid"),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_654168_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_433250_value", "value", "", "", "")
        ),
        "string"
      ),
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        "string"
      ),
      GSM_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_390390_value", "value", "", "", "")
        ),
        "string"
      ),
      AdSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_750474_value", "value", "", "", "")
        ),
        "string"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_409878_value", "value", "", "", "")
        ),
        "string"
      ),
      Acil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_950974_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TerminTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_926329_value", "value", "", "", "")
        ),
        "Date"
      ),
      ToplamButce_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_401545_value", "value", "", "", "")
        ),
        "string"
      ),
      Produktor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_548831_value", "value", "", "", "")
        ),
        "string"
      ),
      Yonetmen_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_241884_value", "value", "", "", "")
        ),
        "string"
      ),
      SahneTarifi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_821567_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansSuresi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_161165_value", "value", "", "key", "")
        ),
        "string"
      ),
      TelifBedeli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_108219_value", "value", "", "", "")
        ),
        "string"
      ),
      EserKullanimSekli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_599586_value", "value", "", "key", "")
        ),
        "number"
      ),
      Basrol_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_154279_value", "value", "", "", "")
        ),
        "string"
      ),
      SenaryoOzeti_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_306823_value", "value", "", "", "")
        ),
        "string"
      ),
      FragmandaVarmi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_109874_value", "value", "", "", "")
        ),
        "string"
      ),
      TurkiyeHakki_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_49563_value", "value", "", "key", "")
        ),
        "number"
      ),
      TCDisiUlke_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_437312_value", "value", "", "", "")
        ),
        "string"
      ),
      TCDisiFestivalmi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_763581_value", "value", "", "key", "")
        ),
        "number"
      ),
      TCDisiFestivalAciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_478463_value", "value", "", "", "")
        ),
        "string"
      ),
      EserAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_405451_value", "value", "", "", "")
        ),
        "string"
      ),
      EserSahibi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_788421_value", "value", "", "", "")
        ),
        "string"
      ),
      LinkInfo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_179006_value", "value", "", "", "")
        ),
        "string"
      ),
      EserSure_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_135744_value", "value", "", "", "")
        ),
        "string"
      ),
      FirmaTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_907796_value", "value", "sbFirmaTuruList", "id", "id")
        ),
        "Guid"
      ),
      BasvuranSirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_389012_value", "value", "", "", "")
        ),
        "string"
      ),
      FilmMecra_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_43394_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansSuresiDiger_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_597208_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "5"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuTicari/BFormuTicariComponent_616689_onClick5_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spBasvuruFormuInsert_Ticari_F5 = result?.data.spBasvuruFormuInsert_Ticari_F5;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_616689_onClick6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_616689_onClick6_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_616689_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bformuticari_192848_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBavuruTuruID ?? this.props.screenInputs.prmbavuruturuid,
        "Guid"
      ),
      ProjeTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProjeTuruID ?? this.props.screenInputs.prmprojeturuid,
        "Guid"
      ),
      LisansTipiID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000001", "Guid"),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_654168_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_779822_value", "value", "", "", "")
        ),
        "string"
      ),
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        "string"
      ),
      GSM_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_390390_value", "value", "", "", "")
        ),
        "string"
      ),
      AdSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_750474_value", "value", "", "", "")
        ),
        "string"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_409878_value", "value", "", "", "")
        ),
        "string"
      ),
      Acil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_950974_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TerminTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_926329_value", "value", "", "", "")
        ),
        "Date"
      ),
      Sanatcilar_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_139034_value", "value", "", "", "")
        ),
        "string"
      ),
      Yapimci_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_213030_value", "value", "", "", "")
        ),
        "string"
      ),
      Sponsor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_895754_value", "value", "", "", "")
        ),
        "string"
      ),
      KonserYerTarih_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_569899_value", "value", "", "", "")
        ),
        "string"
      ),
      KonserKapasitesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_179729_value", "value", "", "", "")
        ),
        "string"
      ),
      BiletFiyatlari_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_936236_value", "value", "", "", "")
        ),
        "string"
      ),
      KonserAfis_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_46982_value", "value", "", "", "")
        ),
        "string"
      ),
      KonserKayit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_925632_value", "value", "", "", "")
        ),
        "string"
      ),
      KonserGoruntu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_753776_value", "value", "", "", "")
        ),
        "string"
      ),
      KonserCDvd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_118061_value", "value", "", "", "")
        ),
        "string"
      ),
      TelifBedeli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_447965_value", "value", "", "", "")
        ),
        "string"
      ),
      EserKullanimSekli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_636418_value", "value", "", "key", "")
        ),
        "number"
      ),
      KonserHologram_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_243523_value", "value", "", "", "")
        ),
        "string"
      ),
      KonserGelir_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_252338_value", "value", "", "", "")
        ),
        "string"
      ),
      KonserGelirPay_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_63361_value", "value", "", "", "")
        ),
        "string"
      ),
      EserAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_405451_value", "value", "", "", "")
        ),
        "string"
      ),
      EserSahibi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_788421_value", "value", "", "", "")
        ),
        "string"
      ),
      LinkInfo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_606315_value", "value", "", "", "")
        ),
        "string"
      ),
      FirmaTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_907796_value", "value", "sbFirmaTuruList", "id", "id")
        ),
        "Guid"
      ),
      BasvuranSirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_389012_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "6"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuTicari/BFormuTicariComponent_616689_onClick6_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spBasvuruFormuInsert_Ticari_F6 = result?.data.spBasvuruFormuInsert_Ticari_F6;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_616689_onClick7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_616689_onClick7_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_616689_onClick7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bformuticari_192848_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBavuruTuruID ?? this.props.screenInputs.prmbavuruturuid,
        "Guid"
      ),
      ProjeTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProjeTuruID ?? this.props.screenInputs.prmprojeturuid,
        "Guid"
      ),
      LisansTipiID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000001", "Guid"),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_654168_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_160425_value", "value", "", "", "")
        ),
        "string"
      ),
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        "string"
      ),
      GSM_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_390390_value", "value", "", "", "")
        ),
        "string"
      ),
      AdSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_750474_value", "value", "", "", "")
        ),
        "string"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_409878_value", "value", "", "", "")
        ),
        "string"
      ),
      Acil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_950974_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TerminTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_926329_value", "value", "", "", "")
        ),
        "Date"
      ),
      Parca_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_375623_value", "value", "", "", "")
        ),
        "string"
      ),
      Urun_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_634083_value", "value", "", "", "")
        ),
        "string"
      ),
      BesteciSozYazari_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_84279_value", "value", "", "", "")
        ),
        "string"
      ),
      IzinTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_952938_value", "value", "sbIzinTuruList", "id", "id")
        ),
        "Guid"
      ),
      Spot_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_575302_value", "value", "", "", "")
        ),
        "string"
      ),
      FilmSayisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_275256_value", "value", "", "", "")
        ),
        "string"
      ),
      VersiyonSayisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_33436_value", "value", "", "", "")
        ),
        "string"
      ),
      TCDisiUlke_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_601579_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansSuresi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_925097_value", "value", "", "key", "")
        ),
        "string"
      ),
      LisansSuresiDiger_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_713576_value", "value", "", "", "")
        ),
        "string"
      ),
      Script_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_69287_value", "value", "", "", "")
        ),
        "string"
      ),
      EserKullanimSekli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_423768_value", "value", "", "key", "")
        ),
        "number"
      ),
      SozDegisimi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_826306_value", "value", "", "", "")
        ),
        "string"
      ),
      YayinTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_508876_value", "value", "", "", "")
        ),
        "string"
      ),
      MunhasirlikID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_958009_value", "value", "sbMunhasirlikList", "id", "id")
        ),
        "Guid"
      ),
      EserAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_405451_value", "value", "", "", "")
        ),
        "string"
      ),
      EserSahibi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_788421_value", "value", "", "", "")
        ),
        "string"
      ),
      LinkInfo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_680656_value", "value", "", "", "")
        ),
        "string"
      ),
      FirmaTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_907796_value", "value", "sbFirmaTuruList", "id", "id")
        ),
        "Guid"
      ),
      BasvuranSirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_389012_value", "value", "", "", "")
        ),
        "string"
      ),
      Mecras_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_330499_value", "value", "", "", "")
        ),
        "string"
      ),
      Arsiv_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_776748_value", "value", "", "key", "")
        ),
        "number"
      ),
      ArsivSure_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_883613_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "7"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuTicari/BFormuTicariComponent_616689_onClick7_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spBasvuruFormuInsert_Ticari_F7 = result?.data.spBasvuruFormuInsert_Ticari_F7;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_616689_onClick8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_616689_onClick8_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_616689_onClick8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bformuticari_192848_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBavuruTuruID ?? this.props.screenInputs.prmbavuruturuid,
        "Guid"
      ),
      ProjeTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProjeTuruID ?? this.props.screenInputs.prmprojeturuid,
        "Guid"
      ),
      LisansTipiID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000001", "Guid"),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_654168_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_41842_value", "value", "", "", "")
        ),
        "string"
      ),
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        "string"
      ),
      GSM_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_390390_value", "value", "", "", "")
        ),
        "string"
      ),
      AdSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_750474_value", "value", "", "", "")
        ),
        "string"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_409878_value", "value", "", "", "")
        ),
        "string"
      ),
      Acil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_950974_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TerminTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_926329_value", "value", "", "", "")
        ),
        "Date"
      ),
      ToplamButce_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_769963_value", "value", "", "", "")
        ),
        "string"
      ),
      Produktor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_745722_value", "value", "", "", "")
        ),
        "string"
      ),
      Yonetmen_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_678596_value", "value", "", "", "")
        ),
        "string"
      ),
      SahneTarifi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_977010_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansSuresi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_114157_value", "value", "", "key", "")
        ),
        "string"
      ),
      TelifBedeli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_595383_value", "value", "", "", "")
        ),
        "string"
      ),
      EserKullanimSekli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_945615_value", "value", "", "key", "")
        ),
        "number"
      ),
      Basrol_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_13402_value", "value", "", "", "")
        ),
        "string"
      ),
      SenaryoOzeti_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_98625_value", "value", "", "", "")
        ),
        "string"
      ),
      SahneVeKoltuk_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_939868_value", "value", "", "", "")
        ),
        "string"
      ),
      YayinlanmaFrekans_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_348650_value", "value", "", "", "")
        ),
        "string"
      ),
      BiletFiyatlari_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_785985_value", "value", "", "", "")
        ),
        "string"
      ),
      Sponsor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_531317_value", "value", "", "", "")
        ),
        "string"
      ),
      FragmandaVarmi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_834746_value", "value", "", "", "")
        ),
        "string"
      ),
      EserAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_405451_value", "value", "", "", "")
        ),
        "string"
      ),
      EserSahibi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_788421_value", "value", "", "", "")
        ),
        "string"
      ),
      LinkInfo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_997705_value", "value", "", "", "")
        ),
        "string"
      ),
      Koltuk_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_605461_value", "value", "", "", "")
        ),
        "string"
      ),
      FirmaTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_907796_value", "value", "sbFirmaTuruList", "id", "id")
        ),
        "Guid"
      ),
      BasvuranSirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_389012_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansSuresiDiger_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_961538_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "8"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuTicari/BFormuTicariComponent_616689_onClick8_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spBasvuruFormuInsert_Ticari_F8 = result?.data.spBasvuruFormuInsert_Ticari_F8;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_616689_onClick9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_616689_onClick9_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_616689_onClick9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bformuticari_192848_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBavuruTuruID ?? this.props.screenInputs.prmbavuruturuid,
        "Guid"
      ),
      ProjeTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmProjeTuruID ?? this.props.screenInputs.prmprojeturuid,
        "Guid"
      ),
      LisansTipiID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000001", "Guid"),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_654168_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_970226_value", "value", "", "", "")
        ),
        "string"
      ),
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        "string"
      ),
      GSM_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_390390_value", "value", "", "", "")
        ),
        "string"
      ),
      AdSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_750474_value", "value", "", "", "")
        ),
        "string"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_409878_value", "value", "", "", "")
        ),
        "string"
      ),
      Acil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_950974_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TerminTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_926329_value", "value", "", "", "")
        ),
        "Date"
      ),
      Produktor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_584964_value", "value", "", "", "")
        ),
        "string"
      ),
      Yonetmen_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_151522_value", "value", "", "", "")
        ),
        "string"
      ),
      TurkiyeHakki_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_475588_value", "value", "", "key", "")
        ),
        "number"
      ),
      TCDisiUlke_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_382382_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansSuresi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_841151_value", "value", "", "key", "")
        ),
        "string"
      ),
      TelifBedeli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_232600_value", "value", "", "", "")
        ),
        "string"
      ),
      EserKullanimSekli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "bformuticari_653975_value", "value", "", "key", "")
        ),
        "number"
      ),
      YayinlanmaFrekans_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_701444_value", "value", "", "", "")
        ),
        "string"
      ),
      ProgramIcerik_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_818678_value", "value", "", "", "")
        ),
        "string"
      ),
      TVKanal_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_284840_value", "value", "", "", "")
        ),
        "string"
      ),
      EserAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_405451_value", "value", "", "", "")
        ),
        "string"
      ),
      EserSahibi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_788421_value", "value", "", "", "")
        ),
        "string"
      ),
      LinkInfo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_607210_value", "value", "", "", "")
        ),
        "string"
      ),
      FirmaTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_907796_value", "value", "sbFirmaTuruList", "id", "id")
        ),
        "Guid"
      ),
      BasvuranSirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_389012_value", "value", "", "", "")
        ),
        "string"
      ),
      TVBolumNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_596953_value", "value", "", "", "")
        ),
        "string"
      ),
      TVYayimTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_38491_value", "value", "", "", "")
        ),
        "Date"
      ),
      LisansSuresiDiger_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_691237_value", "value", "", "", "")
        ),
        "string"
      ),
      Sezon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_300311_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "9"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuTicari/BFormuTicariComponent_616689_onClick9_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spBasvuruFormuInsert_Ticari_F9 = result?.data.spBasvuruFormuInsert_Ticari_F9;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_616689_onClick10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_616689_onClick10_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_616689_onClick10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "bformuticari_192848_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruTuruID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmBavuruTuruID ?? this.props.screenInputs.prmbavuruturuid,
        "Guid"
      ),
      ProjeTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_830230_value", "value", "", "", "id")
        ),
        "Guid"
      ),
      LisansTipiID_0: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000001", "Guid"),
      SirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_654168_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjeAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_709919_value", "value", "", "", "")
        ),
        "string"
      ),
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_192848_value", "value", "", "", "")
        ),
        "string"
      ),
      GSM_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_390390_value", "value", "", "", "")
        ),
        "string"
      ),
      AdSoyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_750474_value", "value", "", "", "")
        ),
        "string"
      ),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_409878_value", "value", "", "", "")
        ),
        "string"
      ),
      Acil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_950974_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TerminTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_926329_value", "value", "", "", "")
        ),
        "Date"
      ),
      ProgramIcerik_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_211926_value", "value", "", "", "")
        ),
        "string"
      ),
      EserAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_405451_value", "value", "", "", "")
        ),
        "string"
      ),
      EserSahibi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_788421_value", "value", "", "", "")
        ),
        "string"
      ),
      FirmaTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "bformuticari_907796_value", "value", "sbFirmaTuruList", "id", "id")
        ),
        "Guid"
      ),
      BasvuranSirketAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "bformuticari_389012_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "10"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuTicari/BFormuTicariComponent_616689_onClick10_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spBasvuruFormuInsert_Ticari_F10 = result?.data.spBasvuruFormuInsert_Ticari_F10;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_616689_onClick11_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_616689_onClick11_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_616689_onClick11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      ServisUcretiVar_0: ReactSystemFunctions.convertToTypeByName("True", "boolean")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.getUserInfo?.length > 0
            ? stateVars.getUserInfo[0].rolName
            : this.state.getUserInfo?.length > 0
            ? this.state.getUserInfo[0].rolName
            : null
        ),
        "Bayi"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "BFormuTicari/BFormuTicariComponent_616689_onClick11_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spUpdateServisUcret = result?.data.spUpdateServisUcret;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_616689_onClick12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BFormuTicariComponent_616689_onClick12_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTicariComponent_616689_onClick12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
